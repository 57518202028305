<template>
  <v-container class="fill-height pa-0">
    
    <v-row grow no-gutters>
        <v-card
            color="red darken-2"
            :loading="loading"
            :height="windowHeight"
            id="id-box-schedule" ref="boxschedule"
                width="100%"  class="mb-5 mx-auto rounded-tr-0 rounded-tl-0"
        >
        <template slot="progress">
        <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
        ></v-progress-linear>
        </template>
        <v-toolbar
        flat
      color="red darken-2"
      dark
    >

        <v-btn @click="backToHome()" icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title >Kembali</v-toolbar-title>
    </v-toolbar>
    <!--<v-img
      height="250"
      src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
    ></v-img>-->
    <v-card-text >
        <v-responsive :height="windowHeight-85" class="overflow-y-auto">
        <v-card
                class="mx-auto mb-2"
            >
                <v-card-text >
                
                <p class="headline mb-1 text--primary">
                    SARS-CoV-2 RNA
                </p>
                <p class="body-1 mb-0"><code>20%</code> <span class="text-decoration-line-through">Rp 1.100.000</span></p>
                <p class="title"><span>Rp 900.000</span></p>
                <div class="subtitle font-weight-black">
                     DESKRIPSI
                </div>
                <div class="text--primary mb-2">
                     PCR Test meggunakan metode swab pada bagian dari dalam hidung (Nasofaring) dan atau bagian belakang tenggorokan (Orofaring).
                </div>
                <div class="subtitle font-weight-black">
                     TEMPAT PENGAMBILAN TES
                </div>
                <div class="text--primary">
                    Klinik Pramita Cabang Adityawarman. <a href="#" class="text-decoration-none">Lihat Peta</a>
                </div>
                <div class="subtitle font-weight-black">
                     HASIL TES
                </div>
                <div class="text--primary mb-2">
                    1 hari kerja maksimal pukul 23.59 (di aplikasi - cek di riwayat pemesanan dan melalui email)
                </div>
                </v-card-text>
        </v-card>
        <v-card
                class="mx-auto mb-8"
            >
                <v-card-title class="body-2 font-weight-bold">
                    WAKTU PENGAMBILAN SPESIMEN
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <p >Masih tersedia : <span class="font-weight-black">50 kuota</span></p>
                    
                    <v-card
                        class="mb-2 d-flex justify-center align-center align-content-center flex-wrap"
                        flat
                        tile
                        no-gutters
                        >
                        <v-card
                            v-for="ds in datetime_samples"
                            :key="ds.id"
                            class="pa-2 text-center"
                            :class="{'red white--text':checkDateSelected(ds)}"
                            tile
                            outlined
                            width="14.285%"
                            hover
                            @click="clickDate(ds)"
                        >
                            <p class="caption mb-1">{{ds.month}}</p> 
                            <p class="mb-1">{{ds.date}}</p> 
                        </v-card>
                    </v-card>
                    <v-expansion-panels v-model="panel" class="mb-6">
                    <v-expansion-panel
                        v-for="(item,i) in timeSamples"
                        :key="i"
                    >
                        <v-expansion-panel-header expand-icon="mdi-menu-down">
                        <p class="black--text" :class="{'red--text':item.status === 'active'}">{{item.parent}}</p>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            
                            <v-row
                                    class="mb-2"
                                    no-gutters
                                    >
                                    <v-col
                            
                                        v-for="(subitem,j) in item.childs" :key="j"
                                        cols="6"
                                        md="4"
                                    >
                                        <v-card
                                        class="ma-1 text-center pa-2"
                                        :class="{'red white--text':subitem.status === 'active'}"
                                        tile
                                        @click="clickTime(subitem)"
                                        outlined
                                        hover
                                        >
                                       {{subitem.name}}
                                        </v-card>
                                    </v-col>
                                </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    </v-expansion-panels>
                    <div class="subtitle mb-2 font-weight-black">ATURAN SAAT PENGAMBILAN SPESIMEN</div>
                    <v-alert
                    border="right"
                    colored-border
                    type="warning"
                    elevation="2"
                    >
                    Pengambilan spesimen dilakukan pada tanggal <span v-if="!_.isEmpty(selected_date)">{{selected_date.date}}-{{selected_date.month_x}}-{{selected_date.year}}</span> <span v-if="_.isEmpty(selected_date)"><code>anda belum memilih tanggal</code></span>
                    </v-alert>
                    <v-alert
                    border="right"
                    colored-border
                    type="warning"
                    elevation="2"
                    >
                    Pasien hanya akan dilayani tepat pada rentang jam yang sudah dipilih, yaitu pada jam <span v-if="_.isEmpty(selected_time)"><code>anda belum memilih jam</code></span> <span v-if="!_.isEmpty(selected_time)">{{selected_time.name}}</span>
                    </v-alert>
                    <v-alert
                    border="right"
                    colored-border
                    type="warning"
                    elevation="2"
                    class="mb-10"
                    >
                    Jika pasien datang terlambat maka pemesanan dianggap batal, dan pasien akan diarahkan untuk melakukan pemesanan kembali pada tanggal dan jam selanjutnya yang masih kosong.
                    </v-alert>
                    
                </v-card-text>
        </v-card>
        
       
    
    </v-responsive>
    </v-card-text>

        </v-card>
            <v-bottom-navigation
            max-width="560px"
            :style="{left: '50%', transform:'translateX(-50%)', position:'fixed',bottom:'0px'}"
            grow
            style="z-index:1000"
            value="0"
            
            class="mx-auto box-center justify-center text-center align-center"
            >
            <v-btn
                                color="red darken-4"
                                dark
                                block
                                rounded
                                class="white--text"
                                >
                                PILIH PEMBAYARAN
                                </v-btn>
            </v-bottom-navigation>
    </v-row>
    
  </v-container>
</template>

<script>
  export default {
    name: 'xdetails',
    mounted(){
       //console.log(this.datetime_sample.childs)
       this.fx_timeSamples()
    },
    computed:{
        selected_time:{
         get() {
             return this.$store.state.details.selected_time
                
         },
         set(val) {
               this.$store.commit("details/update_selected_time",val)
         }
      },
      selected_date:{
         get() {
             return this.$store.state.details.selected_date
                
         },
         set(val) {
               this.$store.commit("details/update_selected_date",val)
         }
      },
        timeSamples:{
         get() {
             return this.$store.state.details.timeSamples
                
         },
         set(val) {
               this.$store.commit("details/update_timeSamples",val)
         }
      },
       datetime_samples:{
         get() {
             return this.$store.state.details.datetime_samples
                
         },
         set(val) {
               this.$store.commit("details/update_datetime_samples",val)
         }
      },
       datetime_sample:{
         get() {
             return this.$store.state.details.datetime_sample
                
         },
         set(val) {
               this.$store.commit("details/update_datetime_sample",val)
         }
      },
    },
    methods:{
        backToHome(){
            this.$router.push({name:'xhome'})
        },
        reserve () {
        this.loading = true

        setTimeout(() => (this.loading = false), 2000)
      },
      getWidthBox() {
        return  this.$refs.boxschedule.clientWidth;
        },
        checkDateSelected(date_now){
            //console.log(date_now)
            //console.log(this.selected_date)
            
            if(date_now.date === this.selected_date.date && date_now.month_x === this.selected_date.month_x && date_now.year === this.selected_date.year ){
                //console.log('true'); 
                return true;
            }
            else{
                //console.log('false'); 
                return false;
            }
                
        },
        clickDate(selectedDate){
            this.selected_time = {}
            this.selected_date = selectedDate
            this.fx_timeSamples()
        },
        clickTime(selectedTime){
            this.selected_time = selectedTime
            this.fx_timeSamples()
        },
        fx_timeSamples(){
            if(this.selected_date.childs && this.selected_date.childs.length > 0){
                    var rtn = []
                   var xunik = _.uniq(_.map(this.selected_date.childs, 'note'))
                   //console.log(xunik)
                   var v = this.selected_date.childs
                   var seltime = this.selected_time
                   _.forEach(xunik, function(value,i) {
                       var xpush = _.filter(v, ['note', value])
                       xpush.forEach( task => task.status = '-' )
                       var status_now = _.findIndex(xpush, function(o) { return o.id == seltime.id && o.name == seltime.name })
                       var status_parent = status_now !== -1 ?'active':'-'
                       if(status_now !== -1)
                        xpush[status_now].status = 'active'
                       rtn.push({parent:value,status:status_parent,childs:xpush})
                    })
                    //console.log(rtn)
                this.timeSamples  = rtn
                }
                else
                this.timeSamples = []
        },
        clocksamples(sampletime){
            return _.filter(this.selected_date.childs, function(o) { return o.note === sampletime })
        }
    },
    data: () => ({
       windowHeight: window.innerHeight,
       windowWidth: window.innerWidth,
       loading: false,
       xsampletime:{},
       panel:0
    }),
  }
</script>
