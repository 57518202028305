<template>
    <div  style="background-color: #EF5350 !important;overflow-x:hidden;overflow-y:hidden" >
        <v-app-bar
            color="red lighten-1"
            fixed
            dark
         
            height="59px"
        >

            <v-list-item class="mx-auto pa-0">
              <v-list-item-avatar >
                  <v-icon style="cursor:pointer" @click="goBack()">mdi-arrow-left</v-icon>
              </v-list-item-avatar>
              <v-list-item-content class="font-weight-bold">
                {{selected_subcategory.name}}
              </v-list-item-content>
            </v-list-item>
        </v-app-bar>
        <v-divider/>
        <v-card class="mt-15">
            <p class="pl-4 pt-4 mb-3 font-weight-bold  pr-2 body-1">PILIH PEMERIKSAAN</p>
            <v-divider/>
            <v-row class="mx-auto mt-2 flex-wrap no-gutters">
                <v-col
                    cols="12"
                    class="mb-2"
                    style="height:590px;overflow-y:auto"
                   
                > 
                <v-container class=" pa-1 mb-5" style="min-height:250px">
                    <v-card
                      style="cursor:pointer"
                      class="pa-0 ml-2 mr-2 mb-3"
               
                      elevation="4"
                      
                      
                      v-for="(test,kt) in tests"
                      :key="kt"
                      @click="clickTest(test)"
                    >
                      <v-list
                          two-line
                          dark
                          class="pa-0" style="background:#EF5350;min-height:56px"
                      >
                          <v-list-item class="pa-0 ma-0"
                          >
                          <v-list-item-avatar class="mt-0 pt-0 mb-0 pb-0" tile>
                              <v-icon 
                              class="red pl-3 lighten-1"
                              dark
                              >fa-syringe</v-icon>
                          </v-list-item-avatar>

                          <v-list-item-content>
                              <v-list-item-subtitle  class="caption">Harga</v-list-item-subtitle>
                              <v-list-item-subtitle style="font-size:18px;" class="white--text font-weight-bold">{{getPrice(test.price)}}</v-list-item-subtitle>
                          </v-list-item-content>
                          </v-list-item>
                      </v-list>
                      <v-divider></v-divider>
                      <v-row
                          align="center"
                          no-gutters
                      >
                          <v-col
                          cols="12"
                          class="pa-2"
                          >
                          <p style="font-family: 'Fraunces', serif;font-size:16px;line-height:1.5rem"  class="pt-2 font-weight-bold pl-2">{{test.test_name}}</p>
                          <!--<p style="color:#555;" class="body-1 pl-2 mb-1">Deskripsi</p>-->
                          <p class="body-1 pl-2 mb-2 pr-2"  style="line-height:1.6rem">
                              {{test.description}}
                          </p>

                          </v-col>
                          
                  
                      </v-row>
                    </v-card>
                
                    
                    
                </v-container>
                    
                   
                </v-col>
                
                    
                </v-row>
        </v-card>
    </div>       
</template>
<style>
.cex{
background: linear-gradient(to right top, #65dfc9, #6cdbeb);
}
.text-rokkit-reguler{
  font-family: 'Rokkitt', serif;
  font-size:1.2rem;
}
.box-center {
        position: fixed;
        left: 50%;
        bottom: -50px;
        transform: translate(-50%, -50%);
        margin: 0 auto;
    }
    .fixed-bottom-bg{
        position: fixed;
        left: 50%;
        bottom: -52px;
        transform: translate(-50%, -50%);
        margin: 0 auto;
        box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12) !important;
    }

@media only screen and (min-device-width : 1024px){
/* Styles */
/* width */
::-webkit-scrollbar {
  height: 8px;              /* height of horizontal scrollbar ← You're missing this */
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
}

</style>
<script>
import axios from 'axios';
import InfiniteLoading from 'vue-infinite-loading';

  export default {
    name: 'xchoosetest',
    components: {
      InfiniteLoading
    },
    mounted(){
      var localstorage = JSON.parse(localStorage.getItem("oneState"))
      this.$store.commit("choosebranch/update_selected_branch",localstorage.choosebranch.selected_branch)
      this.$store.commit("choosebranch/update_selected_subcategory",localstorage.choosebranch.selected_subcategory)
      this.$store.commit("beranda/update_dialog_loading",true)
      this.$store.dispatch("order/getTests", {branch:localstorage.choosebranch.selected_branch,subcategory:localstorage.choosebranch.selected_subcategory})

      if(this.$vuetify.breakpoint.name == 'xs'){
        this.window_width = window.innerWidth
      }
      else
        this.window_width = '560px'

    },
    computed:{
      selected_subcategory:{
         get() {
             return this.$store.state.choosebranch.selected_subcategory
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_selected_subcategory",val)
         }
      },
     tests:{
         get() {
             return this.$store.state.order.tests
                
         },
         set(val) {
               this.$store.commit("order/update_tests",val)
         }
      },
      selected_tests:{
         get() {
             return this.$store.state.order.selected_tests
         },
         set(val) {
               this.$store.commit("order/update_selected_tests",val)
         }
      },
       selected_test_varian:{
         get() {
             return this.$store.state.order.selected_test_varian
         },
         set(val) {
               this.$store.commit("order/update_selected_test_varian",val)
         }
      },
      selected_result_option:{
        get() {
            return this.$store.state.order.selected_result_option
                
        },
        set(val) {
              this.$store.commit("order/update_selected_result_option",val)
        }
      },
       result_option:{
        get() {
            return this.$store.state.order.result_option
                
        },
        set(val) {
              this.$store.commit("order/update_result_option",val)
        }
      },
    },
    methods:{

        getPrice(price){
          var xprice = _.uniq(price)
          var arr_text_price = []
          xprice.forEach(function(price) {
            var t_price =  'Rp'+price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            arr_text_price.push(t_price)
          })
          return arr_text_price.join(' - ')
         
        },
        goBack(){
            this.$router.push({name:'xbranch'})
        },
        clickTest(test){
          this.selected_test_varian = [test]
          this.selected_result_option = test['result_option'][0]
          this.result_option = test['result_option']
          var xstate = {}
          xstate.beranda = this.$store.state.beranda
          xstate.order = this.$store.state.order
          xstate.choosebranch = this.$store.state.choosebranch
          localStorage.setItem('oneState', JSON.stringify(xstate));
          this.$router.push({name:'xchoosetime'})
        }
        
    },
    data: () => ({
      infiniteId: +new Date(),
          masks: {
        input: 'DD-MM-YYYY',
      },
      infiniteId:1,
      dialog_payment_methode:false,
      dialog_daterange:false,
      isLogin:false,
      dialog_city:false,
      dialog_error:false,
      msg_error:'',
      reveal:false,
      dialog_branch:false,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      selected_city: {},
      categories:[{id:1,name:'PCR',active:false},{id:1,name:'Swab Antigen',active:false}],
      selected_categories:[],
      show: false,
      cities: [
          { name: 'Surabaya'},
          { name: 'Madiun'},
          { name: 'Salatiga'},
          { name: 'Magelang'},
          { name: 'Yogyakarta'},
          { name: 'Bandung'},
        ],
    }),
  }
</script>
