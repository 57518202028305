<template>
  <div style="overflow-x:hidden;overflow-y:hidden">
    
  <v-app-bar
      dark
      color="#e42e2a"
      fixed
      class="pa-0 ma-0"
    >
      <v-btn
                            color="#fffbfb36"
                            small="small"
                            text
                            class="ml-0 pl-0 white--text"
                            @click="goBack()">
                            <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>

      <v-toolbar-title>Beranda</v-toolbar-title>

      <v-spacer></v-spacer>
    
  
    </v-app-bar>
    <v-row v-if="info_payment.payment_type !== '1'" class="mx-auto no-gutters mt-12  mb-0 pb-0">
        <v-col class="mb-0 pt-2 mb-10" cols="12">
          
            <v-card
                class="mx-auto"
                max-width="374"
                flat
                v-if="info_payment.is_lunas === 'N'"
            >
                
                <v-card-text>
                  <v-card>
                     <v-img
                  class="mx-auto pa-0 mb-3"
                  :src="require('../assets/payment-img.jpeg')"
                  width="100%"
                ></v-img>
                  </v-card>
                    <v-row>
                        <v-col cols="12">
                            <p class="body-2 mb-0">Segera lakukan pembayaran sebelum</p>
                            <p class="title font-weiight-regular mb-1">{{info_payment.expired_time_text}}</p>
                        </v-col>
                    </v-row>
                    <v-divider/>
                    <v-row>
                        <v-col cols="12">
                            <p class="body-2 mb-0">Transfer ke {{info_payment.payment_name}}</p>
                            <v-list-item class="pl-0 ml-0">
                                <v-list-item-avatar class="mt-0 pt-0 mb-0 pb-0" tile>
                                <v-img height="64" contain  :src="info_payment.icon"></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>

                                <v-list-item-title>
                                    <span>{{info_payment.va_number}}</span><span class="ml-5"><v-icon  v-clipboard:copy="info_payment.va_number"
      v-clipboard:success="onCopy"
      v-clipboard:error="onError">mdi-content-copy</v-icon></span>
                                </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                    <v-divider/>
                     <v-row>
                        <v-col cols="12">
                            <p class="body-2 mb-0">Jumlah yang harus dibayar</p>
                            <p class="headline red--text mb-1">{{info_payment.total}}</p>
                        </v-col>
                    </v-row>
                    <v-divider/>
                      <p class="body-1 mb-2 mt-2 font-weight-black">Cara Pembayaran</p>
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-card
                              class="mx-auto mb-1"
                              v-for="(pay,i) in info_payment.doc_pay"
                              :key="i"
                              dense
                              @click="openDetail(pay,i)"
                            >
                              <v-card-actions>
                                <p class="mb-0 blue--text text--lighten-1 body-1">{{pay.label}}</p>

                              </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
                
            </v-card>

            <v-card
                flat
                class="mx-auto"
                max-width="374"
                v-if="info_payment.is_lunas === 'Y'"
            >
                <v-card-text>
                  <v-card>
                  <v-img
                  class="mx-auto pa-0 mb-3"
                  :src="require('../assets/payment-success.jpeg')"
                  width="100%"
                ></v-img>
                  </v-card>
                    <v-row>
                        <v-col cols="12">
                            <p class="display-2 text-center green--text text--ligthen-2">LUNAS</p>
                        </v-col>
                    </v-row>
                    <v-divider/>
                    <v-row>
                        <v-col cols="12">
                            <p class="body-2 mb-0">Terima kasih telah melakukan pembayaran.</p>
                        </v-col>
                    </v-row>
                    <v-divider/>
                    <v-row>
                        <v-col cols="12">
                            <v-card class="mb-0 pb-0" elevation="0" color="cyan lighten-5">
                                <v-row class="pa-2 mb-0">
                                    <v-col cols="6">Nomor Transaksi</v-col>
                                    <v-col class="text-right font-weight-bold" cols="6">{{info_payment.order_id}}</v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                     <v-row>
                        <v-col class="text-right" cols="12"> 
                            <p class="body-2 mb-0">Jumlah pembayaran</p>
                            <p class="body-1 font-weight-bold mb-1">{{info_payment.total}}</p>
                        </v-col>
                    </v-row>
                </v-card-text>
  
            </v-card>
            
        </v-col>
    </v-row>
   <v-bottom-sheet
      v-model="sheet_payment"
      
       inset
    >
      <v-sheet
        
      >
        <v-row class="no-gutters">
            
            <v-col class="body-2 text-right" cols='12'>
               <v-btn
          class="mb-0 pb-2 mt-2 text-right"
          text
          color="error"
          @click="sheet_payment = !sheet_payment"
        >
          <v-icon>fa-times</v-icon>
        </v-btn>
            </v-col>
          </v-row>
          <v-row class="pl-2">
            <v-col class="body-2 font-weight-bold" cols="12">
              {{payment_title}}
            </v-col>
            
          </v-row>
       
        <div class="pb-3">
          <v-row v-for="(step,ik) in payment_steps" :key="ik" class="no-gutters">
            <v-col class="text-center pb-2 body-2" cols='1'>
              {{ik+1}}.
            </v-col>
            <v-col class="body-2 pb-2 pr-2" cols='11'>
              {{step}}
            </v-col>
          </v-row>
        </div>
      </v-sheet>
    </v-bottom-sheet>



 <v-snackbar style="z-index:200000"
                    v-model="snackbar.value"
                    top
                    centered
                    :timeout="snackbar.timeout"
                    >
                    {{ snackbar.text }}
                    </v-snackbar>



                  
                

                  
</div>
                
</template>
<style>
.box-center {
        position: fixed;
        left: 50%;
        bottom: -50px;
        transform: translate(-50%, -50%);
        margin: 0 auto;
    }
    .fixed-bottom-bg{
        position: fixed;
        left: 50%;
        bottom: -52px;
        transform: translate(-50%, -50%);
        margin: 0 auto;
        box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12) !important;
    }

@media only screen and (min-device-width : 1024px){
/* Styles */
/* width */
::-webkit-scrollbar {
  height: 8px;              /* height of horizontal scrollbar ← You're missing this */
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
}

</style>
<script>
import axios from 'axios';
import InfiniteLoading from 'vue-infinite-loading';

  export default {
    name: 'xpaymentva',
    components: {
      InfiniteLoading
    },
    mounted(){
      //this.orders = []
      this.$store.commit("beranda/update_dialog_loading",true)
      this.$store.dispatch("order/statusVA", this.$route.params)

      if(this.$vuetify.breakpoint.name == 'xs'){
        this.window_width = window.innerWidth
      }
      else
        this.window_width = '560px'

    },
    beforeRouteLeave(to, from, next) {
                if(to.name === 'xorder'){
                    next(false)
                }
                else{
                  clearInterval(window.interval_status)
                  next()
                  
                }
                    

        },
    computed:{
      info_payment(){
        return this.$store.state.order.info_payment
      },
      selected_payment:{
        get() {
            return this.$store.state.order.selected_payment
                
        },
        set(val) {
              this.$store.commit("order/update_selected_payment",val)
        }
      },
      order_total:{
        get() {
            return this.$store.state.order.order_total
                
        },
        set(val) {
              this.$store.commit("order/update_order_total",val)
        }
      },
      window_width:{
        get() {
            return this.$store.state.beranda.window_width
                
        },
        set(val) {
              this.$store.commit("beranda/update_window_width",val)
        }
      },
    },
    methods:{
onCopy: function (e) {
      this.snackbar = {value:true,timeout:2000,text:"Nomor VA berhasil disalin"}
    },
    onError: function (e) {
      alert('Failed to copy texts')
    },
      openDetail(pay,i){
            this.payment_title = pay.label
            this.payment_steps = pay.steps
            this.sheet_payment  = true
          },
        goBack(){
            this.$router.push({name:'xberanda'})
        },
        goToPaymentMethodes(){
          this.dialog_payment_methode = true
        },
        thisPayment(payment){
          this.selected_payment = payment
          this.dialog_payment_methode = false
        },
        pay(){
          var payment = this.$store.state.order.selected_payment
          var ordertotal = this.order_total
          this.$store.commit("beranda/update_dialog_loading",true)
          this.$store.dispatch("order/pay",{order:ordertotal,payment:payment})
        }
        
    },
    data: () => ({
      snackbar:{value:false,timeout:2000,text:''},
      sheet_payment:false,
      paymwnt_title:'',
      payment_steps:[],
      infiniteId: +new Date(),
          masks: {
        input: 'DD-MM-YYYY',
      },
      infiniteId:1,
      dialog_payment_methode:false,
      dialog_daterange:false,
      isLogin:false,
      dialog_city:false,
      dialog_error:false,
      msg_error:'',
      reveal:false,
      dialog_branch:false,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      selected_city: {},
      categories:[{id:1,name:'PCR',active:false},{id:1,name:'Swab Antigen',active:false}],
      selected_categories:[],
      show: false,
      cities: [
          { name: 'Surabaya'},
          { name: 'Madiun'},
          { name: 'Salatiga'},
          { name: 'Magelang'},
          { name: 'Yogyakarta'},
          { name: 'Bandung'},
        ],
    }),
  }
</script>
