// 1 => LOADING
// 2 => DONE
// 3 => ERROR

import * as api from "@/api/api.js"
import moment from 'moment';
import router from '@/router'
export default {
    namespaced: true,
    state: {
        patients:[],
        selected_patient : {},
        tgl:'',
        bln:'',
        thn:'',
        adressesHS:[]
    },
    mutations: {
        update_adressesHS(state, val) {
            state.adressesHS = val
        },
        update_tgl(state, val) {
            state.tgl = val
        },
        update_bln(state, val) {
            state.bln = val
        },
        update_thn(state, val) {
            state.thn = val
        },
        update_patients(state, val) {
            state.patients = val
        },
        update_selected_patient(state, val) {
            state.selected_patient = val
        },
    },
    actions: {
        async getPatients(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               //var user = JSON.parse(localStorage.getItem("user"))
               //prm.token = user.token
               let fn_url = 'patient/getPatients'
               console.log(fn_url)
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  console.log(resp)
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("update_patients",resp.data.patients)
                  context.commit("order/update_ids",resp.data.ids,{root:true})
                  context.commit("update_selected_patient",{})
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",'Error Connection',{root:true})
            }
        },
        async getAddressesHS(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               //var user = JSON.parse(localStorage.getItem("user"))
               //prm.token = user.token
               let fn_url = 'patient/getAddressesHS'
               console.log(fn_url)
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  console.log(resp)
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("update_adressesHS",resp.data.adresses)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",'Error Connection',{root:true})
            }
        },
        async getCities(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let fn_url = 'order/getCities'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                if(resp.satus == 'INVALID_TOKEN'){
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
                  context.dispatch("login/logout",prm,{root:true})
                } else
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
                } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("order/update_cities",resp.data,{root:true})
                  //context.commit("update_selected_city",{})
                  context.commit("order/update_districts",[],{root:true})
                  context.commit("order/update_kelurahans",[],{root:true})
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },

        async getDistricts(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let fn_url = 'order/getDistricts'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                if(resp.satus == 'INVALID_TOKEN'){
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
                  context.dispatch("login/logout",prm,{root:true})
                } else
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
             } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("order/update_districts",resp.data,{root:true})
                  context.commit("order/update_selected_district",{},{root:true})
                  //context.commit("update_kelurahans",[])
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },

        async getKelurahans(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let fn_url = 'order/getKelurahans'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                if(resp.satus == 'INVALID_TOKEN'){
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
                  context.dispatch("login/logout",prm,{root:true})
                } else
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
             } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("order/update_kelurahans",resp.data,{root:true})
                  //context.commit("update_selected_kelurahan",{})
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async savePatient(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'order/savePatient'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  
                  context.commit("order/update_dialog_form",false,{root:true})
                  var user = JSON.parse(localStorage.getItem("user"))
                    context.dispatch("getPatients",user)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async deletePatient(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'patient/deletePatient'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  var user = JSON.parse(localStorage.getItem("user"))
                    context.dispatch("getPatients",user)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async deleteAddressHS(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'patient/deleteAddressHS'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  var user = JSON.parse(localStorage.getItem("user"))
                    context.dispatch("getAddressesHS",user)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },

    }
}