// 1 => LOADING
// 2 => DONE
// 3 => ERROR

import * as api from "@/api/api.js"
import router from '@/router'
export default {
    namespaced: true,
    state: {
        regionals:[],
        selected_regional:{},
        branches:[],
        brancheshs:[],
        selected_branch:{},
        dialog_regional:false,
        header:1,
        subcategories:[],
        selected_subcategory:[],
        loading:false,
        loading_content:false,
        current_place:null,
        location: { lat: 45.508, lng: -73.587 },
        populer:[],
        categories:[],
        selected_category:{},
        markers:[],
        places:[],
        new_address:{
            label : '',
            address:'',
            details:'',
            lat:'',
            lng:'',
            is_selected:false,
            is_utama:false
        },
        dialog_new_address:false,
        hs_address:[],
        selected_hs_address:{},
        loading_search_near_branch:false,
        not_found_near_branch:false,
        packet_category:[],
        carousel:[],
        selected_packet_category:{},
        dialog_map:false,
        packets:[],
        selected_packet:{},
        favorites:[],
        display_categories:[]
    },
    mutations: {
        update_brancheshs(state, val) {
            state.brancheshs = val
        },
        update_display_categories(state, val) {
            state.display_categories = val
        },
        update_favorites(state, val) {
            state.favorites = val
        },
        update_packets(state, val) {
            state.packets = val
        },
        update_selected_packet(state, val) {
            state.selected_packet = val
        },
        update_dialog_map(state, val) {
            state.dialog_map = val
        },
        update_selected_category(state, val) {
            state.selected_category = val
        },
        update_selected_packet_category(state, val) {
            state.selected_packet_category = val
        },
        update_carousel(state, val) {
            state.carousel = val
        },
        update_packet_category(state, val) {
            state.packet_category = val
        },
        update_not_found_near_branch(state, val) {
            state.not_found_near_branch = val
        },
        update_loading_search_near_branch(state, val) {
            state.loading_search_near_branch = val
        },
        update_selected_hs_address(state, val) {
            state.selected_hs_address = val
        },
        update_hs_address(state, val) {
            state.hs_address = val
        },
        update_dialog_new_address(state, val) {
            state.dialog_new_address = val
        },
        update_new_address(state, val) {
            state.new_address = val
        },
        update_markers(state, val) {
            state.markers = val
        },
        update_places(state, val) {
            state.places = val
        },
        update_categories(state, val) {
            state.categories = val
        },
        update_populer(state, val) {
            state.populer = val
        },
        update_location(state, val) {
            state.location = val
        },
        update_current_place(state, val) {
            state.current_place = val
        },
        update_loading_content(state, val) {
            state.loading_content = val
        },
        update_loading(state, val) {
            state.loading = val
        },
        update_selected_subcategory(state, val) {
            state.selected_subcategory = val
        },
        update_subcategories(state, val) {
            state.subcategories = val
        },
        update_header(state, val) {
            state.header = val
        },
        update_dialog_regional(state, val) {
            state.dialog_regional = val
        },
        update_regionals(state, val) {
            state.regionals = val
        },
        update_selected_regional(state, val) {
            state.selected_regional = val
        },
        update_branches(state, val) {
            state.branches = val
        },
        update_selected_branch(state, val) {
            state.selected_branch = val
        }
    },
    actions: {
        async getregionals_nearest_without_gps(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'choosebranch/nearest'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  //context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("update_loading_content",false)
                  context.commit("beranda/update_api_status",3,{root:true})
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  //context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("update_regionals",resp.data.regionals)
                  context.commit("update_selected_regional",{})
                  context.commit("update_branches",[])
                  context.commit("update_dialog_regional",true)
                  context.commit("update_loading_content",false)
               }
            } catch(e) {
                //context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("update_loading_content",false)
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async getregionals_nearest(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
                //context.commit("update_api_status",true)
               let fn_url = 'choosebranch/nearest'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  //context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
                  context.commit("update_loading_content",false)
               } else {
                  //context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("update_loading_content",false)
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("update_regionals",resp.data.regionals)
                  context.commit("update_selected_regional",resp.data.selected_regional)
                  context.commit("update_branches",resp.data.branches)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("update_loading_content",false)
                //context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async getPopuler(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
                //var prm = { lat: 45.508, lng: -73.587 }
                var user = JSON.parse(localStorage.getItem("user"))
                if(user)
                prm.token = user.token
               let fn_url = 'choosebranch/getPopuler'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("update_populer",resp.data)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async getCategories(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
                //var prm = context.state.selected_branch
               let fn_url = 'choosebranch/getCategories'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("update_categories",resp.data)
                  var display_cat = []
                  resp.data.forEach((element,k) => {
                      if(k < 6)
                      display_cat.push(element)
                  });
                  context.commit("update_display_categories",display_cat)

               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async getPacketCategory(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
                
               let fn_url = 'choosebranch/getPacketCategories'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("update_packet_category",resp.data)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async getPacketListing(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
                var user = JSON.parse(localStorage.getItem("user"))
                if(user)
                prm.token = user.token
               let fn_url = 'choosebranch/getPacketListing'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("update_packets",resp.data)
                  context.commit("update_selected_packet",{})
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async getFavoriteListing(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
                var user = JSON.parse(localStorage.getItem("user"))
                if(user)
                prm.token = user.token
               let fn_url = 'choosebranch/getFavoriteListing'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("update_favorites",resp.data)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async getCarousel(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
                
               let fn_url = 'choosebranch/getCarousel'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("update_carousel",resp.data)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async getbranches(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
                //context.commit("update_api_status",true)
               let fn_url = 'choosebranch/getbranches'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                  //context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
                  context.commit("update_loading_content",false)
               } else {
                  //context.commit("beranda/update_loading_content",false,{root:true})
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("update_branches",resp.data)
                  context.commit("update_dialog_regional",false)
                  context.commit("update_loading_content",false)
               }
            } catch(e) {
                //context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("update_loading_content",false)
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        
        async searchRegional(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'choosebranch/searchRegional'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("update_loading",false)
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  context.commit("update_regionals",resp.data)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async saveNewAddressJS(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
               let fn_url = 'choosebranch/saveNewAddressPHP'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                  //context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  //context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  context.commit("update_selected_hs_address",resp.data)
                  router.push({name:'xsetplacehs'})
                  //context.commit("update_dialog_new_address",false)
                  //context.dispatch("getSavedAddress",{})
               }
            } catch(e) {
                //context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async getSavedAddress(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
               let fn_url = 'choosebranch/getHsAddress'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                  //context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  //context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data.length)
                  context.commit("update_hs_address",resp.data)
                  context.commit("update_dialog_new_address",false)
                  
               }
            } catch(e) {
                //context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",'',{root:true})
            }
        },
        async checkSavedAddress(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
               let fn_url = 'choosebranch/getHsAddress'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                  //context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  //context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  console.log(resp.data.length)
                  if(resp.data.length == 0){
                    router.push({name:'xformaddresshs'})
                  }
                  else{
                    context.commit("update_hs_address",resp.data)
                    context.commit("update_dialog_new_address",false)
                    router.push({name:'xsetplacehs'})
                  }
                  
               }
            } catch(e) {
                //context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",'',{root:true})
            }
        },
        async getbranchhs(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
               let fn_url = 'choosebranch/getbranchhs'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                  //context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  //context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  console.log(resp.data.length)
                 
                  
               }
            } catch(e) {
                //context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",'',{root:true})
            }
        },
        async getNearBranch(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
                //context.commit("update_api_status",true)
               let fn_url = 'choosebranch/getnearbranch'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                    context.commit("update_not_found_near_branch",false)
                    context.commit("beranda/update_dialog_loading",false,{root:true})
                    context.commit("beranda/update_api_status",3,{root:true})
                    if(resp.status == 'INVALID_TOKEN'){
                        context.commit("beranda/update_api_error_message",resp.message,{root:true})
                        context.dispatch("login/logout",prm,{root:true})
                    } else
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
             } else {
                  context.commit("update_not_found_near_branch",false)
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("update_loading_search_near_branch",false)
                  if(resp.data){
                    context.commit("update_brancheshs",resp.data)
                    context.commit("update_not_found_near_branch",false)
                  }
                  else{
                    context.commit("update_brancheshs",{})
                    context.commit("update_not_found_near_branch",true)
                  }
                  
               }
            } catch(e) {
                context.commit("update_not_found_near_branch",false)
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async getPriceHs(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
               let fn_url = 'choosebranch/getPriceHs'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                  //context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  //context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  if(resp.data){
                    var x_branch = prm.selected_branch
                    x_branch.distance = prm.distance
                    x_branch.hs_cost = resp.data.HS_PriceAmount
                    context.commit("update_selected_branch",x_branch)
                  }else{
                    var brancheshs = context.state.brancheshs
                    brancheshs.forEach((entry,k) => {
              
                        brancheshs[k].is_selected = false
                        brancheshs[k].distance = '-'
                        brancheshs[k].hs_cost = '-'
                      
                      
                    })
                    context.commit("update_brancheshs",brancheshs)
                    context.commit("update_selected_branch",{})
                    context.commit("beranda/update_api_error_message",'Maaf layanan Homeservice belum tersedia',{root:true})
                  }
                  
                 
                  
               }
            } catch(e) {
                //context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",'',{root:true})
            }
        },
    }
}