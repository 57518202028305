// 1 => LOADING
// 2 => DONE
// 3 => ERROR


export default {
    namespaced: true,
    state: {
        lng:0,
        lat:0,
        window_width:0
    },
    mutations: {
        update_lng(state, val) {
            state.lng = val
        },
        update_lat(state, val) {
            state.lat = val
        },
        update_window_width(state, val) {
            state.window_width = val
        }
    },
    actions: {
        
    }
}