// 1 => LOADING
// 2 => DONE
// 3 => ERROR

import * as api from "@/api/api.js"
import moment from 'moment';
import router from '@/router'

export default {
    namespaced: true,
    state: {
        
        start_date:'',
        end_date:'',
        tests:[],
        selected_tests:[],
        selected_test_varian:[],
        result_option:[],
        selected_result_option:{},
        date_sampling:'',
        selected_date:'',
        attrs:  [
            {
              key: 'selected',
              highlight: true,
              dates: ''
            }
          ],
        days:[],
        times:[],
        selected_time:{},
        selected_patient:[],
        is_klinisi:false,
        referal_code:'',
        test_purposes:[],
        selected_test_purpose:{},
        provinces:[],
        selected_province:{},
        cities:[],
        selected_city:{},
        districts:[],
        selected_district:{},
        kelurahans:[],
        selected_kelurahan:{},
        patient_id:0,
        ktp:'',
        name:'',
        job:'',
        sex:'',
        dob:moment(new Date()).format('DD-MM-YYYY'),
        pramitaid:'',
        address:'',
        ids:[],
        selected_id:{},
        patients:[],
        dialog_form:false,
        localstorage:[],
        deliveries:[],
        errors:[],
        data_order:{},
        opt_qrcode:{
            cellSize: 8,
            correctLevel: 'H',
            data: 'F4REY'
        },
        orders:[],
        order_total:{},
        range_date:[],
        page:1,
        patientNumbers:[{'id':1,'name':'1 PASIEN'},{'id':2,'name':'2 PASIEN'},{'id':3,'name':'3 PASIEN'},{'id':4,'name':'4 PASIEN'},{'id':5,'name':'5 PASIEN'}],
        patient_number:{'id':1,'name':'1 PASIEN'},
        payment_methodes:[],
        selected_payment:{},
        info_payment:{},
        list_header:{},
        list_payment:{},
        data_timeline:{},
        rpt_url:'',
        dialog_rpt:false,
        data_report:[],
        selected_report:{},
        row_header:{},
        titles:[],
        selected_title:{},
        hp:'',
        email:'',
        delete_akun_otp:false,
        show_methode:false,
        show_daftar_otp:false,
        show_resend_otp:false,
        dialog_delete_account:false,
        otp_counting:-1,
        otp:'',
        success_delete_account:false
    },
    mutations: {
        update_success_delete_account(state, val) {
            state.success_delete_account= val
        },
        update_otp(state, val) {
            state.otp= val
        },
        update_otp_counting(state, val) {
            state.otp_counting= val
        },
        update_dialog_delete_account(state, val) {
            state.dialog_delete_account= val
        },
        update_show_resend_otp(state, val) {
            state.show_resend_otp= val
        },
        update_show_daftar_otp(state, val) {
            state.show_daftar_otp= val
        },
        update_show_methode(state, val) {
            state.show_methode= val
        },
        update_delete_akun_otp(state, val) {
            state.delete_akun_otp= val
        },
        update_email(state, val) {
            state.email= val
        },
        update_hp(state, val) {
            state.hp= val
        },
        update_titles(state, val) {
            state.titles= val
        },
        update_selected_title(state, val) {
            state.selected_title= val
        },
        update_row_header(state, val) {
            state.row_header= val
        },
        update_selected_report(state, val) {
            state.selected_report= val
        },
        update_data_report(state, val) {
            state.data_report= val
        },
        update_dialog_rpt(state, val) {
            state.dialog_rpt= val
        },
        update_rpt_url(state, val) {
            state.rpt_url= val
        },
        update_data_timeline(state, val) {
            state.data_timeline= val
        },
        update_list_header(state, val) {
            state.list_header= val
        },
        update_list_payment(state, val) {
            state.list_payment= val
        },
        update_info_payment(state, val) {
            state.info_payment = val
        },
        update_selected_payment(state, val) {
            state.selected_payment = val
        },
        update_payment_methodes(state, val) {
            state.payment_methodes = val
        },
        update_order_total(state, val) {
            state.order_total = val
        },
        update_patientNumbers(state, val) {
            state.patientNumbers = val
        },
        update_patient_number(state, val) {
            state.patient_number = val
        },
        update_page(state, val) {
            state.page = val
        },
        update_range_date(state, val) {
            state.range_date = val
        },
        update_end_date(state, val) {
            state.end_date = val
        },
        update_start_date(state, val) {
            state.start_date = val
        },
        update_orders(state, val) {
            state.orders = val
        },
        update_opt_qrcode(state, val) {
            state.opt_qrcode = val
        },
        update_data_order(state, val) {
            state.data_order = val
        },
        update_errors(state, val) {
            state.errors = val
        },
        update_deliveries(state, val) {
            state.deliveries = val
        },
        update_localstorage(state, val) {
            state.localstorage = val
        },
        update_dialog_form(state, val) {
            state.dialog_form = val
        },
        update_patients(state, val) {
            state.patients = val
        },
        update_patient_id(state, val) {
            state.patient_id = val
        },
        update_ids(state, val) {
            state.ids = val
        },
        update_selected_id(state, val) {
            state.selected_id = val
        },
        update_address(state, val) {
            state.address = val
        },
        update_pramitaid(state, val) {
            state.pramitaid = val
        },
        update_dob(state, val) {
            state.dob = val
        },
        update_sex(state, val) {
            state.sex = val
        },
        update_job(state, val) {
            state.job = val
        },
        update_name(state, val) {
            state.name = val
        },
        update_ktp(state, val) {
            state.ktp = val
        },
        update_cities(state, val) {
            state.cities = val
        },
        update_selected_city(state, val) {
            state.selected_city = val
        },
        update_districts(state, val) {
            state.districts = val
        },
        update_selected_district(state, val) {
            state.selected_district = val
        },
        update_kelurahans(state, val) {
            state.kelurahans = val
        },
        update_selected_kelurahan(state, val) {
            state.selected_kelurahan = val
        },
        update_provinces(state, val) {
            state.provinces = val
        },
        update_selected_province(state, val) {
            state.selected_province = val
        },
        update_selected_test_purpose(state, val) {
            state.selected_test_purpose = val
        },
        update_test_purposes(state, val) {
            state.test_purposes = val
        },
        update_is_klinisi(state, val) {
            state.is_klinisi = val
        },
        update_referal_code(state, val) {
            state.referal_code = val
        },
        update_selected_patient(state, val) {
            state.selected_patient = val
        },
        update_selected_time(state, val) {
            state.selected_time = val
        },
        update_times(state, val) {
            state.times = val
        },
        update_days(state, val) {
            state.days = val
        },
        update_selected_date(state, val) {
            state.selected_date = val
            state.selected_time = {}
        },
        update_selected_result_option(state, val) {
            state.selected_result_option = val
        },
        update_result_option(state, val) {
            state.result_option = val
        },
        update_selected_test_varian(state, val) {
            state.selected_test_varian = val
        },
        update_baru(state, val) {
            state.baru = val
        },
        update_attrs(state, val) {
            state.attrs = val
        },
        update_date_sampling(state, val) {
            state.date_sampling = val
        },
        update_tests(state, val) {
            state.tests = val
        },
        update_selected_tests(state, val) {
            state.selected_tests = val
        }
    },
    actions: {
        async getTests(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'order/getTest'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  console.log(resp)
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("update_tests",resp.data)
                  context.commit("update_selected_tests",[])
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async getTestPrice(context,prm) {
            
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'order/getTestPrice'
               let resp= await api.getdata(prm,fn_url)
               
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  //console.log(resp)
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  
                  //context.commit("update_result_option",resp.data.result_option)
                  //context.commit("update_selected_result_option",resp.data.selected_result_option)
                  //context.commit("update_selected_test_varian",[resp.data.selected_test])

                  //context.commit("update_selected_date",moment(resp.data.datenow).format('DD-MM-YYYY'))
                 //context.commit("update_date_sampling",moment(resp.data.datenow).format('YYYY-MM-DD'))
                
                  let days = []
                  days.push({
                    id: moment(new Date(resp.data.datenow)).format('YYYY-MM-DD'),
                    date: new Date(resp.data.datenow)
                  })
                  context.commit("update_days",days)
                  //console.log( context.state)
                  context.commit("update_times",resp.data.times)

                  
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async getPriceByResultOption(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'order/getPriceByResultOption'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  console.log(resp)
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("update_selected_test_varian",[resp.data])
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async searchProvince(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
                var user = JSON.parse(localStorage.getItem("user"))
                prm.token = user.token
               let fn_url = 'patient/searchProvince'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  console.log(resp)
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("order/update_provinces",resp.data,{root:true})
                  context.commit("order/update_selected_province",{},{root:true})
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async searchProvinceRegister(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
                //var user = JSON.parse(localStorage.getItem("user"))
                //prm.token = user.token
               let fn_url = 'patient/searchProvince'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  console.log(resp)
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("order/update_provinces",resp.data,{root:true})
                  context.commit("order/update_selected_province",{},{root:true})
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async getTitles(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
                var user = JSON.parse(localStorage.getItem("user"))
                prm.token = user.token
               let fn_url = 'patient/getTitles'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  console.log(resp)
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("order/update_titles",resp.data,{root:true})
                  context.commit("order/update_selected_title",{},{root:true})
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async getCitiesRegister(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                console.log('modules')
                console.log(prm)
               //var user = JSON.parse(localStorage.getItem("user"))
               //prm.token = user.token
               let fn_url = 'order/getCities'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                if(resp.satus == 'INVALID_TOKEN'){
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
                  context.dispatch("login/logout",prm,{root:true})
                } else
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
                } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("update_cities",resp.data)
                  context.commit("update_selected_city",{})
                  context.commit("update_districts",[])
                  context.commit("update_kelurahans",[])
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",'',{root:true})
            }
        },
        async getCities(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                console.log('modules')
                console.log(prm)
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let fn_url = 'order/getCities'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                if(resp.satus == 'INVALID_TOKEN'){
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
                  context.dispatch("login/logout",prm,{root:true})
                } else
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
                } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("update_cities",resp.data)
                  context.commit("update_selected_city",{})
                  context.commit("update_districts",[])
                  context.commit("update_kelurahans",[])
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",'',{root:true})
            }
        },

        async getDistricts(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let fn_url = 'order/getDistricts'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                if(resp.satus == 'INVALID_TOKEN'){
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
                  context.dispatch("login/logout",prm,{root:true})
                } else
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
             } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("update_districts",resp.data)
                  context.commit("update_selected_district",{})
                  context.commit("update_kelurahans",[])
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",'',{root:true})
            }
        },

        async getDistrictsRegister(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               //var user = JSON.parse(localStorage.getItem("user"))
               //prm.token = user.token
               let fn_url = 'order/getDistricts'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                if(resp.satus == 'INVALID_TOKEN'){
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
                  context.dispatch("login/logout",prm,{root:true})
                } else
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
             } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("update_districts",resp.data)
                  context.commit("update_selected_district",{})
                  context.commit("update_kelurahans",[])
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",'',{root:true})
            }
        },

        async getKelurahans(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let fn_url = 'order/getKelurahans'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                if(resp.satus == 'INVALID_TOKEN'){
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
                  context.dispatch("login/logout",prm,{root:true})
                } else
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
             } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("update_kelurahans",resp.data)
                  context.commit("update_selected_kelurahan",{})
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },

        async getKelurahansRegister(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               //var user = JSON.parse(localStorage.getItem("user"))
               //prm.token = user.token
               let fn_url = 'order/getKelurahans'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                if(resp.satus == 'INVALID_TOKEN'){
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
                  context.dispatch("login/logout",prm,{root:true})
                } else
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
             } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("update_kelurahans",resp.data)
                  context.commit("update_selected_kelurahan",{})
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        
        async getTestPurpose(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'order/getTestPurpose'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)
                  context.commit("update_patients",resp.data.patients)
                  context.commit("update_test_purposes",resp.data.purposes)
                  context.commit("update_provinces",resp.data.provinces)
                  context.commit("update_deliveries",resp.data.deliveries)
                  context.commit("update_selected_province",{})
                  context.commit("update_ids",resp.data.ids)
                  context.commit("update_selected_id",{})
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",'',{root:true})
            }
        },
        
        async savePatient(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'order/savePatient'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  context.commit("update_patients",resp.data)
                  context.commit("update_dialog_form",false)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },

        async checkOut(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'order/checkOut'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  context.commit("update_patient_number",{'id':1,'name':'1 PASIEN'})
                  context.commit("update_selected_patient",[])
                  context.commit("update_selected_province",{})
                  context.commit("update_selected_result_option",{})
                  context.commit("update_selected_test_purpose",{})
                  context.commit("update_selected_test_varian",[])
                  context.commit("update_selected_time",{})
                  context.commit("update_tests",[])
                  context.commit("update_times",[])
                  context.commit("choosebranch/update_selected_branch",{},{root:true})

                  context.commit("update_date_sampling",'')
                  context.commit("update_result_option",[])
                  context.commit("update_selected_city",{})
                  context.commit("update_selected_district",{})

                  context.commit("update_selected_kelurahan",{})
                  context.commit("update_selected_id",{})
                  context.commit("update_localstorage",{})
                  
                


                  router.push({ path: `/checkout/id/${resp.data}`})
                  //context.commit("update_patients",resp.data)
                  //context.commit("update_dialog_form",false)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },

        async getDataOrder(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'test/getdataorder'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  context.commit("update_data_order",resp.data)
                  context.commit("update_row_header",resp.row)
           
                  //context.commit("update_dialog_form",false)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },

        async getDataOrderList(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'test/getdataorder_list'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  context.commit("update_data_order",resp.data.details)
                  context.commit("update_list_header",resp.data.header)
                  context.commit("update_list_payment",resp.data.payment?[]:resp.data.payment)
           
                  //context.commit("update_dialog_form",false)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },

        async getDataOrderTimeline(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'order/getdataorder_timeline'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  context.commit("update_data_timeline",resp.data)
           
                  //context.commit("update_dialog_form",false)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },

        async getOrders(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'order/getOrders'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  context.commit("update_orders",resp.data)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async getOrders_status(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'order/getOrders_status'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  context.commit("update_orders",resp.data)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },

        async getTimes(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'order/getTimes'
               var user = JSON.parse(localStorage.getItem("user"))
              // prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  context.commit("update_times",resp.data)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },

        async getDataOrderTotal(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'order/getOrderTotal'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  context.commit("update_order_total",resp.data)
           
                  //context.commit("update_dialog_form",false)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },

        async pay(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'test/pay'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  router.push({ path: `/payment/id/${prm.order.order_number}/code/${prm.payment.code}`})
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },

        async statusVA(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'order/getStatusVA'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  context.commit("update_info_payment",resp.data)
                  if(resp.data.payment_type !== '1' && resp.data.payment_type !== '5'){
                    window.interval_status = setInterval(function(){ context.dispatch("statusVARe",prm) }, 1000)
                  }
                  else if(resp.data.payment_type == '5'){
                    router.push({ path: `/listorder`})
                  }
                  else
                  window.location = resp.data.url_pay;
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async statusVARe(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'order/getStatusVA'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  context.commit("update_info_payment",resp.data)
                  if(resp.data.is_lunas === 'Y'){
                    clearInterval(window.interval_status)
                  }
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },

        async checkPg(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'order/check_pg'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  context.commit("update_info_payment",resp.data)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async getReport(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'order/getReport'
               var user = JSON.parse(localStorage.getItem("user"))
               prm.token = user.token
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  if(resp.satus == 'INVALID_TOKEN'){
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    context.dispatch("login/logout",prm,{root:true})
                  } else
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  context.commit("update_data_report",resp.data)
                  //context.commit("update_selected_report",resp.data[0])
                  //context.commit("update_dialog_rpt",true)
               }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async getOTP(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'login/getOTP'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  
                  context.commit("update_dialog_confirmation",false)
                  context.commit("update_show_daftar_otp",true)
                  context.commit("update_show_daftar",false)
                  var now  = moment(new Date(),"YYYY-MM-DD HH:mm:ss")
                  var then = moment(resp.data.time,"YYYY-MM-DD HH:mm:ss")
                  var incomeTicker = 120;
                  context.commit("update_show_resend_otp",false)
                  if (window.interval_login) clearInterval(window.interval_login)
                  window.interval_login = window.setInterval(function(){
                    if (incomeTicker > 0)
                        incomeTicker--;
                        context.commit("update_otp_counting",incomeTicker)
                        if (incomeTicker == 0){
                            clearInterval(window.interval_login)
                            context.commit("update_show_resend_otp",true)
                        }
                    }, 1000);   
                  //var rest_count = now.diff(then, 'seconds')
                  var user = JSON.parse(localStorage.getItem("user"))
                  if ('OTPCredential' in window) { 

                    const ac = new AbortController();
                    navigator.credentials.get({
                        otp: { transport:['sms'] },
                        signal: ac.signal
                    }).then(otp => {
                        console.log(otp)
                        let value = otp.code
                        let hp = user.username
                        if(value.length == 4){
                            context.commit("beranda/update_dialog_loading",true,{root:true})
                            context.dispatch("checkOTP", {otp:value,hp:hp})
                        }
                    }).catch(err => {
                        console.log(err)
                    });
                    
                    
                  } else {
                    console.log('WebOTP not supported!.')
                  }

                 
                }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async checkOTP(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'login/checkOTP'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)show_daftar_otp
                  context.commit("update_dialog_delete_account",false)
                  var user = JSON.parse(localStorage.getItem("user"))
                   context.dispatch('login/delete_account',user,{root:true})
                  
                  
                }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
    }
}