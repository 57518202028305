<template>
  <div style="overflow-x:hidden;overflow-y:hidden">
    
    <v-app-bar
      color="white"
      fixed
      elevation="1"
      height="59px"
    >
        <v-list-item class="mx-auto pa-0">
        <v-list-item-avatar>
            <v-icon style="cursor:pointer" @click="goBack()">mdi-arrow-left</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-subtitle class="font-weight-bold title">
            <v-row no-gutters>
                <v-col cols="8">
                Daftar Pesanan
                </v-col>
            </v-row>
            </v-list-item-subtitle>
        </v-list-item-content>
        </v-list-item>
    </v-app-bar>
    <v-row class="mx-auto no-gutters mt-15 mb-0 pb-0">
        <v-col class="mb-0 pt-2 pb-0" cols="12">
          <v-card tile elevation="5">
              <v-card-text class="pt-2 pb-2">
                <v-row class="pt-0 pb-0">
                    <v-col class="body-1 pt-0 pb-0" cols="6">
                        Nomor
                    </v-col>
                    <v-col class="body-1 font-weight-bold pt-0 pb-0 text-right pr-4" cols="6">
                        {{order_total.order_number}}
                    </v-col>
                </v-row>
              </v-card-text>
              <v-divider/>
              <v-card-text class="pt-2 pb-3">
                <v-row class="pt-0 pb-0">
                    <v-col class="body-1 pt-0 pb-0" cols="6">
                        Tanggal
                    </v-col>
                    <v-col class="font-weight-bold body-1 pt-0 pb-0 text-right pr-4" cols="6">
                        {{order_total.order_date}}
                    </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          <v-card tile elevation="5">
              <v-card-title class="body-1 mt-2 pt-2 pb-2 font-weight-bold">Metode Pembayaran</v-card-title>
              <v-divider/>
              <v-card-text>
                <p v-if="_.isEmpty(selected_payment)" @click="goToPaymentMethodes()" class="mb-1 body-1">Pilih Metode Pembayaran</p>
                <v-list-item class="pl-0 ml-0" v-if="!_.isEmpty(selected_payment)"  @click="goToPaymentMethodes()">
                    <v-list-item-avatar v-if="selected_payment.icon && selected_payment.icon !== ''" class="mt-0 pt-0 mb-0 pb-0" tile>
                      <v-img height="64" contain  :src="selected_payment.icon"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{selected_payment.name}}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="mr-0 pr-0" align="right">
                      <v-icon class="text-right mr-0 pr-0">fa-edit</v-icon>
                    </v-list-item-action>
                </v-list-item>
              </v-card-text>
            </v-card>
            <v-card tile elevation="5">
              <v-card-title class="body-1 pt-2 mt-2  pb-2 font-weight-bold">{{order_total.branch_name}}</v-card-title>
              <v-divider/>
              <v-card-text v-if="order_total.test">
                <p class="mb-1 body-1">{{order_total.test.test_name}}</p>
                <p v-if="order_total.test.result_option_name !== ''" class="mb-1"><kbd class="warning white--text">{{order_total.test.result_option_name}}</kbd></p>
                <v-row>
                    <v-col class="body-2 text-right pr-4" cols="12">
                        {{order_total.patients.length}}x <span class="font-weight-bold body-1">{{order_total.test.total}}</span>
                    </v-col>
                </v-row>
              <v-divider v-if="parseInt(order_total.discount_total) > 0"/>
              <v-row v-if="parseInt(order_total.discount_total) > 0" v-for="(discount,kd) in order_total.discount_details" :key="kd" class="mb-0 pb-0">
                  <v-col cols="6">
                    <span class="body-1  mb-0 pb-0">{{discount.promo_name}}</span>
                  </v-col>
                  <v-col class="text-right mb-0 pb-0 pr-4" cols="6">
                    <span class="red--text body-1 mb-0 pb-0">{{discount.text}}</span>
                  </v-col>
                </v-row>
              <v-divider/>
                <v-row class="mb-0 pb-0">
                  <v-col cols="6">
                    <span class="body-1  mb-0 pb-0">Biaya Layanan</span>
                  </v-col>
                  <v-col class="text-right mb-0 pb-0 pr-4" cols="6">
                    <span class="body-1 mb-0 pb-0">{{order_total.fee}}</span>
                  </v-col>
                </v-row>
                <v-row v-if="parseInt(order_total.number_ehac_fee) > 0" class="mb-0 pb-0">
                  <v-col cols="6">
                    <span class="body-1  mb-0 pb-0">Biaya Admin eHAC</span>
                  </v-col>
                  <v-col class="text-right mb-0 pb-0 pr-4" cols="6">
                    {{order_total.ehac_patient}}x <span class="body-1 mb-0 pb-0">{{order_total.ehac_fee}}</span>
                  </v-col>
                </v-row>
                <v-row class="pt-0 mt-0">
                  <v-col class="pt-0 mt-0" cols="6">
                    <span class="body-1 font-weight-bold">Total</span>
                  </v-col>
                  <v-col class="pt-0 mt-0 text-right pr-4" cols="6">
                    <span class="body-1 font-weight-bold"><span class="pr-2 red--text text-decoration-line-through" v-if="parseInt(order_total.discount_total) > 0">{{numberWithCommas(order_total.number_total_fee+order_total.discount_total)}} </span> {{order_total.total}}</span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            
            
        </v-col>
        
    </v-row>

    

                  
    <v-row style="width:100%;background:#fff;" class="fixed-bottom-bg" justify="center">
        <v-col cols="12"  class="text-center mb-2">
            <v-card flat >
                <v-row>
                    <v-col class="text-right pt-1" cols="12">
                            <v-btn
                              v-if="!_.isEmpty(selected_payment)"
                                @click="pay()"
                                color="red lighten-1"
                                dark
                                block
                            >
                            Bayar sekarang
                            </v-btn>
                            <v-btn
                              v-if="_.isEmpty(selected_payment)"
                                @click="pay()"
                                disabled
                                block
                            >
                            Bayar sekarang
                            </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>

    <v-dialog
      v-model="dialog_payment_methode"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card >
        <v-toolbar
          
          flat
          justify="center"
        >
          <v-btn
            icon
            class="text-center"
            @click="dialog_payment_methode = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card class="mx-auto" flat width="360px">
          <v-list
            subheader
          >
            <template v-for="payment in payment_methodes">
              <v-subheader v-if="payment.is_parent === 'Y'">{{payment.name}}</v-subheader>
              <v-list-item @click="thisPayment(payment)" v-if="payment.is_parent === 'N'">
                <v-list-item-avatar v-if="payment.icon && payment.icon !== ''"class="mt-0 pt-0 mb-0 pb-0" tile>
                  <v-img height="64"contain  :src="payment.icon"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>

                  <v-list-item-title>
                    {{payment.name}}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon v-if="selected_payment.id !== payment.id">mdi-checkbox-blank-outline</v-icon>
                  <v-icon v-if="selected_payment.id === payment.id">mdi-checkbox-marked</v-icon>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-card>

    </v-dialog>


                  
                

                  
</div>
                
</template>
<style>
.box-center {
        position: fixed;
        left: 50%;
        bottom: -50px;
        transform: translate(-50%, -50%);
        margin: 0 auto;
    }
    .fixed-bottom-bg{
        position: fixed;
        left: 50%;
        bottom: -52px;
        transform: translate(-50%, -50%);
        margin: 0 auto;
        box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12) !important;
    }

@media only screen and (min-device-width : 1024px){
/* Styles */
/* width */
::-webkit-scrollbar {
  height: 8px;              /* height of horizontal scrollbar ← You're missing this */
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
}

</style>
<script>
import axios from 'axios';
import InfiniteLoading from 'vue-infinite-loading';

  export default {
    name: 'xlistorder',
    components: {
      InfiniteLoading
    },
    mounted(){
      //this.orders = []
      this.$store.commit("beranda/update_dialog_loading",true)
      this.$store.dispatch("order/getDataOrderTotal", this.$route.params)

      if(this.$vuetify.breakpoint.name == 'xs'){
        this.window_width = window.innerWidth
      }
      else
        this.window_width = '560px'

    },
    computed:{
      payment_methodes(){
     //console.log(this.$store.state.order.order_total.payments)
        return this.$store.state.order.order_total.payments
      },
      selected_payment:{
        get() {
            return this.$store.state.order.selected_payment
                
        },
        set(val) {
              this.$store.commit("order/update_selected_payment",val)
        }
      },
      order_total:{
        get() {
            return this.$store.state.order.order_total
                
        },
        set(val) {
              this.$store.commit("order/update_order_total",val)
        }
      },
      window_width:{
        get() {
            return this.$store.state.beranda.window_width
                
        },
        set(val) {
              this.$store.commit("beranda/update_window_width",val)
        }
      },
    },
    methods:{
        goBack(){
            this.$router.push({name:'xberanda'})
        },
        goToPaymentMethodes(){
          this.dialog_payment_methode = true
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        thisPayment(payment){
          this.selected_payment = payment
          this.dialog_payment_methode = false
          var payment = this.$store.state.order.selected_payment
          var ordertotal = this.order_total
          var number_total = ordertotal.number_total
          var fee_original = ordertotal.number_fee_original
          var ehac_sum = ordertotal.number_ehac_fee_sum
          //console.log(parseInt(payment.pg_id))
          if(parseInt(payment.type_id) == 1){
            ordertotal.number_fee = ( (1/100) * number_total ) + fee_original + ehac_sum
            ordertotal.fee = this.numberWithCommas(( (1/100) * number_total ) + fee_original)
          }
          else{
            ordertotal.number_fee = fee_original + ehac_sum 
            ordertotal.fee = this.numberWithCommas(fee_original)
            console.log(ordertotal)
          }
          ordertotal.number_total_fee =  number_total + ordertotal.number_fee - parseInt(ordertotal.discount_total)
          ordertotal.total = this.numberWithCommas(ordertotal.number_total_fee)
          this.order_total = ordertotal
        },
        pay(){
          var payment = this.$store.state.order.selected_payment
          var ordertotal = this.order_total
          this.$store.commit("beranda/update_dialog_loading",true)
          this.$store.dispatch("order/pay",{order:ordertotal,payment:payment})
        }
        
    },
    data: () => ({
      infiniteId: +new Date(),
          masks: {
        input: 'DD-MM-YYYY',
      },
      infiniteId:1,
      dialog_payment_methode:false,
      dialog_daterange:false,
      isLogin:false,
      dialog_city:false,
      dialog_error:false,
      msg_error:'',
      reveal:false,
      dialog_branch:false,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      selected_city: {},
      categories:[{id:1,name:'PCR',active:false},{id:1,name:'Swab Antigen',active:false}],
      selected_categories:[],
      show: false,
      cities: [
          { name: 'Surabaya'},
          { name: 'Madiun'},
          { name: 'Salatiga'},
          { name: 'Magelang'},
          { name: 'Yogyakarta'},
          { name: 'Bandung'},
        ],
    }),
  }
</script>
