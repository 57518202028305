<template>
  <div>
    <v-app-bar
      color="white"
      fixed
      elevation="1"
      height="59px"
    >

    <v-list-item class="mx-auto pa-0">
      <v-list-item-avatar>
        <v-icon small style="cursor:pointer" @click="goBack()">fa-arrow-left</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-subtitle class="font-weight-bold title">
          <v-row no-gutters>
            <v-col class="body-1 font-weight-bold" cols="8">
              FORM PATIENT
            </v-col>
          </v-row>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    </v-app-bar>
 
    

    <v-row style="background:#f1f1f1" class="mx-auto mt-14 flex-wrap">
      <v-col cols="12">
        <v-card elevation="3">
          <v-card-text  class="pa-3 pb-0">
          <p class="body-2  mb-4">Panel SARS CoV-2 Antigen & Antibodi Test</p>
          <v-divider class="mb-3"/>
          <p class="body-2 mb-0">Pramita Aditya </p>
          <p class="body-2 font-weight-black">Senin, 02-01-2021 07:00</p>
          </v-card-text>
          <v-card-actions class="pt-0 no-gutters">
             

              <v-btn small dark>
                details
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-auto no-gutters flex-wrap">
      <v-col class="pa-3" cols="12">
        <v-row class="no-gutters">
              <v-col cols="8" class="font-weight-bold">DATA PASIEN</v-col>
              
            </v-row>
      </v-col>
      <v-col class="pl-3 pr-3" cols="12">
        <v-card>
          <v-card-text  class="pa-3">
          <v-row align="center">
            <v-col cols="8">
            <p class="body-2 mb-0">Belum ada data pasien</p>
            </v-col>
            <v-col class="text-right"> <v-btn
              color="red darken-1"
              fab
              small
              dark
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn></v-col>
          </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <DialogLoading/>
  </div>
</template>
<style>
html,body{
  background:#fafafa
}
.box-center{
  position: fixed;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}
@media only screen and (min-device-width : 1024px){
/* Styles */
/* width */
::-webkit-scrollbar {
   height: 8px;              /* height of horizontal scrollbar ← You're missing this */
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
}


</style>
<script>
import DialogLoading from '@/components/DialogLoading';
  export default {
    name: 'xinputpatient',
    components: {
      DialogLoading
    },
    mounted(){
      //console.log(this.$route)
      this.selected_branch = this.$route.params
      this.getTests()
    },
    computed:{
      selected_branch:{
         get() {
             return this.$store.state.choosebranch.selected_branch
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_selected_branch",val)
         }
      },
      tests:{
         get() {
             return this.$store.state.order.tests
                
         },
         set(val) {
               this.$store.commit("order/update_tests",val)
         }
      },
      selected_tests:{
         get() {
             return this.$store.state.order.selected_tests
         },
         set(val) {
               this.$store.commit("order/update_selected_tests",val)
         }
      }
    },
     methods:{
       goBack(){
         this.$router.go(-1)
       },
       goChooseBranch(){
         this.$router.push({ name: 'xchoosebranch'})
         this.$router.push({ path: '/choosebranch/header/0' }) 
       },
       goChooseTime(selected){
         this.$router.push({ name: 'xchoosetime'})
         this.selected_tests = []
         this.selected_tests.push(selected)
         let seltest = this.selected_tests[0].test_id
         let branch = this.selected_branch.id
         this.$router.push({ path: `/choosetime/test/${seltest}/branch/${branch}` }) 
         //let prm = {branch_id:this.selected_branch.id, test_id:seltest}
         //this.$store.commit("beranda/update_dialog_loading",true)
         //this.$store.dispatch("order/getTests",prm)
       },
       getTests() {
          let prm = this.selected_branch
          this.$store.commit("beranda/update_dialog_loading",true)
          this.$store.dispatch("order/getTests",prm)
        },
    },
    data: () => ({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    }),
  }
</script>
