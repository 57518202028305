import Vue from 'vue'
import VueRouter from 'vue-router'
import xlogin from '@/components/Login'
import xwelcome from '@/components/Welcome'
import xberanda from '@/components/Beranda'
import dashboard from '@/components/Dashboard'
import xdetails from '@/components/Details'
import xselbranch from '@/components/SelectedBranch'
import xlistingpacket from '@/components/ListingPacket'
import xlistingfavorite from '@/components/ListingFavorit'
import xdetailproduct from '@/components/DetailProduct'
import xchoosetest from '@/components/ChooseTest'
import xrincianpemeriksaan from '@/components/RincianPemeriksaan'
import xchoosebranch from '@/components/Choosebranch'
import xchoosetime from '@/components/ChooseTime'
import xformpatient from '@/components/FormPasien'
import xinputpatient from '@/components/InputPatient'
import xcheckout from '@/components/Checkout'
import xorder from '@/components/Order'
import xlistorder from '@/components/Listorder'
import xdetailorder from '@/components/Detailorder'
import xstatusresult from '@/components/StatusResult'
import xdetailstatusresult from '@/components/DetailStatusResult'
import xtimelinestatusresult from '@/components/TimelineStatusResult'
import xchooisepayment from '@/components/ChooisePayment'
import xpaymentva from '@/components/PaymentVA'
import xmenu from '@/components/Menu'
import xlistpatient from '@/components/ListPatient'
import xlistingtestv2 from '@/components/ListingTestv2'
import xlistingtestfilter from '@/components/ListingTestFilter'
import xsettingschedule from '@/components/SettingSchedule'
import xsettingschedulehs from '@/components/SettingScheduleHS'
import xcart from '@/components/DialogCart'
import xcarths from '@/components/DialogCartHS'
import xpayment from '@/components/Payment'
import xsetplacehs from '@/components/SetPlaceHS'
import xformaddresshs from '@/components/FormAddressHS'
import xselectbranchhs from '@/components/SelectBranchHS'
import xsearchtestall from '@/components/SearchTest'
import xsearchtesths from '@/components/SearchTestHS'
import xrequirements from '@/components/Requirements'
import xlistingallpacket from '@/components/ListingPacketAll'
import xdetailordernotlunas from '@/components/DetailorderNotLunas'
import xlistaddresshs from '@/components/ListAddressHS'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'xwelcome',
    component: xwelcome
  },
  {
    path: '/filter',
    name: 'xlistingtestfilter',
    component: xlistingtestfilter
  },
  {
    path: '/selectbranchhs',
    name: 'xselectbranchhs',
    component: xselectbranchhs,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/searchtesths',
    name: 'xsearchtesths',
    component: xsearchtesths,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/formaddresshs',
    name: 'xformaddresshs',
    component: xformaddresshs,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/setplacehs',
    name: 'xsetplacehs',
    component: xsetplacehs,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/home',
    name: 'xberanda',
    component: xberanda
  },
  {
    path: '/login',
    name: 'xlogin',
    component: xlogin
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: dashboard
  },
  {
    path: '/details',
    name: 'xdetails',
    component: xdetails
  },
  {
    path: '/choosetest',
    name: 'xchoosetest',
    component: xchoosetest
  },
  {
    path: '/branch',
    name: 'xselbranch',
    component: xselbranch
  },
  {
    path: '/listingpacket',
    name: 'xlistingpacket',
    component: xlistingpacket
  },
  {
    path: '/searchtestall',
    name: 'xsearchtestall',
    component: xsearchtestall
  },
  {
    path: '/detailproduct',
    name: 'xdetailproduct',
    component: xdetailproduct
  },
  {
    path: '/rincianpemeriksaan',
    name: 'xrincianpemeriksaan',
    component: xrincianpemeriksaan
  },
  {
    path: '/choosebranch/header/:header',
    name: 'xchoosebranch',
    component: xchoosebranch
  },
  {
    path: '/choosetime',
    name: 'xchoosetime',
    component: xchoosetime
  },
  {
    path: '/formpatient',
    name: 'xformpatient',
    component: xformpatient,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/listingfavorite',
    name: 'xlistingfavorite',
    component: xlistingfavorite,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/inputpatient/id/:id',
    name: 'xinputpatient',
    component: xinputpatient,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/checkout/id/:id',
    name: 'xcheckout',
    component: xcheckout,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/payment/id/:id',
    name: 'xpayment',
    component: xpayment,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/order/id/:id',
    name: 'xorder',
    component: xorder
  },
  {
    path: '/settingschedule',
    name: 'xsettingschedule',
    component: xsettingschedule,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settingschedulehs',
    name: 'xsettingschedulehs',
    component: xsettingschedulehs,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/listpatient',
    name: 'xlistpatient',
    component: xlistpatient,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/listaddresshs',
    name: 'xlistaddresshs',
    component: xlistaddresshs,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/listingtest',
    name: 'xlistingtestv2',
    component: xlistingtestv2
  },
  {
    path: '/cart',
    name: 'xcart',
    component: xcart
  },
  {
    path: '/carths',
    name: 'xcarths',
    component: xcarths
  },
  {
    path: '/listorder',
    name: 'xlistorder',
    component: xlistorder,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/detailorder/id/:id',
    name: 'xdetailorder',
    component: xdetailorder,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/detailordernotlunas/id/:id',
    name: 'xdetailordernotlunas',
    component: xdetailordernotlunas,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/statusresult',
    name: 'xstatusresult',
    component: xstatusresult,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/detailstatusresult/id/:id',
    name: 'xdetailstatusresult',
    component: xdetailstatusresult,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/timelinestatusresult/id/:id',
    name: 'xtimelinestatusresult',
    component: xtimelinestatusresult,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/chooisepayment/id/:id',
    name: 'xchooisepayment',
    component: xchooisepayment,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/paymentva/id/:id/code/:code',
    name: 'xpaymentva',
    component: xpaymentva,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/menu',
    name: 'xmenu',
    component: xmenu,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/requirements',
    name: 'xrequirements',
    component: xrequirements,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/listingallpacket',
    name: 'xlistingallpacket',
    component: xlistingallpacket
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    var user = JSON.parse(localStorage.getItem("user"))
    if (user && user.token !== '') {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})



export default router
