// 1 => LOADING
// 2 => DONE
// 3 => ERROR

import * as api from "@/api/api.js"
export default {
    namespaced: true,
    state: {
        lng:0,
        lat:0,
        window_width:0,
        window_height:0,
        api_error_message:'',
        api_warning_message:'',
        api_status:'',
        dialog_loading:false,
        testing:'',
        user:'',
        start_date:'',
        end_date:'',
        info_message:false,
        dialog_cart:false,
        order_hs:false,
        before_menu:'',
        last_menu:'',
        cari_menu:'',
        heightx:window.innerHeight,
        data_ref:[]
    },
    mutations: {
        update_cari_menu(state, val) {
            state.cari_menu = val
        },
        update_before_menu(state, val) {
            state.before_menu = val
        },
        update_data_ref(state, val) {
            state.data_ref = val
        },
        update_heightx(state, val) {
            state.heightx = val
        },
        update_api_warning_message(state, val) {
            state.api_warning_message = val
        },
        update_last_menu(state, val) {
            state.last_menu = val
        },
        update_order_hs(state, val) {
            state.order_hs = val
        },
        update_dialog_cart(state, val) {
            state.dialog_cart = val
        },
        update_info_message(state, val) {
            state.info_message = val
        },
        update_window_height(state, val) {
            state.window_height = val
        },
        update_start_date(state, val) {
            state.start_date = val
        },
        update_end_date(state, val) {
            state.end_date = val
        },
        update_user(state, val) {
            state.user = val
        },
        update_testing(state, val) {
            state.testing = val
        },
        update_dialog_loading(state, val) {
            state.dialog_loading = val
        },
        update_api_error_message(state, val) {
            state.api_error_message = val
        },
        update_api_status(state, val) {
            state.api_status = val
        },
        update_lng(state, val) {
            state.lng = val
        },
        update_lat(state, val) {
            state.lat = val
        },
        update_window_width(state, val) {
            state.window_width = val
        }
    },
    actions: {

    }
}