<template>
    <div style="overflow-x:hidden;overflow-y:hidden">
     
    <v-app-bar
      color="white"
      fixed
      flat
      elevation="1"
      height="59px"
    >

      <v-list-item class="mx-auto pa-0">
        <v-list-item-avatar>
          <v-icon style="cursor:pointer" @click="goBack()">mdi-arrow-left</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-subtitle class="font-weight-bold title">
            <v-row no-gutters>
              <v-col class="font-weight-bold" cols="8">
                Form alamat rumah
              </v-col>
            </v-row>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-app-bar>
       
 
        <v-card elevation="0" flat style="max-width:450px" class="mt-16 pl-3 pr-3 mx-auto">
            <v-row>
              <v-col cols="12">

              </v-col>
            </v-row>
            <v-row>
                <v-col cols="9">
                    <GmapAutocomplete
                        id="placeinput"
                        :options="{
                          language:'id'
                        }" 
                        @place_changed='setPlace'
                        placeholder="Tulis jalan / kelurahan / perumahan / gedung"
                        class="modgautocomplete"
                    />
                </v-col>
                 <v-col class="text-right" @click="removePlace()" cols="3">
                  <v-btn depressed>
                    <v-icon dark>
                      fa-times
                    </v-icon>
                  </v-btn>
                </v-col>
            </v-row>
            <v-row class="mb-0 mt-0">
              <v-col class="pa-0 pl-3" cols="12">
                <v-btn @click="geolocate()" color="grey lighten-2" small depressed>
                    Gunakan lokasi saat ini
                  </v-btn>
              </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                  <v-card>
                    <v-card-text>
                      <GmapMap
                        :center='center'
                        :zoom='18'
                        style='width:100%;  height: 400px;'
                        >
                        <GmapMarker
                            :key="index"
                            v-for="(m, index) in markers"
                            :position="m.position"
                            :clickable="true"
                            :draggable="true"
                            @dragend="updateCoordinates" 
                            @click="center=m.position"
                            :icon="{ url: require('../assets/icon_map_home.png')}" 
                        />
                      </GmapMap>
                    </v-card-text>
                  </v-card>
                </v-col>
               
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  hide-details="auto"
                  label="Label Alamat *"
                  v-model="label"
                  placeholder="misal : Alamat Rumah / Alamat Kantor "
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  readonly
                  hide-details="auto"
                  label="Alamat Rumah *"
                  v-model="address"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  hide-details="auto"
                  label="Detail Lainnya (Misal : Blok / Unit No., Patokan)"
                  v-model="detail"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-switch
                  v-model="new_address.is_utama"
                  :label="`Jadikan alamat utama`"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn color="error" @click="saveNewAddress()" block>
                  Simpan 
                </v-btn>
              </v-col>
            </v-row>
        </v-card>


    </div>
</template>
<style>
.modgautocomplete{
    padding: 4px 4px 4px 8px;
    border: 1px solid #bdbdbd;
    border-radius: 2px;
    width: 100%;
    min-height: 42px;
}
.box-center {
        position: fixed;
        left: 50%;
        bottom: -50px;
        transform: translate(-50%, -50%);
        margin: 0 auto;
    }
    .fixed-bottom-bg{
        position: fixed;
        left: 50%;
        bottom: -52px;
        transform: translate(-50%, -50%);
        margin: 0 auto;
        box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12) !important;
    }

@media only screen and (min-device-width : 1024px){
/* Styles */
/* width */
::-webkit-scrollbar {
  height: 8px;              /* height of horizontal scrollbar ← You're missing this */
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
}

</style>
<script>
import axios from 'axios';
import * as VueGoogleMaps from 'vue2-google-maps';

  export default {
    name: 'xformaddresshs',
    mounted(){
      this.geolocate();
      var localstorage = JSON.parse(localStorage.getItem("oneState"))
      this.selected_branch = localstorage.choosebranch.selected_branch
      this.$store.dispatch("choosebranch/getSavedAddress",{})

      if(this.$vuetify.breakpoint.name == 'xs'){
        this.window_width = window.innerWidth
      }
      else
        this.window_width = '560px'

    },
    computed:{
      google: VueGoogleMaps.gmapApi,
      not_found_near_branch:{
         get() {
             return this.$store.state.choosebranch.not_found_near_branch
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_not_found_near_branch",val)
         }
      },
      loading_search_near_branch:{
         get() {
             return this.$store.state.choosebranch.loading_search_near_branch
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_loading_search_near_branch",val)
         }
      },
      selected_hs_address:{
         get() {
             return this.$store.state.choosebranch.selected_hs_address
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_selected_hs_address",val)
         }
      },
      hs_address:{
         get() {
             return this.$store.state.choosebranch.hs_address
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_hs_address",val)
         }
      },
      dialog_new_address:{
         get() {
             return this.$store.state.choosebranch.dialog_new_address
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_dialog_new_address",val)
         }
      },
       new_address:{
         get() {
             return this.$store.state.choosebranch.new_address
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_new_address",val)
         }
      },
        places:{
         get() {
             return this.$store.state.choosebranch.places
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_places",val)
         }
      },
        markers:{
         get() {
             return this.$store.state.choosebranch.markers
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_markers",val)
         }
      },
        center:{
         get() {
             return this.$store.state.choosebranch.location
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_location",val)
         }
      },
        currentPlace:{
         get() {
             return this.$store.state.choosebranch.current_place
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_current_place",val)
         }
      },
        subcategories:{
         get() {
             return this.$store.state.choosebranch.subcategories
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_subcategories",val)
         }
      },
      selected_branch:{
         get() {
             return this.$store.state.choosebranch.selected_branch
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_selected_branch",val)
         }
      },
      window_width:{
        get() {
            return this.$store.state.beranda.window_width
                
        },
        set(val) {
              this.$store.commit("beranda/update_window_width",val)
        }
      },
    },
    methods:{
       gotoSearchTest(){
                this
                    .$store
                    .commit("choosebranch/update_selected_subcategory", [])
                    var xstate = {}
                    xstate.beranda = this.$store.state.beranda
                     
                xstate.order = this.$store.state.order
                xstate.choosebranch = this.$store.state.choosebranch
                localStorage.setItem('oneState', JSON.stringify(xstate));
                this.$router.push({name:'xlistingtest'})
            },
      selectAddress(address){
         let hs_address = this.hs_address
         let idx = _.findIndex(hs_address, function(o) { return o.label == address.label && o.address == address.address})
          hs_address.forEach((entry,k) => {
            if(k !== idx)
            hs_address[k].is_selected = false
          })


            hs_address[idx].is_selected = address.is_selected ? false:true
            if(hs_address[idx].is_selected){
              this.selected_hs_address = hs_address[idx]
              this.loading_search_near_branch = true
              this.$store.dispatch("choosebranch/getNearBranch", this.selected_hs_address)
            }
            else{
              this.this.selected_hs_address = {}
              this.selected_branch = {}
              this.loading_search_near_branch = false
            }
            
         
       },
      isCheckedHSAddress(address){
        var hs_address = this.hs_address
      },
      saveNewAddress(){
        //console.log("ini mau nyimpan")
        console.log(this.new_address)
        let params = this.new_address
        params.label = this.label
        params.address = this.address
        params.details = this.detail
        if(this.label !== '' && this.address !== '')
        this.$store.dispatch("choosebranch/saveNewAddressJS",params)
        else{
          var msg = ''
          if(this.label == '')
          msg += "<p>Label belum anda isi</p>"

          if(this.address == '')
          msg += "<p>Alamat belum anda tentukan</p>"
          this.$store.commit('beranda/update_api_error_message',msg)
        }
        
      },
      openNewAddress(){
        this.currentPlace = ''
        this.new_address = {
            label : '',
            address:'',
            details:'',
            lat:'',
            lng:'',
            is_selected:false,
            is_utama:false
        }
        this.dialog_new_address = true
      },
      setPlace(place) {
        document.getElementById('placeinput').value = "";
        var newaddrs = this.new_address
        this.address = place.formatted_address
        newaddrs.lat = place.geometry.location.lat()
        newaddrs.lng =  place.geometry.location.lng()
        const marker = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        };
        this.markers = []
        this.markers.push({ position: marker });
        this.places = []
        this.places.push(this.currentPlace);
        this.center = marker;

    },
    removePlace(){
      document.getElementById('placeinput').value = "";
         this.currentPlace = ''
        var newaddrs = this.new_address
        this.address = ''
        this.detail = ''
        newaddrs.lat = ''
        newaddrs.lng =  ''
        const marker = {
          lat: '',
          lng: ''
        };
        
        this.markers = []
        this.places = []
        this.center = marker;
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },
    updateCoordinates(location) {
      console.log(location)
      this.center = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
      this.lat = location.latLng.lat()
      this.lng = location.latLng.lng()
      
      this.getDistance()
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(this.successPosition, this.failurePosition, {
        enableHighAccuracy: true,
        timeout: 15000,
        maximumAge: 0,
      })
    },
    successPosition: function(position) {
        let prm = {lat: position.coords.latitude, lng: position.coords.longitude}
        this.center = prm
        this.markers.push({ position: prm });
        this.lat = position.coords.latitude
        this.lng = position.coords.longitude
        this.getDistance()
    },
    failurePosition: function(err) {
      console.log('Error Code: ' + err.code + ' Error Message: ' + err.message)
    },
    getDistance(){
      
        const marker = {
        lat: this.lat,
        lng: this.lng,
      };
        this.markers = []
        this.markers.push({ position: marker });

        var origin = {"lat":this.lat, "lng":this.lng};
        var destination = {"lat":-6.90832, "lng":107.62459};
        this.$gmapApiPromiseLazy().then(() => {
          var service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            travelMode: "DRIVING",
            avoidHighways: false,
            avoidTolls: false
          }, this.callback);
        });
        

      },
      callback(response, status) {
        if (status != google.maps.DistanceMatrixStatus.OK) {
              alert('Error was: ' + status);
        } else {
              
            //console.log(response)
            let originAddresses = response.originAddresses[0]
            this.new_address = {
                label : '',
                address:originAddresses,
                details:'',
                lat:this.lat,
                lng:this.lng,
                is_selected:false,
                is_utama:false
            }   
            this.address = originAddresses  
              
        }
      },
    goBack(){
        this.$router.push({name:'xsetplacehs'})
    },
    clickCategory(subcategory){
        this.$store.commit("choosebranch/update_selected_subcategory",subcategory)
        var xstate = {}
        xstate.beranda = this.$store.state.beranda
        xstate.order = this.$store.state.order
        xstate.choosebranch = this.$store.state.choosebranch
        localStorage.setItem('oneState', JSON.stringify(xstate));
        this.$router.push({name:'xchoosetest'})
    } 
    },
      watch : {
       my_email (v, o) {
           if (v == "") {
               this.email = value => {
                   return /\s/ || 'Hahaha'
               }
           } else {
               this.email = value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                }
           }
       },
      },
    data: () => ({
      lat:null,
      lang:null,
      label:'',
      address:'',
      detail:'',
      infiniteId:1,
      dialog_payment_methode:false,
      dialog_daterange:false,
      isLogin:false,
      dialog_city:false,
      dialog_error:false,
      msg_error:'',
      reveal:false,
      dialog_branch:false,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      selected_city: {},
      categories:[{id:1,name:'PCR',active:false},{id:1,name:'Swab Antigen',active:false}],
      selected_categories:[],
      show: false,
      cities: [
          { name: 'Surabaya'},
          { name: 'Madiun'},
          { name: 'Salatiga'},
          { name: 'Magelang'},
          { name: 'Yogyakarta'},
          { name: 'Bandung'},
        ],
    }),
  }
</script>
