<template>
  <div style="overflow:hidden">
  <v-card
    class="mx-auto"
    color="#EF5350"
    :height="window_height"
  >
    <v-app-bar
      color="#EF5350"
      dark
      tile
      fixed
      elevation="1"
      height="59px"
    >
        <v-list-item class="mx-auto pa-0">
        <v-list-item-avatar>
            <v-icon style="cursor:pointer" @click="goBack()">mdi-arrow-left</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-subtitle class="font-weight-bold title">
            <v-row no-gutters>
                <v-col cols="8">
                Kembali
                </v-col>
            </v-row>
            </v-list-item-subtitle>
        </v-list-item-content>
        </v-list-item>
    </v-app-bar>
    <v-card   class="mt-14 ml-2 mr-2" elevation="10">
                            
        <v-list-item  three-line>
            <v-list-item-content>
                <p class="caption mb-1">Kode Booking</p>
                <kbd class="mb-2">
                    {{order.qrcode}}
                </kbd>
                <v-list-item-title class="title mb-1">
                {{order.patient_name}}
                </v-list-item-title>
                <v-list-item-subtitle>{{order.id_number}}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
                tile
                size="80"
                color="grey"
            ><QRCanvas :options="{cellSize: 4,correctLevel: 'H',data: order.qrcode}"/></v-list-item-avatar>
            </v-list-item>
        
    </v-card>
    <v-card-text :style="{'max-height':window_height - 140+'px' }" style="overflow-y:auto" class="pl-0 ml-0 pb-10 py-0">

      <v-timeline
        align-top
        dense
        class="pl-0 pr-0"
      >
        <v-timeline-item
          class="pl-0 pr-0"
          color="warning"
          small
          v-for="(timeline,i) in order.timelines"
          :key="i"
        >
          <v-row class="mb-2">
            <v-col class="pa-0" cols="12">
              <kbd>{{timeline.tx_date.replace(";", ":")}}</kbd>
            </v-col>
          </v-row>
          <v-row class="pr-1">
            <v-col class="pa-0" cols="12">
              <v-card>
                    <v-card-title class="pl-0 pt-1 pb-1">
                        <v-chip
                          class="ma-2"
                          color="warning lighten-1"
                          rounded
                          label
                          text-color="black"
                        >
                          
                          {{timeline.tx_code}}
                        </v-chip>
                        
                        <v-chip
                          v-if="timeline.tx_code == 'RESULT'"
                          class="ma-2"
                          color="green lighten-1"
                          rounded
                          label
                          style="cursor:pinter"
                          @click="InsertPassword()"
                          text-color="white"
                        >
                          <v-icon small left>
                            fa-file-medical-alt
                          </v-icon>
                          LIHAT HASIL
                        </v-chip>
                        
                    </v-card-title>
                    <v-divider/>
                    <v-card-text>
                        
                        <p class="body-2 mb-1" v-html="timeline.tx_note">
                            
                        </p>
                        <p class="pl-0 ml-0 mb-0" v-if="timeline.tx_code == 'RESULT' && data_report.length > 0" v-for="(report,idx) in data_report" :key="idx">
                          <v-chip
                          class="ma-2 ml-0"
                          color="red lighten-1"
                          rounded
                          label
                          style="cursor:pinter"
                          @click="openPDF(report)"
                          text-color="white"
                        >
                          <v-icon small left>
                            fa-file-pdf
                          </v-icon>
                          DOWNLOAD HASIL {{report.group}}
                        </v-chip>
                        </p>
                    </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-timeline-item>

      </v-timeline>
    </v-card-text>
     <v-bottom-sheet
            v-model="open_rpt"
            inset
            max-width="350px"
            height="200px"
            >
        <v-sheet
            class="text-center rounded"
            
        >
            
            <div class="ma-4">
                <v-card  flat>
                    <v-card-text class="text-center">
                        <v-text-field
                            color="grey"
                            v-model="password"
                            :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show_password ? 'text' : 'password'"
                            name="input-10-1"
                            label="Password"
                            @click:append="show_password = !show_password"
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-row>
                            
                             <v-col cols="6">
                                <v-btn
                                block
                            outlined
                            color="grey"
                
                            @click="open_rpt = !open_rpt"
                            >
                            Batal
                        </v-btn>
                            </v-col>
                            <v-col cols="6">
                                <v-btn
                            block
                            color="red lighten-1"
                            dark
                            @click="openReport"
                            >
                            Lanjutkan
                        </v-btn>
                            </v-col>
                        </v-row>
                        
              
                    </v-card-actions>
                </v-card>
            </div>
        </v-sheet>
        </v-bottom-sheet>
    <v-dialog
      v-model="dialog_rpt"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-toolbar
          dark
          color="green ligthen-1"
        >
          <v-btn
            icon
            dark
            @click="dialog_rpt = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>HASIL PEMERIKSAAN</v-toolbar-title>
          <v-spacer></v-spacer>
          
        </v-toolbar>
      <v-card tile>
        <v-row>
          <v-col class="text-center pa-2" cols="12">
              <v-btn
                small
                color="green ligthen-1"
                v-for="(rpt,i) in data_report" :key="i"
                :dark="selected_report.group === rpt.group"
                :text="selected_report.group === rpt.group"
                @click="loadRpt(rpt)"
              >
                {{rpt.group}}
              </v-btn>
          </v-col>
        </v-row>
        <v-divider/>
        <v-row>
          <v-col cols="12">
              

          </v-col>
        </v-row>
        
      </v-card>
    </v-dialog>
  </v-card>
  </div>
</template>
<style>


.box-center{
  position: fixed;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}
@media only screen and (min-device-width : 1024px){
/* Styles */
/* width */
::-webkit-scrollbar {
  height: 8px;              /* height of horizontal scrollbar ← You're missing this */
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
}

</style>
<script>
import { QRCanvas } from 'qrcanvas-vue';
import pdf from 'pdfvuer';
  export default {
    name: 'xtimelinestatusresult',
    components: {
      QRCanvas,
      pdf
    },
    mounted(){
      this.$store.commit("beranda/update_dialog_loading",true)
      this.$store.dispatch("order/getDataOrderTimeline",this.$route.params)

      this.window_height = window.innerHeight 

      if(this.$vuetify.breakpoint.name == 'xs'){
        this.window_width = window.innerWidth
      }
      else
        this.window_width = '560px'

    },
    computed:{
      selected_report:{
        get() {
            return this.$store.state.order.selected_report
                
        },
        set(val) {
              this.$store.commit("order/update_selected_report",val)
        }
      },
      data_report:{
        get() {
            return this.$store.state.order.data_report
                
        },
        set(val) {
              this.$store.commit("order/update_data_report",val)
        }
      },
      dialog_rpt:{
        get() {
            return this.$store.state.order.dialog_rpt
                
        },
        set(val) {
              this.$store.commit("order/update_dialog_rpt",val)
        }
      },
      list_header:{
        get() {
            return this.$store.state.order.list_header
                
        },
        set(val) {
              this.$store.commit("order/update_list_header",val)
        }
      },
      list_payment:{
        get() {
            return this.$store.state.order.list_payment
                
        },
        set(val) {
              this.$store.commit("order/update_list_payment",val)
        }
      },
      user:{
        get() {
            return this.$store.state.beranda.user
                
        },
        set(val) {
              this.$store.commit("beranda/update_user",val)
        }
      },
      opt_qrcode:{
        get() {
            return this.$store.state.order.opt_qrcode
                
        },
        set(val) {
              this.$store.commit("order/update_opt_qrcode",val)
        }
      },
      order:{
        get() {
            return this.$store.state.order.data_timeline
                
        },
        set(val) {
              this.$store.commit("order/update_data_timeline",val)
        }
      },
      lat:{
        get() {
            return this.$store.state.beranda.lat
                
        },
        set(val) {
              this.$store.commit("beranda/update_lat",val)
        }
      },
      lng:{
        get() {
            return this.$store.state.beranda.lng
                
        },
        set(val) {
              this.$store.commit("beranda/update_lng",val)
        }
      },
      window_width:{
        get() {
            return this.$store.state.beranda.window_width
                
        },
        set(val) {
              this.$store.commit("beranda/update_window_width",val)
        },
        
      },
      window_height:{
        get() {
            return this.$store.state.beranda.window_height
                
        },
        set(val) {
              this.$store.commit("beranda/update_window_height",val)
        }
      },
      date_myformat(){
        if(!_.isEmpty(this.$store.state.order.data_order)){
          var d = new Date(this.$store.state.order.data_order.order_date);
        var weekday = new Array(7);
        weekday[0] = "Minggu";
        weekday[1] = "Senin";
        weekday[2] = "Selasa";
        weekday[3] = "Rabu";
        weekday[4] = "Kamis";
        weekday[5] = "Jumat";
        weekday[6] = "Sabtu";

        var n = weekday[d.getDay()];
        //console.log(this.localstorage.order.selected_date)
        return n+', '+this.$moment(d).format('DD-MM-YYYY') 
        }
        else
        return 'dd-mm-yyyy'
        
      }
    },
    methods:{
      openPDF(rpt){
        //console.log(rpt)
        this.data_report = []
        window.open('/one_api_coba/test/test_quota/get_pdf/'+rpt.rpt+'.pdf', '_blank')
        
        
      },
      InsertPassword(){
        this.password = ''
        this.open_rpt = true
      },
      loadRpt(rpt){
        this.selected_report = rpt
      },
      openReport(){
        this.$store.commit("beranda/update_dialog_loading",true)
        this.open_rpt = false
        var params= this.order
        params.password = this.password
        this.$store.dispatch("order/getReport",params)
      },
      goBayar(){
        if(this.list_header.payment_status === 'N')
          this.$router.push({ path: `/order/id/${this.list_header.id}`})
        else
          this.$router.push({ path: `/payment/id/${this.list_header.order_number}/code/${this.list_payment.payment_code}`})
      },
      openDetail(order,i){
            var xdata = this.data_order
            xdata[i]['xshow'] = order.xshow == 'N' ? 'Y':'N'
           // console.log( xdata[i]['show'])
            //this.data_order = xdata
            this.$store.commit("order/update_data_order", xdata)
          },
        goBack(){
          this.$router.push({ path: `/detailstatusresult/id/${this.order.trx_id}`})
        },
        getWIdth(){
          switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 320
            case 'sm': return 325
            case 'md': return 363
            case 'lg': return 363
          }
        }
    },
    data: () => ({
      show:true,
      password:'',
      show_password:false,
      open_rpt:false,

    }),
  }
</script>
