<template>
    <div style="overflow-x:hidden;">
      
    <v-app-bar
      color="white"
      fixed
      flat
      elevation="1"
      height="59px"
    >

      <v-list-item class="mx-auto pa-0">
        <v-list-item-avatar>
          <v-icon style="cursor:pointer" @click="goBack()">mdi-arrow-left</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-subtitle class="font-weight-bold title">
            <v-row no-gutters>
              <v-col class="font-weight-bold" cols="8">
                Cabang Pramita
              </v-col>
            </v-row>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-app-bar>
       
        <v-card flat class="mt-12">
          <v-card-text class="pa-3">
            <v-row>
              <v-col class="mt-0" cols="12">
                <p class="mb-0 pt-0 mt-0 body-2">
                  Silahkan Pilih Cabang Pramita 
                </p>
              </v-col>
            </v-row>
            <v-row  v-if="!loading_search_near_branch && brancheshs.length == 0">
              <v-col>
                <v-alert
                  outlined
                 
                  type="warning"
                  prominent
                  border="left"
                >
                  Mohon maaf, alamat anda belum bisa kami jangkau untuk pemesanan layanan Home Service
                </v-alert>
              </v-col>
            </v-row>
            <v-card v-if="!loading_search_near_branch && brancheshs.length > 0">
              <v-list two-line>
              <template v-for="branch in brancheshs">
                <div :style="{'background':branch.is_selected?'#faebd7':'#fff'}">
                <v-list-item  @click="selectBranch(branch)">
                  <v-list-item-content>
                    <v-list-item-title>
                      <p class="mb-1" :class="{'red--text':branch.is_selected}">{{branch.label}}</p>
                    </v-list-item-title>
                    <v-list-item-subtitle>{{branch.address}}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="branch.distance !== '-'">
                      <p class="mb-0 caption">Jarak : <span>{{branch.distance}} KM</span> <span v-if="branch.hs_cost">| Biaya Transport : {{formatRupiah(branch.hs_cost)}}</span></p>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon v-if="!branch.is_selected">mdi-checkbox-blank-outline</v-icon>
                    <v-icon color="red accent-4" v-if="branch.is_selected">mdi-checkbox-marked</v-icon>
                  </v-list-item-action>
                </v-list-item>
                </div>
              </template>
            </v-list>
            </v-card>
          </v-card-text>
        </v-card>
        <v-row v-if="selected_branch && !_.isEmpty(selected_branch)" class="ml-1 mr-1 mb-4">
          <v-col >
            <v-alert
      outlined
      color="info"
      class="poppins-light body-2 mb-3"
    >
      <div class="font-weight-bold subtitle">
        Informasi
      </div>
      <p clas="caption mb-1">Minimal order sebesar <span class="red--text">{{formatRupiah(selected_branch.min_order)}}</span></p>
      <p clas="caption mb-1">Untuk pemeriksaan 2 jam PP dikenakan biaya tambahan sebesar <span class="red--text">{{formatRupiah((selected_branch.second_visit_fee/100)*selected_branch.hs_cost)}}</span> sebagai biaya pengambilan sample kedua</p>
      <p clas="caption mb-1">Untuk pemeriksaan covid dikenakan biaya tambahan sebesar <span class="red--text">{{formatRupiah(selected_branch.apd_fee)}}</span> sebagai biaya APD </p>
    </v-alert>
          </v-col>
        </v-row>
        <v-row >
            <v-col
                cols="12"
                class="text-right mb-2 fixed-bottom"
            > 
                <v-btn

                    block
                    @click="gotoSelectBranch()"
                    color="red lighten-1"
                    dark
                    style="width:100%;display:inline-block"
                >
                    Lanjutkan
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>
<style>
.poppins-light{
  font-family: 'Poppins', sans-serif;
  font-weight:300;
}
.modgautocomplete{
    padding: 4px 4px 4px 8px;
    border: 1px solid #bdbdbd;
    border-radius: 2px;
    width: 100%;
    min-height: 42px;
}
.box-center {
        position: fixed;
        left: 50%;
        bottom: -50px;
        transform: translate(-50%, -50%);
        margin: 0 auto;
    }
    .fixed-bottom-bg{
        position: fixed;
        left: 50%;
        bottom: -52px;
        transform: translate(-50%, -50%);
        margin: 0 auto;
        box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12) !important;
    }

@media only screen and (min-device-width : 1024px){
/* Styles */
/* width */
::-webkit-scrollbar {
  height: 8px;              /* height of horizontal scrollbar ← You're missing this */
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
}

</style>
<script>
import axios from 'axios';
import * as VueGoogleMaps from 'vue2-google-maps'
  export default {
    name: 'xselectbranchhs',
    mounted(){
      this.geolocate();
      this.selected_branch = {}
      var localstorage = JSON.parse(localStorage.getItem("oneState"))
      var selected_hs_address = localstorage.choosebranch.selected_hs_address
      this.selected_hs_address = selected_hs_address
      this.$store.dispatch("choosebranch/getNearBranch", this.selected_hs_address)

      if(this.$vuetify.breakpoint.name == 'xs'){
        this.window_width = window.innerWidth
      }
      else
        this.window_width = '560px'

    },
    computed:{
      google: VueGoogleMaps.gmapApi,
      not_found_near_branch:{
         get() {
             return this.$store.state.choosebranch.not_found_near_branch
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_not_found_near_branch",val)
         }
      },
      loading_search_near_branch:{
         get() {
             return this.$store.state.choosebranch.loading_search_near_branch
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_loading_search_near_branch",val)
         }
      },
      selected_hs_address:{
         get() {
             return this.$store.state.choosebranch.selected_hs_address
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_selected_hs_address",val)
         }
      },
      brancheshs:{
         get() {
             return this.$store.state.choosebranch.brancheshs
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_brancheshs",val)
         }
      },
      hs_address:{
         get() {
             return this.$store.state.choosebranch.hs_address
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_hs_address",val)
         }
      },
      dialog_new_address:{
         get() {
             return this.$store.state.choosebranch.dialog_new_address
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_dialog_new_address",val)
         }
      },
       new_address:{
         get() {
             return this.$store.state.choosebranch.new_address
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_new_address",val)
         }
      },
        places:{
         get() {
             return this.$store.state.choosebranch.places
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_places",val)
         }
      },
        markers:{
         get() {
             return this.$store.state.choosebranch.markers
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_markers",val)
         }
      },
        center:{
         get() {
             return this.$store.state.choosebranch.location
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_location",val)
         }
      },
        currentPlace:{
         get() {
             return this.$store.state.choosebranch.current_place
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_current_place",val)
         }
      },
        subcategories:{
         get() {
             return this.$store.state.choosebranch.subcategories
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_subcategories",val)
         }
      },
      selected_branch:{
         get() {
             return this.$store.state.choosebranch.selected_branch
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_selected_branch",val)
         }
      },
      window_width:{
        get() {
            return this.$store.state.beranda.window_width
                
        },
        set(val) {
              this.$store.commit("beranda/update_window_width",val)
        }
      },
    },
    methods:{
      getDistanced(){
       //var origin = {"lat":this.selected_hs_address.lat, "lng":this.selected_hs_address.lng};
       //var destination = {"lat":this.selected_branch.branch_lat, "lng":this.selected_branch.branch_lng};
       //var distance = require('google-distance-matrix');
       // distance.header = {}
 
        var origins = '40.7421,-73.9914';
        var destinations = '41.8337329,-87.7321554';
        
       //distance.key('AIzaSyDHz7vf520UottjpiqGqPvvebuM4lo8G5o');
//distance.units('imperial');
        var prm = {
          'origins':origins,
          'destinations':destinations,
          'mode':'DRIVING',
          'units':'imperial',
          'key':'AIzaSyDHz7vf520UottjpiqGqPvvebuM4lo8G5o'
        }
       // distance.matrix(origins, destinations,this.callback); 
       axios.get('https://maps.googleapis.com/maps/api/distancematrix/json',  {
        params:prm,
        headers: {
          'Access-Control-Allow-Origin':'*'
        }
            }).then(res => {
              console.log(res);
            }).catch(err => {
              console.log(err.response);
            });

      },
      getDistance(branch){
        console.log(branch)
        this.now_branch = branch
        var origin = {"lat":this.selected_hs_address.lat*1, "lng":this.selected_hs_address.lng*1};
        var destination = {"lat":branch.branch_lat*1, "lng":branch.branch_lng*1};
        this.$gmapApiPromiseLazy().then(() => {
          var service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            travelMode: "DRIVING",
            avoidHighways: false,
            avoidTolls: false
          }, this.callback);
        });
        

      },
      callback(response, status) {
        if (status != google.maps.DistanceMatrixStatus.OK) {
              alert('Error was: ' + status);
        } else {
              
            console.log(response)
            var totalDistance = response.rows[0].elements[0].distance.value;
           var totalTime = response.rows[0].elements[0].duration.value;
           var distance_km = Math.round((totalDistance/1000)  * 10) / 10
           this.$store.dispatch("choosebranch/getPriceHs",{distance:distance_km,selected_branch:this.now_branch})
           
              
        }
      },
       gotoSearchTest(){
        var randomstring = Math.floor(Date.now() / 1000)
            this
              .$store
              .commit("choosebranch/update_selected_subcategory", [])
            const branch = JSON.parse(JSON.stringify(this.selected_branch))
            branch.distance = randomstring
            this.selected_branch = branch
            var xstate = {}
            xstate.beranda = this.$store.state.beranda
            
            xstate.order = this.$store.state.order
            xstate.choosebranch = this.$store.state.choosebranch
            xstate.choosebranch.selected_branch.distance = randomstring
            localStorage.setItem('oneState', JSON.stringify(xstate));
            this.$router.push({name:'xlistingtest'})
      },
      gotoSelectBranch(){
        /*this
          .$store
          .commit("choosebranch/update_selected_subcategory", [])*/
          var randomstring = Math.floor(Date.now() / 1000) * 220688
          var selbranch = this.selected_branch
          selbranch.distance = randomstring
          if(!_.isEmpty(selbranch) && ( parseInt(selbranch.hs_cost) && parseInt(selbranch.hs_cost) > 0 )){
            var xstate = {}
            this.$store.commit("test/update_xsearch", '')
            this.$store.commit("test/update_cart", [])
            this.$store.commit("test/update_selected_tests", [])
            this.$store.commit("beranda/update_order_hs",true)
            xstate.beranda = this.$store.state.beranda
            //xstate.order = this.$store.state.order
            
            xstate.choosebranch = this.$store.state.choosebranch
            xstate.choosebranch.selected_branch.distance = randomstring
            xstate.test = this.$store.state.test
            localStorage.setItem('oneState', JSON.stringify(xstate));
            this.$router.push({name:'xsearchtesths'})
          }
          else{
            if(_.isEmpty(selbranch)){
              this.$store.commit("beranda/update_api_error_message","Anda belum memilih cabang pramita")
            }else if(!parseInt(selbranch.hs_cost) || parseInt(selbranch.hs_cost) < 0){
              this.$store.commit("beranda/update_api_error_message","Sistem tidak bisa menghitung biaya transport")
            }
            
          }
          
      },
      selectAddress(address){
         let hs_address = this.hs_address
         let idx = _.findIndex(hs_address, function(o) { return o.label == address.label && o.address == address.address})
          hs_address.forEach((entry,k) => {
            if(k !== idx)
            hs_address[k].is_selected = false
          })
          hs_address[idx].is_selected = address.is_selected ? false:true
          if(hs_address[idx].is_selected){
            this.selected_hs_address = hs_address[idx]
          }
          else{
           this.selected_hs_address = {}
          }
       },
       selectBranch(branch){
         //console.log(branch)
         let brancheshs = this.brancheshs
         let idx = _.findIndex(brancheshs, function(o) { return o.id == branch.id})
         //console.log(idx)
         brancheshs.forEach((entry,k) => {
            if(k !== idx){
              brancheshs[k].is_selected = false
              brancheshs[k].distance = '-'
              brancheshs[k].hs_cost = '-'
            }
            
          })
          
          brancheshs[idx].is_selected = branch.is_selected ? false:true
          if(brancheshs[idx].is_selected){
            this.selected_branch = brancheshs[idx]
            this.getDistance(branch)
          }
          else{
           this.selected_branch = {}
            brancheshs.forEach((entry,k) => {
              
                brancheshs[k].is_selected = false
                brancheshs[k].distance = '-'
                brancheshs[k].hs_cost = '-'
              
              
            })
          }
          
            

         
       },
      isCheckedHSAddress(address){
        var hs_address = this.hs_address
      },
      saveNewAddress(){
        console.log("ini mau nyimpan")
        console.log(this.new_address)
        this.$store.dispatch("choosebranch/saveNewAddressJS",this.new_address)
      },
      openNewAddress(){
        /*this.currentPlace = ''
        this.new_address = {
            label : '',
            address:'',
            details:'',
            lat:'',
            lng:'',
            is_selected:false,
            is_utama:false
        }
        this.dialog_new_address = true*/
        this.$router.push({name:'xformaddress'})
      },
      setPlace(place) {
        var newaddrs = this.new_address
        newaddrs.address = place.formatted_address
        newaddrs.details = ''
        newaddrs.lat = place.geometry.location.lat()
        newaddrs.lng =  place.geometry.location.lng()
        const marker = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        };
        this.markers = []
        this.markers.push({ position: marker });
        this.places = []
        this.places.push(this.currentPlace);
        this.center = marker;

    },
    removePlace(){
      document.getElementById('placeinput').value = "";
         this.currentPlace = ''
        var newaddrs = this.new_address
        newaddrs.address = ''
        newaddrs.details = ''
        newaddrs.lat = ''
        newaddrs.lng =  ''
        const marker = {
          lat: '',
          lng: ''
        };
        
        this.markers = []
        this.places = []
        this.center = marker;
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },
    updateCoordinates(location) {
      this.center = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(this.successPosition, this.failurePosition, {
        enableHighAccuracy: true,
        timeout: 15000,
        maximumAge: 0,
      })
    },
    successPosition: function(position) {
        let prm = {lat: position.coords.latitude, lng: position.coords.longitude}
        this.center = prm
        this.markers.push({ position: prm });
    },
    failurePosition: function(err) {
      console.log('Error Code: ' + err.code + ' Error Message: ' + err.message)
    },
    goBack(){
        this.selected_branch = {}
        this.xsearch = ''
        var xstate = {}
        this.$store.commit("test/update_xsearch", '')
        this.$store.commit("test/update_cart", [])
        this.$store.commit("test/update_selected_tests", [])
        this.$store.commit("beranda/order_hs",true)
        xstate.beranda = this.$store.state.beranda
        //xstate.order = this.$store.state.order
        xstate.choosebranch = this.$store.state.choosebranch
        xstate.test = this.$store.state.test
        this.$router.push({name:'xsetplacehs'})
    },
    clickCategory(subcategory){
        this.$store.commit("choosebranch/update_selected_subcategory",subcategory)
        var xstate = {}
        xstate.beranda = this.$store.state.beranda
        xstate.order = this.$store.state.order
        xstate.choosebranch = this.$store.state.choosebranch
        localStorage.setItem('oneState', JSON.stringify(xstate));
        this.$router.push({name:'xchoosetest'})
    } ,
    formatRupiah(angka, prefix) {
                var number_string = angka.toString()
                        .replace(/[^,\d]/g, '')
                        .toString(),
                    sisa = number_string.length % 3,
                    rupiah = number_string.substr(0, sisa),
                    ribuan = number_string
                        .substr(sisa)
                        .match(/\d{3}/gi);

                // tambahkan titik jika yang di input sudah menjadi angka satuan ribuan
                if (ribuan) {
                    let separator = sisa
                        ? '.'
                        : '';
                    rupiah += separator + ribuan.join('.');
                }

                return 'Rp' + rupiah
            },
    },
    data: () => ({
      now_branch:{},
      infiniteId:1,
      dialog_payment_methode:false,
      dialog_daterange:false,
      isLogin:false,
      dialog_city:false,
      dialog_error:false,
      msg_error:'',
      reveal:false,
      dialog_branch:false,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      selected_city: {},
      categories:[{id:1,name:'PCR',active:false},{id:1,name:'Swab Antigen',active:false}],
      selected_categories:[],
      show: false,
      cities: [
          { name: 'Surabaya'},
          { name: 'Madiun'},
          { name: 'Salatiga'},
          { name: 'Magelang'},
          { name: 'Yogyakarta'},
          { name: 'Bandung'},
        ],
    }),
  }
</script>
