<template>
  <div>
    <v-app-bar
      color="#EF5350"
      dark
      fixed
      elevation="1"
      height="59px"
      class="pl-0 ml-0"
    >

    <v-list-item class="mx-auto pa-0">
      <v-list-item-avatar class="pl-0 ml-0">
        <v-icon style="cursor:pointer;" @click="goBack()">mdi-arrow-left</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-subtitle class="font-weight-bold title">
          <v-row no-gutters>
            <v-col cols="12" class="ma-0 pa-0">
                <v-list-item class="text-right ml-0 pl-0 mr-0 pr-1">
                    <v-list-item-content>
                        <v-list-item-subtitle class="caption" v-if="selected_test_varian.length > 0">Harga</v-list-item-subtitle>
                        <v-list-item-title class="font-weight-bold title" v-if="selected_test_varian.length > 0">{{'Rp'+formatRupiah(selected_result_option.price)}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-col>
          </v-row>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    </v-app-bar>
 
    

    <v-row class="mx-auto mt-15  flex-wrap " style="padding-bottom:100px">
      <v-col
        cols="12"
        class="mb-0 pb-0"
      > 
        <v-expand-x-transition>
          <v-card>
            <p style="color:#888" class="pl-4 pt-2 mb-0 caption">PEMERIKSAAN</p>
              <p v-if="selected_test_varian.length > 0" style="font-family: 'Fraunces', serif;font-size:18px;line-height:1.5rem"  class="pl-4 font-weight-bold mb-0 pb-2 pr-4">{{selected_test_varian[0].test_name}}</p>
          </v-card>
        </v-expand-x-transition>
      </v-col>
       <v-col
        cols="12"
        class="mb-0 pb-0"
      > 
        <v-expand-x-transition>
          <v-card>
              <p class="body-2 pl-4 font-weight-bold mb-2 pt-2">PILIH JUMLAH PASIEN</p>
            
            <v-divider></v-divider>
            <v-card-text class="pt-2 pb-2">
              <v-row wrap align="center" >
                <v-col  class="pa-2" cols="12">
                  <v-select
                    color="grey"
                    outlined
                    :items="patientNumbers"
                    v-model="patient_number"
                    return-object
                    hide-details
                    item-text="name"
                    label=""
                    clearable
                    item-color="red lighten-2"
                  >
                  <template v-slot:prepend-inner>
                
                    <v-icon class="mr-3">fa-user-friends</v-icon>
                  
                </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-expand-x-transition>
      </v-col>
      <v-col
        cols="12"
        class="mb-0 pb-0"
        v-if="result_option && result_option.length > 0"
      > 
        <v-expand-x-transition>
          <v-card>
            
              <p class="body-2 pl-4 font-weight-bold mb-2 pt-2">PILIH HASIL TES</p>
            
            <v-divider></v-divider>
            <v-card-text class="pt-2 pb-2">
              <v-row wrap >
                <v-col  class="pa-2" v-for="(ro,n_ro) in result_option" :key="n_ro" cols="auto">
                  <v-btn
                    
                    :color="checkRoIsSelected(ro)"
                    dark
                    @click="checkPriceByResultOption(ro)"
                    block
                    depressed
                    class="mb-0 ma-0"
                  >
                    {{ro.name}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-expand-x-transition>
      </v-col>
      
      <v-col
        cols="12"
        class="mb-0 pb-0"
      > 
        <v-expand-x-transition>
          <v-card>
            <v-card-title class="pt-2 pb-2 font-weight-bold body-2">
              <p class="body-2 font-weight-bold mb-1">PILIH WAKTU PENGAMBILAN SPESIMEN </p>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pt-2 pb-2">

              <div class="text-h6 mb-0 text-center"><kbd class="red"><span>{{selected_date}}</span> <span v-if="!_.isEmpty(selected_time)">{{selected_time.name}}</span> <span v-if="_.isEmpty(selected_time)">HH:MM</span></kbd></div>

              <v-row align="center">
                <v-col>
                  <vc-calendar style="display:block" @dayclick="onDayClick" color="red" :attributes='attributes' :min-date="new Date()" :max-date="new Date(Date.now() + 13 * 24 * 60 * 60 * 1000)" is-expanded />
                </v-col>
              </v-row>

              
              <v-row>
                <v-col>
                  <v-card outline flat rounded color="red-lighten-2">
                  <v-list >
                    <v-list-item-group
                      v-model="selected_time"
                    >
                      <template v-for="(item, i) in times">
                        <v-list-item
                          :key="`item-${i}`"
                          :value="item"
                          class="ml-0 pl-0 pt-0 pb-0"
                          active-class="amber--text"
                          :disabled="parseInt(item.kuota) < parseInt(patient_number.id)"
                        >
                          <template v-slot:default="{ active }">
                            <v-list-item-content class="ma-0 pa-0">
                              <v-list-item-title>
                                  <v-list-item two-line>
                                    <v-list-item-content class="ma-0 pa-0">
                                      <v-list-item-title>
                                        <span v-if="parseInt(item.kuota) > 0">{{item.name}}</span>
                                        <span class="text-decoration-line-through" v-if="parseInt(item.kuota) == 0">{{item.name}}</span>
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        <span v-if="parseInt(item.kuota) > 0">{{item.kuota}} Kuota tersisa</span>
                                        <span v-if="parseInt(item.kuota) == 0">Penuh</span>
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                              </v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action class="pr-0">
                              <v-checkbox
                                :input-value="active"
                                :disabled="parseInt(item.kuota) < parseInt(patient_number.id)"
                                color="red accent-4"
                              ></v-checkbox>
                            </v-list-item-action>
                          </template>
                        </v-list-item>
                        <v-divider/>
                      </template>
                    </v-list-item-group>
                  </v-list>
                  </v-card>
                </v-col>
              </v-row>
              
            </v-card-text>
          </v-card>
        </v-expand-x-transition>
      </v-col>
     
    </v-row>
     <v-row v-if="!_.isEmpty(selected_time)" class="fixed-bottom"  justify="center" >
      <v-col
        cols="12"
        class="text-center mb-2"
      > 
      <v-card transition="scroll-y-reverse-transition">
                   <v-btn
                    block
                    @click="goToFormPasien()"
                    color="red lighten-1"
                    dark
                    class="pa-5"
                  >
                    Lanjutkan <v-icon class="pl-3">fa-long-arrow-alt-right</v-icon>
                  </v-btn>
      </v-card>
    </v-col>
  </v-row>
  <DialogLoading/>
  </div>
</template>
<style>
.fixed-bottom{
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}
html,body{
  background:#fafafa
}
.box-center{
  position: fixed;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}
@media only screen and (min-device-width : 1024px){
/* Styles */
/* width */
::-webkit-scrollbar {
   height: 8px;              /* height of horizontal scrollbar ← You're missing this */
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
}


</style>
<script>
import moment from 'moment';
import DialogLoading from '@/components/DialogLoading';

  export default {
    name: 'xchoosetime',
    components: {
      DialogLoading
    },
    mounted(){
      this.$store.commit("beranda/update_dialog_loading",true)
      var localstorage = JSON.parse(localStorage.getItem("oneState"))
      this.$store.commit("choosebranch/update_selected_branch",localstorage.choosebranch.selected_branch)
      this.$store.commit("choosebranch/update_selected_subcategory",localstorage.choosebranch.selected_subcategory)
      this.$store.commit("order/update_selected_test_varian",localstorage.order.selected_test_varian)
      this.$store.commit("order/update_result_option",localstorage.order.result_option)
      this.$store.commit("order/update_selected_result_option",localstorage.order.selected_result_option)
      
      this.selected_date = this.$moment(new Date()).format('DD-MM-YYYY')
      this.date_sampling = this.$moment(new Date()).format('YYYY-MM-DD')
      this.$store.dispatch("order/getTestPrice",{
        branch:localstorage.choosebranch.selected_branch,
        packet:localstorage.order.selected_test_varian,
        xdate: this.date_sampling,
        option:localstorage.order.selected_result_option
      })

      if(this.$vuetify.breakpoint.name == 'xs'){
        this.window_width = window.innerWidth
      }
      else
        this.window_width = '560px'
    },
    computed:{
      dates() {
        return this.days.map(day => day.date);
      },
      attributes() {
        return this.dates.map(date => ({
          highlight: true,
          dates: date,
        }))
      },
      patientNumbers:{
         get() {
             return this.$store.state.order.patientNumbers
                
         },
         set(val) {
               this.$store.commit("order/update_patientNumbers",val)
         }
      },
      patient_number:{
         get() {
             return this.$store.state.order.patient_number
                
         },
         set(val) {
               this.$store.commit("order/update_patient_number",val)
               this.selected_time = {}
               var arr_pat = []
                for (var i = 1; i <= parseInt(val.id); i++) {
                    arr_pat.push({id:'0',name:'PASIEN '+i,id_number:'-'})
                }
               //this.$store.commit("order/update_selected_patient",arr_pat)
         }
      },
      days:{
         get() {
             return this.$store.state.order.days
                
         },
         set(val) {
               this.$store.commit("order/update_days",val)
         }
      },
      result_option:{
         get() {
             return this.$store.state.order.result_option
                
         },
         set(val) {
               this.$store.commit("order/update_result_option",val)
         }
      },
      selected_result_option:{
         get() {
             return this.$store.state.order.selected_result_option
                
         },
         set(val) {
               this.$store.commit("order/update_selected_result_option",val)
         }
      },
      selected_test_varian:{
         get() {
             return this.$store.state.order.selected_test_varian
                
         },
         set(val) {
               this.$store.commit("order/update_selected_test_varian",val)
         }
      },
      selected_branch:{
         get() {
             return this.$store.state.choosebranch.selected_branch
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_selected_branch",val)
         }
      },
      selected_date:{
         get() {
             return this.$store.state.order.selected_date
                
         },
         set(val) {
               this.$store.commit("order/update_selected_date",val)
         }
      },
      date_sampling:{
         get() {
             return this.$store.state.order.date_sampling
                
         },
         set(val) {
           console.log(val)
               this.$store.commit("order/update_date_sampling",val)
               var prm = {}
                this.$store.commit("beranda/update_dialog_loading",true)
                this.$store.dispatch("order/getTestPrice",{
                branch:this.$store.state.choosebranch.selected_branch,
                packet:this.$store.state.order.selected_test_varian,
                xdate: val,
                option:this.$store.state.order.selected_result_option
                })
         }
      },
      attrs:{
         get() {
             return this.$store.state.order.attrs
                
         },
         set(val) {
           console.log(val)
               this.$store.commit("order/update_attrs",val)
         }
      },
      times:{
         get() {
             return this.$store.state.order.times
                
         },
         set(val) {
           console.log(val)
               this.$store.commit("order/update_times",val)
         }
      },
      selected_time:{
         get() {
             return this.$store.state.order.selected_time
                
         },
         set(val) {
           console.log(val)
               this.$store.commit("order/update_selected_time",val)
         }
      }
    },
     methods:{
       checkPriceByResultOption(ro){
         this.selected_result_option = ro
           this.$store.dispatch("order/getTestPrice",{
            branch:this.$store.state.choosebranch.selected_branch,
            packet:this.$store.state.order.selected_test_varian,
            xdate: this.$store.state.order.date_sampling,
            option:this.$store.state.order.selected_result_option
            })
       },
       checkRoIsSelected(ro){
         if(parseInt(this.selected_result_option.id) == parseInt(ro.id)){
           return 'red lighten-1'
         }
         else
          return 'black'
       },
        formatRupiah(angka, prefix){
          var number_string = angka.replace(/[^,\d]/g, '').toString(),
          split           = number_string.split(','),
          sisa             = split[0].length % 3,
          rupiah             = split[0].substr(0, sisa),
          ribuan             = split[0].substr(sisa).match(/\d{3}/gi);
      
          // tambahkan titik jika yang di input sudah menjadi angka satuan ribuan
          if(ribuan){
              let separator = sisa ? '.' : '';
              rupiah += separator + ribuan.join('.');
          }
      
          rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
          return prefix == undefined ? rupiah : (rupiah ? 'Rp' + rupiah : '');
      },
      goBack(){
        //window.history.go(-1)
          //this.$store.commit("choosebranch/update_header",1)
          //var localstorage = JSON.parse(localStorage.getItem("oneState"))
          //var selected_branch = localstorage.choosebranch.selected_branch
          //this.$router.push({ path: `/pilihpemeriksaan/id/${selected_branch.id}/name/${selected_branch.name}` }) 
         this.$router.push({name:'xchoosetest'})
        },
       onDayClick(day){
          const idx = this.days.findIndex(d => d.id === day.id);
          if (idx >= 0) {
            this.days.splice(idx, 1);
          } else {
            if(day.date >= new Date(Date.now() - 1 * 24 * 60 * 60 * 1000) && day.date <= new Date(Date.now() + 13 * 24 * 60 * 60 * 1000) ){
              this.days = []
              this.days.push({
                id: day.id,
                date: day.date,
              })
              this.selected_date = moment(day.date).format('DD-MM-YYYY')
              this.date_sampling = moment(day.date).format('YYYY-MM-DD')
              //console.log(day)
            }
       
        }
       },
       goToFormPasien(){
          var xstate = {}
          xstate.beranda = this.$store.state.beranda
          xstate.order = this.$store.state.order
          xstate.choosebranch = this.$store.state.choosebranch
          localStorage.setItem('oneState', JSON.stringify(xstate));
          this.$router.push({ path: `/formpatient` })
       }
    },
    data: () => ({
     
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      window_width:window.innerWidth,
      items: [
        'Dog Photos',
        'Cat Photos',
        'Potatoes',
        'Carrots',
        'Dog Photos',
        'Cat Photos',
        'Potatoes',
        'Carrots',
        'Dog Photos',
        'Cat Photos',
        'Potatoes',
        'Carrots',
        'Dog Photos',
        'Cat Photos',
        'Potatoes',
        'Carrots',
        'Dog Photos',
        'Cat Photos',
        'Potatoes',
        'Carrots',
      ],
    }),
  }
</script>
