<template>
    <div style="overflow-x:hidden;overflow-y:hidden">
      <v-dialog
      v-model="dialog_new_address"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog_new_address = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <!--<v-toolbar-title>Settings</v-toolbar-title>-->
          <v-spacer></v-spacer>
          <!--<v-toolbar-items>
            <v-btn
              dark
              text
              @click="dialog_new_address = false"
            >
              Save
            </v-btn>
          </v-toolbar-items>-->
        </v-toolbar>
        <v-card elevation="0" flat style="max-width:450px" class="mt-16 pl-3 pr-3 mx-auto">
            <v-row>
              <v-col cols="12">

              </v-col>
            </v-row>
            <v-row>
                <v-col cols="9">
                    <GmapAutocomplete
                        id="placeinput"
                        :options="{
                          language:'id'
                        }" 
                        @place_changed='setPlace'
                        placeholder="Tulis jalan / kelurahan / perumahan / gedung"
                        class="modgautocomplete"
                    />
                </v-col>
                 <v-col class="text-right" @click="removePlace()" cols="3">
                  <v-btn depressed>
                    <v-icon dark>
                      fa-times
                    </v-icon>
                  </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                  <v-card>
                    <v-card-text>
                      <GmapMap
                        :center='center'
                        :zoom='12'
                        style='width:100%;  height: 400px;'
                        >
                        <GmapMarker
                            :key="index"
                            v-for="(m, index) in markers"
                            :position="m.position"
                            :clickable="true"
                            :draggable="true"
                            @dragend="updateCoordinates" 
                            @click="center=m.position"
                            :icon="{ url: require('../assets/icon_map_home.png')}" 
                        />
                      </GmapMap>
                    </v-card-text>
                  </v-card>
                </v-col>
               
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  hide-details="auto"
                  label="Label Alamat"
                  v-model="new_address.label"
                  placeholder="misal : Alamat Rumah / Alamat Kantor "
                  :error="new_address.label === ''"
                  error-messages="Harus diisi"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  readonly
                  hide-details="auto"
                  label="Alamat Rumah"
                  v-model="new_address.address"
                  :error="new_address.address === ''"
                  error-messages="Harus diisi"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  hide-details="auto"
                  label="Detail Lainnya (Misal : Blok / Unit No., Patokan)"
                  v-model="new_address.details"
                  
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-switch
                  v-model="new_address.is_utama"
                  :label="`Jadikan alamat utama`"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn color="error" v-if="new_address.address != '' && new_address.label != ''" @click="saveNewAddress()" block>
                  Simpan 
                </v-btn>
                <v-btn  v-if="new_address.address == '' || new_address.label == ''" block>
                  Simpan 
                </v-btn>
              </v-col>
            </v-row>
        </v-card>
      </v-card>
    </v-dialog>
        <v-app-bar
      color="white"
      fixed
      flat
      elevation="1"
      height="59px"
    >

      <v-list-item class="mx-auto pa-0">
        <v-list-item-avatar>
          <v-icon style="cursor:pointer" @click="goBack()">mdi-arrow-left</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-subtitle class="font-weight-bold title">
            <v-row no-gutters>
              <v-col class="font-weight-bold" cols="8">
                Tentukan alamat rumah
              </v-col>
            </v-row>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-app-bar>
       
        <v-card class="mt-16">
          <v-card-text class="pa-3">
            <v-row>
              <v-col class="mt-0 text-right" cols="12">
                <p class="mb-0 text-right"><v-btn @click="openNewAddress()" color="#1976d2" text>
                  tambahkan alamat baru
                </v-btn></p>
              </v-col>
            </v-row>
            <v-card v-if="hs_address.length == 0">
              <v-alert type="warning">
                Data alamat belum ada
              </v-alert>
            </v-card>
            <v-card v-if="hs_address.length > 0">
              <v-list two-line>
              <template v-for="address in hs_address">
                <div :style="{'background':address.is_selected?'#faebd7':'#fff'}">
                <v-list-item  @click="selectAddress(address)">
                  <v-list-item-content>
                    <v-list-item-title>
                      <p class="mb-1" :class="{'red--text':address.is_selected}">{{address.label}}</p>
                    </v-list-item-title>
                    <v-list-item-subtitle>{{address.address}}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon v-if="!address.is_selected">mdi-checkbox-blank-outline</v-icon>
                    <v-icon color="red accent-4" v-if="address.is_selected">mdi-checkbox-marked</v-icon>
                  </v-list-item-action>
                </v-list-item>
                </div>
              </template>
            </v-list>
            </v-card>
          </v-card-text>
        </v-card>
        <v-row >
            <v-col
                cols="12"
                class="text-right mb-2 fixed-bottom"
            > 
                <v-btn
                    block
                    @click="gotoSelectBranch()"
                    color="red lighten-1"
                    dark
                    style="width:100%;display:inline-block"
                >
                    Lanjutkan
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>
<style>
.modgautocomplete{
    padding: 4px 4px 4px 8px;
    border: 1px solid #bdbdbd;
    border-radius: 2px;
    width: 100%;
    min-height: 42px;
}
.box-center {
        position: fixed;
        left: 50%;
        bottom: -50px;
        transform: translate(-50%, -50%);
        margin: 0 auto;
    }
    .fixed-bottom-bg{
        position: fixed;
        left: 50%;
        bottom: 0px;
        transform: translate(-50%, -50%);
        margin: 0 auto;
        box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12) !important;
    }
    .fixed-bottom{
        position: fixed;
        left: 50%;
        bottom: -30px;
        transform: translate(-50%, -50%);
        margin: 0 auto;
       /* box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12) !important;*/
    }

@media only screen and (min-device-width : 1024px){
/* Styles */
/* width */
::-webkit-scrollbar {
  height: 8px;              /* height of horizontal scrollbar ← You're missing this */
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
}

</style>
<script>
import axios from 'axios';
import * as VueGoogleMaps from 'vue2-google-maps';

  export default {
    name: 'xsetplacehs',
    mounted(){
      this.geolocate();
      var localstorage = JSON.parse(localStorage.getItem("oneState"))
      this.$store.commit("beranda/update_order_hs",true)
      this.$store.dispatch("choosebranch/getSavedAddress",{})

      if(this.$vuetify.breakpoint.name == 'xs'){
        this.window_width = window.innerWidth
      }
      else
        this.window_width = '560px'

    },
    computed:{
      google: VueGoogleMaps.gmapApi,
      not_found_near_branch:{
         get() {
             return this.$store.state.choosebranch.not_found_near_branch
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_not_found_near_branch",val)
         }
      },
      loading_search_near_branch:{
         get() {
             return this.$store.state.choosebranch.loading_search_near_branch
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_loading_search_near_branch",val)
         }
      },
      selected_hs_address:{
         get() {
             return this.$store.state.choosebranch.selected_hs_address
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_selected_hs_address",val)
         }
      },
      hs_address:{
         get() {
             return this.$store.state.choosebranch.hs_address
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_hs_address",val)
         }
      },
      dialog_new_address:{
         get() {
             return this.$store.state.choosebranch.dialog_new_address
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_dialog_new_address",val)
         }
      },
       new_address:{
         get() {
             return this.$store.state.choosebranch.new_address
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_new_address",val)
         }
      },
        places:{
         get() {
             return this.$store.state.choosebranch.places
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_places",val)
         }
      },
        markers:{
         get() {
             return this.$store.state.choosebranch.markers
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_markers",val)
         }
      },
        center:{
         get() {
             return this.$store.state.choosebranch.location
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_location",val)
         }
      },
        currentPlace:{
         get() {
             return this.$store.state.choosebranch.current_place
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_current_place",val)
         }
      },
        subcategories:{
         get() {
             return this.$store.state.choosebranch.subcategories
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_subcategories",val)
         }
      },
      selected_branch:{
         get() {
             return this.$store.state.choosebranch.selected_branch
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_selected_branch",val)
         }
      },
      window_width:{
        get() {
            return this.$store.state.beranda.window_width
                
        },
        set(val) {
              this.$store.commit("beranda/update_window_width",val)
        }
      },
    },
    methods:{
       gotoSearchTest(){
                this
                    .$store
                    .commit("choosebranch/update_selected_subcategory", [])
                    var xstate = {}
                    xstate.beranda = this.$store.state.beranda
                     
                xstate.order = this.$store.state.order
                xstate.choosebranch = this.$store.state.choosebranch
                localStorage.setItem('oneState', JSON.stringify(xstate));
                this.$router.push({name:'xlistingtest'})
            },
      gotoSelectBranch(){
       
        let hs_address = this.hs_address
        var selected_address = _.filter(hs_address, function(o) { return o.is_selected })
        console.log(selected_address)
        if(selected_address.length > 0){
          this.selected_hs_address = selected_address[0]
          this
          .$store
          .commit("choosebranch/update_selected_subcategory", [])
          this.$store.commit("beranda/update_order_hs",true)
          var xstate = {}
          xstate.beranda = this.$store.state.beranda
          //xstate.order = this.$store.state.order
          xstate.choosebranch = this.$store.state.choosebranch
          localStorage.setItem('oneState', JSON.stringify(xstate));
          this.$router.push({name:'xselectbranchhs'})
        }
        else{
          this
          .$store.commit("beranda/update_api_error_message","Anda belum memilih alamat")
        }
        
      },
      selectAddress(address){
         let hs_address = this.hs_address
         let idx = _.findIndex(hs_address, function(o) { return o.label == address.label && o.address == address.address})
          hs_address.forEach((entry,k) => {
            if(k !== idx)
            hs_address[k].is_selected = false
          })
          hs_address[idx].is_selected = address.is_selected ? false:true
          if(hs_address[idx].is_selected){
            this.selected_hs_address = hs_address[idx]
          }
          else{
           this.selected_hs_address = {}
          }


            /*hs_address[idx].is_selected = address.is_selected ? false:true
            if(hs_address[idx].is_selected){
              this.selected_hs_address = hs_address[idx]
              this.loading_search_near_branch = true
              this.$store.dispatch("choosebranch/getNearBranch", this.selected_hs_address)
            }
            else{
              this.this.selected_hs_address = {}
              this.selected_branch = {}
              this.loading_search_near_branch = false
            }*/
            
         
       },
      isCheckedHSAddress(address){
        var hs_address = this.hs_address
      },
      saveNewAddress(){
        console.log("ini mau nyimpan")
        console.log(this.new_address)
        this.$store.dispatch("choosebranch/saveNewAddressJS",this.new_address)
      },
      openNewAddress(){
        /*this.currentPlace = ''
        this.new_address = {
            label : '',
            address:'',
            details:'',
            lat:'',
            lng:'',
            is_selected:false,
            is_utama:false
        }
        this.dialog_new_address = true*/
        this.$router.push({name:'xformaddresshs'})
        //this.getDistance()
      },
      setPlace(place) {
        var newaddrs = this.new_address
        newaddrs.address = place.formatted_address
        newaddrs.details = ''
        newaddrs.lat = place.geometry.location.lat()
        newaddrs.lng =  place.geometry.location.lng()
        const marker = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        };
        this.markers = []
        this.markers.push({ position: marker });
        this.places = []
        this.places.push(this.currentPlace);
        this.center = marker;

    },
    removePlace(){
      document.getElementById('placeinput').value = "";
         this.currentPlace = ''
        var newaddrs = this.new_address
        newaddrs.address = ''
        newaddrs.details = ''
        newaddrs.lat = ''
        newaddrs.lng =  ''
        const marker = {
          lat: '',
          lng: ''
        };
        
        this.markers = []
        this.places = []
        this.center = marker;
    },
    addMarker() {
      console.log('dasdas')
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },
    updateCoordinates(location) {
      console.log(location)
      this.center = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
      console.log(this.center)
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(this.successPosition, this.failurePosition, {
        enableHighAccuracy: true,
        timeout: 15000,
        maximumAge: 0,
      })
    },
    successPosition: function(position) {
        let prm = {lat: position.coords.latitude, lng: position.coords.longitude}
        this.center = prm
        this.markers.push({ position: prm });
        console.log(position)
        this.lat = position.coords.latitude
        this.lng = position.coords.longitude
        /*let new_prm = {
          'latlng':position.coords.latitude+','+position.coords.longitude,
          'key':'AIzaSyDHz7vf520UottjpiqGqPvvebuM4lo8G5o'
        }
        console.log('hello')
        axios.get("https://maps.googleapis.com/maps/api/geocode/json", {
          params: new_prm,
        }).then(({ data }) => {
          if (data) {
            console.log(data)
          } else {
            console.log('err')
          }
        });*/

        //this.new_address.address

    },
    failurePosition: function(err) {
      console.log('Error Code: ' + err.code + ' Error Message: ' + err.message)
    },
    goBack(){
      this.$store.commit("beranda/update_order_hs",false)
      this.selected_hs_address = {}
      this.selected_branch = {}
      this.xsearch = ''
      var xstate = {}
      this.$store.commit("test/update_xsearch", '')
      this.$store.commit("test/update_cart", [])
      this.$store.commit("test/update_selected_tests", [])
      xstate.beranda = this.$store.state.beranda
      //xstate.order = this.$store.state.order
      xstate.choosebranch = this.$store.state.choosebranch
      xstate.test = this.$store.state.test
      this.$router.push({name:'xberanda'})
    },
    clickCategory(subcategory){
        this.$store.commit("choosebranch/update_selected_subcategory",subcategory)
        var xstate = {}
        xstate.beranda = this.$store.state.beranda
        xstate.order = this.$store.state.order
        xstate.choosebranch = this.$store.state.choosebranch
        localStorage.setItem('oneState', JSON.stringify(xstate));
        this.$router.push({name:'xchoosetest'})
    },
    getDistance(){
        var origin = {"lat":this.lat, "lng":this.lng};
        var destination = {"lat":-6.90832, "lng":107.62459};
        this.$gmapApiPromiseLazy().then(() => {
          var service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            travelMode: "DRIVING",
            avoidHighways: false,
            avoidTolls: false
          }, this.callback);
        });
        

      },
      callback(response, status) {
        if (status != google.maps.DistanceMatrixStatus.OK) {
              alert('Error was: ' + status);
        } else {
              
            //console.log(response)
            let originAddresses = response.originAddresses[0]
            this.new_address = {
                label : '',
                address:originAddresses,
                details:'',
                lat:this.lat,
                lng:this.lng,
                is_selected:false,
                is_utama:false
            }
            console.log('bf addrs')
            console.log(this.new_address)
            console.log(originAddresses)           
              
        }
      },
    },
    data: () => ({
      lat:null,
      lng:null,
      infiniteId:1,
      dialog_payment_methode:false,
      dialog_daterange:false,
      isLogin:false,
      dialog_city:false,
      dialog_error:false,
      msg_error:'',
      reveal:false,
      dialog_branch:false,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      selected_city: {},
      categories:[{id:1,name:'PCR',active:false},{id:1,name:'Swab Antigen',active:false}],
      selected_categories:[],
      show: false,
      cities: [
          { name: 'Surabaya'},
          { name: 'Madiun'},
          { name: 'Salatiga'},
          { name: 'Magelang'},
          { name: 'Yogyakarta'},
          { name: 'Bandung'},
        ],
    }),
  }
</script>
