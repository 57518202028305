<template>
  <div style="overflow-x:hidden;overflow-y:hidden">
    
    <v-app-bar
      color="white"
      fixed
      elevation="1"
      height="59px"
    >
        <v-list-item class="mx-auto pa-0">
        <v-list-item-avatar>
            <v-icon style="cursor:pointer" @click="goBack()">fa-arrow-left</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-subtitle class="font-weight-bold title">
            <v-row no-gutters>
                <v-col cols="8">
                Kembali
                </v-col>
            </v-row>
            </v-list-item-subtitle>
        </v-list-item-content>
        </v-list-item>
    </v-app-bar>
    <v-row class="mx-auto mt-15 mb-0 pb-0">
        <v-col class="mb-0 pt-2 pb-0" cols="12">
          <v-card class="mx-auto" elevation="5">
              <v-card-text class="pt-2 pb-2">
                <v-row class="pt-0 pb-0">
                    <v-col cols="12">
                      <v-template v-for="(payment,kp) in payment_methodes" :key="kp">
                        <v-list-item v-if="payment.is_parent == 'Y'">
                            <v-list-item-content>
                                <v-list-item-title class="grey--text body-2 subheader">{{payment.name}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="payment.is_parent == 'N'">
                            <v-list-item-content>
                                <v-list-item-title class="body-1">{{payment.name}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                      </v-template>
                    </v-col>
                </v-row>
              </v-card-text>
            </v-card>
        </v-col>
    </v-row>
        
</div>
                
</template>
<style>
.box-center {
        position: fixed;
        left: 50%;
        bottom: -50px;
        transform: translate(-50%, -50%);
        margin: 0 auto;
    }
    .fixed-bottom-bg{
        position: fixed;
        left: 50%;
        bottom: -52px;
        transform: translate(-50%, -50%);
        margin: 0 auto;
        box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12) !important;
    }

@media only screen and (min-device-width : 1024px){
/* Styles */
/* width */
::-webkit-scrollbar {
  height: 8px;              /* height of horizontal scrollbar ← You're missing this */
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
}

</style>
<script>
import axios from 'axios';
import InfiniteLoading from 'vue-infinite-loading';

  export default {
    name: 'xchooisepayment',
    components: {
      InfiniteLoading
    },
    mounted(){
      //this.orders = []
      //this.$store.dispatch("order/getDataOrderTotal", this.$route.params)

      if(this.$vuetify.breakpoint.name == 'xs'){
        this.window_width = window.innerWidth
      }
      else
        this.window_width = '560px'

    },
    computed:{

      payment_methodes:{
        get() {
            return this.$store.state.order.payment_methodes
                
        },
        set(val) {
              this.$store.commit("order/update_payment_methodes",val)
        }
      },
      window_width:{
        get() {
            return this.$store.state.beranda.window_width
                
        },
        set(val) {
              this.$store.commit("beranda/update_window_width",val)
        }
      },
    },
    methods:{
        goBack(){
             var prm = this.$route.params
            this.$router.push({ path: `/order/id/${prm.id}`})
        },
        
    },
    data: () => ({
      infiniteId: +new Date(),
        masks: {
      input: 'DD-MM-YYYY',
    },
    infiniteId:1,
    dialog_daterange:false,
      isLogin:false,
      dialog_city:false,
      dialog_error:false,
      msg_error:'',
      reveal:false,
      dialog_branch:false,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      selected_city: {},
      categories:[{id:1,name:'PCR',active:false},{id:1,name:'Swab Antigen',active:false}],
      selected_categories:[],
      show: false,
      cities: [
          { name: 'Surabaya'},
          { name: 'Madiun'},
          { name: 'Salatiga'},
          { name: 'Magelang'},
          { name: 'Yogyakarta'},
          { name: 'Bandung'},
        ],
    }),
  }
</script>
