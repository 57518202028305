import Vue from 'vue'
import Vuex from 'vuex'
import beranda from "@/modules/beranda.js";
import choosebranch from "@/modules/choosebranch.js";
import details from "@/modules/details.js";
import order from "@/modules/order.js";
import test from "@/modules/test.js";
import login from "@/modules/login.js";
import patient from "@/modules/patient.js";
import listingtest from "@/modules/listingtest.js";

Vue.use(Vuex)

export default new Vuex.Store({

  state: {
  },
  mutations: {
    initializeStore() {
      const data = localStorage.getItem('oneState');

      if (data) {
        this.replaceState(Object.assign(this.state, JSON.parse(data)));
      }
    },
    removeItem(state, item) {
      [state.items.todo, state.items.inProgress, state.items.done].forEach(
        array => {
          const indexInArray = array.findIndex(i => i.id === item.id);
          if (indexInArray > -1) {
            array.splice(indexInArray, 1);
          }
        }
      );
    }
  },
  actions: {
  },
  modules: {
    beranda:beranda,
    choosebranch:choosebranch,
    details:details,
    order:order,
    login:login,
    test:test,
    patient:patient,
    listingtest:listingtest
  }
})
