<template>
  <div style="overflow-x:hidden">
    <v-dialog
        transition="dialog-top-transition"
        max-width="400"
        v-model="dialog_warning"
      >
        
          <v-card>

            <v-card-text>
              <div class="text-left text-h5 pt-5 pb-5"><v-icon class="mr-4" color="warning" large>fa-exclamation</v-icon> <span class="font-weight-bold pt-5">PERHATIAN</span></div>
              <p v-for="(error, i) in errors" :key="i" class="text--warning mb-2">
                {{error}}
              </p>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="dialog_warning = false"
              >Tutup</v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_form"
      persistent
      scrollable
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card  class="mx-auto" :height="windowHeight">
        <v-toolbar
          dark
          dense
          tile
          class="black--text"
                color="warning ligthen-2"
        >
          <v-btn
            icon
            class="black--text"
            @click="dialog_form = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>FORM PASIEN</v-toolbar-title>
          <v-spacer></v-spacer>
         
        </v-toolbar>
        <v-card-text :style="{height: windowHeight}">
          <v-container>
                    <v-row  class="pr-4" >
                        <v-col cols="12">
                        
                        <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                        >
                        <v-row>
                            <v-col cols="12">
                                <v-select
                                :items="ids"
                                hide-details="auto"
                                label="Tipe Identitas"
                                v-model="selected_id"
                                :rules="[v => !_.isEmpty(v)|| 'Pilih salah satu']"
                                @change="checkIdentity()"
                                item-text="name"
                                return-object
                                required
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                v-model="ktp"
                                hide-details="auto"
                                :rules="[v => !!v || 'Harus diisi']"
                                label="Nomor Identitas"
                                hint="* jika anak-anak diisi nomor KK"
                                persistent-hint
                                @change="checkIdentity()"
                                required
                                ></v-text-field>
                                <p :class="{'error--text':error_ktp}" class="caption mb-0">{{error_ktp_msg}}</p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <div>Jenis Kelamin</div>
                                    <v-radio-group
                                    class="mt-0 pt-2"
                                    v-model="sex"
                                    hide-details="auto"
                                    required
                                    :rules="[v => !!v || 'Pilih salah satu']"
                                    row
                                    >
                                    <v-radio
                                        label="Laki-laki"
                                        value="L"
                                    ></v-radio>
                                    <v-radio
                                        label="Perempuan"
                                        value="P"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4"
                                sm="4"
                                md="2">
                                <v-select
                                :items="titles"
                                item-text="name"
                               :rules="[v => !_.isEmpty(v)|| 'Harus diisi']"
                                    return-object
                                label="Sapaan"
                                v-model="selected_title"
                                required
                                ></v-select>
                            </v-col>
                            <v-col cols="8" sm="8"
                                md="10">
                                <v-text-field
                                v-model="name"
                                :rules="[v => !!v || 'Harus diisi']"
                                label="Nama Lengkap"
                                hide-details="auto"
                                required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center">
                            <v-col cols="12">
                                <p class="mb-0">Tanggal Lahir</p>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0 pt-0" align="center">
                            <v-col class="mt-0 pt-0 mr-0 pr-0" cols="4">
                                <v-text-field
                                    label="Tgl"
                                    ref="input_tgl"
                                    type="number"
                                    outlined
                                    @blur="changeTgl($event)"
                                    clearable
                                    dense
                                    v-model="tgl"
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>
                            <v-col class="mt-0 pt-0 mr-0 pr-0" cols="4">
                                <v-text-field
                                    label="Bln"
                                    ref="input_bln"
                                    type="number"
                                    outlined
                                     @blur="changeBln($event)"
                                    clearable
                                    dense
                                    v-model="bln"
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>
                            <v-col class="mt-0 pt-0 pr-0" cols="4">
                                <v-text-field
                                    label="Thn"
                                    type="number"
                                    ref="input_thn"
                                    @blur="changeThn($event)"
                                    outlined
                                    clearable
                                    dense
                                    v-model="thn"
                                    hide-details="auto"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="center">
                            <v-col class="mt-0 pt-0 pb-0 mb-0" cols="12">
                                <p :class="{'error--text':error_dob}" class="caption mb-0">Format DD-MM-YYYY, contoh : 01-06-1988</p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                v-model="hp"
                                :rules="[v => !!v || 'Harus diisi']"
                                label="No. HP"
                                required
                                hint="contoh : 081123321111"
                                hide-details="auto"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                v-model="job"
                                :rules="[v => !!v || 'Harus diisi']"
                                label="Pekerjaan"
                                required
                                hide-details="auto"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                v-model="pramitaid"
                                label="Pramita ID"
                                hint="Isi jika ada"
                                persistent-hint
                                hide-details="auto"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete
                                    v-model="selected_province"
                                    :rules="[v => !_.isEmpty(v)|| 'Harus diisi']"
                                    :items="provinces"  
                                    :search-input.sync="search_province"
                                    item-text="name"
                                    clearable
                                    return-object
                                    required
                                    clear-icon="fa-times"
                                    label="Propinsi"
                                    hide-details="auto"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete
                                    v-model="selected_city"
                                    :rules="[v => !_.isEmpty(v)|| 'Harus diisi']"
                                    :items="cities"  
                                    :search-input.sync="search_city"
                                    item-text="name"
                                    clearable
                                    return-object
                                    required
                                    clear-icon="fa-times"
                                    label="Kota"
                                    hide-details="auto"
                                ></v-autocomplete>
                       
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete
                                    v-model="selected_district"
                                    :rules="[v => !_.isEmpty(v)|| 'Harus diisi']"
                                    :items="districts"  
                                    :search-input.sync="search_district"
                                    item-text="name"
                                    clearable
                                    return-object
                                    required
                                    clear-icon="fa-times"
                                    label="Kecamatan"
                                    hide-details="auto"
                                ></v-autocomplete>
                                
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete
                                    v-model="selected_kelurahan"
                                    :rules="[v => !_.isEmpty(v)|| 'Harus diisi']"
                                    :items="kelurahans"  
                                    :search-input.sync="search_kelurahan"
                                    item-text="name"
                                    clearable
                                    return-object
                                    required
                                    clear-icon="fa-times"
                                    label="Kelurahan"
                                    hide-details="auto"
                                ></v-autocomplete>
                                
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea
                                    clearable
                                    clear-icon="mdi-close"
                                    label="Alamat"
                                    hide-details="auto"
                                    v-model="address"
                                    :rules="[v => !!v || 'Harus diisi']"
                                    required
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-btn
                                 :disabled="!valid || error_dob || error_ktp || _.isEmpty(selected_title)"
                                color="warning ligthen-2"
                                block
                                class="black--text pa-5 mt-5"
                                @click="savePatient()"
                                >
                                Simpan
                                </v-btn>
                            </v-col>
                        </v-row>
                        </v-form>
                        
                        </v-col>
                    </v-row>
                </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-app-bar
      color="white"
      fixed
      flat
      elevation="1"
      height="59px"
    >

      <v-list-item class="mx-auto pa-0">
        <v-list-item-avatar>
          <v-icon style="cursor:pointer" @click="goBack()">mdi-arrow-left</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-subtitle class="font-weight-bold title">
            <v-row no-gutters>
              <v-col class="font-weight-bold" cols="8">
                Detail Pemesanan
              </v-col>
            </v-row>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

    </v-app-bar>
 
    

    <v-row style="background:#f15e5e" class="mx-auto mt-14">
      <v-col class="mx-0 mt-0 pt-0" cols="12">
        <v-card flat>
          <p v-if="!_.isEmpty(localstorage)" class="text--h5 pa-3 mb-0 pb-0 font-weight-bold">PILIH {{localstorage.order.patient_number.name}}</p>
          <v-card-text class="pa-3">
            <v-list two-line>
            <template v-for="patient in patients">
              <div :style="{'background':checkExist(patient)?'#faebd7':'#fff'}">
              <v-list-item  @click="thisPatient(patient)">
                <v-list-item-content>
                  <v-list-item-title>
                    <p class="mb-1" :class="{'red--text':checkExist(patient)}">{{patient.name}}</p>
                  </v-list-item-title>
                   <v-list-item-subtitle v-text="patient.M_PatientIDNumber"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon v-if="checkExist(patient) === false">mdi-checkbox-blank-outline</v-icon>
                  <v-icon color="red accent-4" v-if="checkExist(patient)">mdi-checkbox-marked</v-icon>
                </v-list-item-action>
              </v-list-item>
              </div>
            </template>
          </v-list>
          </v-card-text>
          <v-card-actions class="mt-0">
            <v-row no-gutters>
              <v-col class="mt-0" cols="12">
              <p class="mb-0 text-center"><v-btn @click="openForm('new')" color="#1976d2" text>
                  tambahkan pasien baru
                </v-btn></p>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-auto  no-gutters flex-wrap">
      <v-col class="mx-0 pa-3 pl-0 pr-0 mt-2" cols="12">
        <v-row class="no-gutters">
          <v-col cols="12">
             <v-divider></v-divider>
          </v-col>
            </v-row>
      </v-col>
      <v-col class="mx-0 pr-3 pt-0 pl-3 pr-0" cols="12">
        <v-row no-gutters>
          <v-col cols="12">
            <v-switch 
              class="mt-0"
              hide-details
              v-model="is_klinisi"
              color="red lighten-2"
              label="Dengan rekomendasi dokter"
            ></v-switch>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="mx-0 pa-3 pl-0 pr-0" cols="12">
        <v-row class="no-gutters">
          <v-col cols="12">
             <v-divider></v-divider>
          </v-col>
            </v-row>
      </v-col>
      <v-col class="mx-0 pt-0 pl-3 pr-0 pb-3" cols="12">
        <v-row no-gutters>
          <v-col cols="12">
            <v-container
              class="pa-0"
              fluid
            >
              <v-radio-group hide-details v-model="selected_test_purpose">
                <template v-slot:label>
                  <div class="text--h4 mt-0 font-weight-bold mb-4">TUJUAN PEMERIKSAAN</div>
                </template>
                <v-radio
                  class="pl-4 pb-2"
                  v-for="(purpose,n) in test_purposes"
                  :key="n"
                  color="red"
                  :value="purpose"
                >
                <template v-slot:label>
                  <div class="pl-3">{{purpose.name}}</div>
                </template>
                </v-radio>
              </v-radio-group>
            </v-container>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="mx-0 pa-3 pl-0 pt-0 pr-0 mt-0" cols="12">
        <v-row class="no-gutters">
          <v-col cols="12">
             <v-divider></v-divider>
          </v-col>
            </v-row>
      </v-col>
      <v-col class="mx-0 pa-3 mt-0 mb-3" cols="12">
        <v-row class="no-gutters">
          <v-col cols="12"><div class="body-2 font-weight-bold">PENGIRIMAN HASIL</div></v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-list
          v-for="(delivery,ndel) in deliveries"
          :key="ndel"
          subheader
        >
          <v-subheader style="height:18px">{{delivery.M_DeliveryName}}</v-subheader>

          <v-list-item v-for="(destinations,ndes) in delivery.destinations" :key="ndes">
            <v-list-item-content>
              <v-list-item-title>
                <v-chip
                  class="ma-0"
                  label
                  :color="destinations.status === 'Y'?'#faebd7':'#bbbbbb6b' "
                  @click="clicktext(ndel,ndes,destinations)"
                  v-if="destinations.type !== 'TEXT'"
                >
                  <v-icon class="mr-2" color="red" v-if="destinations.status === 'Y'">mdi-checkbox-marked</v-icon> 
                  <v-icon class="mr-2" v-if="destinations.status === 'N'">mdi-checkbox-blank-outline</v-icon>
                  {{destinations.destination}}
                </v-chip>
                <v-text-field
                  v-if="destinations.type == 'TEXT'"
                  :placeholder="destinations.label"
                  v-model="destinations.destination"
                  filled
                  hide-details="auto"
                  dense
                >
                <template v-slot:prepend-inner>
                  <v-icon  @click="clicktext(ndel,ndes,destinations)" class="mr-1" v-if="destinations.status === 'Y'">mdi-checkbox-marked</v-icon> 
                  <v-icon  @click="clicktext(ndel,ndes,destinations)" class="mr-1" v-if="destinations.status === 'N'">mdi-checkbox-blank-outline</v-icon>
                </template>
                </v-text-field>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col class="mx-0" cols="12">
        <v-row align="center" class="pa-5 pt-0">
          <v-col  class="text-center" cols="12">
          <v-btn
            block
            color="red lighten-1"
            dark
            class="pa-6"
            @click="checkComplete()"
          >
            PESAN SEKARANG
          </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <DialogLoading/>
  </div>
</template>
<style>
html,body{
  background:#fafafa
}
.box-center{
  position: fixed;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}
@media only screen and (min-device-width : 1024px){
/* Styles */
/* width */
::-webkit-scrollbar {
   height: 8px;              /* height of horizontal scrollbar ← You're missing this */
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
}


</style>
<script>
import DialogLoading from '@/components/DialogLoading';
  export default {
    name: 'xformpatient',
    components: {
      DialogLoading
    },
    mounted(){
      var prm = JSON.parse(localStorage.getItem("user"))

      var localstorage = JSON.parse(localStorage.getItem("oneState"))
      this.localstorage = localstorage
      this.$store.commit("choosebranch/update_selected_branch",localstorage.choosebranch.selected_branch)
      this.$store.commit("choosebranch/update_selected_subcategory",localstorage.choosebranch.selected_subcategory)
      this.$store.commit("order/update_selected_test_varian",localstorage.order.selected_test_varian)
      this.$store.commit("order/update_result_option",localstorage.order.result_option)
      this.$store.commit("order/update_selected_result_option",localstorage.order.selected_result_option)
      prm.branch = this.localstorage.choosebranch.selected_branch
      this.selected_patient =  []
      this.selected_test_purpose = {}
      this.$store.commit("beranda/update_dialog_loading",true)
      this.$store.dispatch("order/getTestPurpose",prm)
      this.$store.dispatch("order/getTitles",{})
    },
    computed:{
      hp:{
         get() {
             return this.$store.state.order.hp
                
         },
         set(val) {
             var hp = val.replace('+','')
                var pref_hp = hp.substring(0,2).replace('62',0)
                var new_hp = pref_hp+hp.slice(2)
            this.$store.commit("order/update_hp",new_hp)
         }
      },
        titles(){
            if(!this.sex || this.sex === ''){
                return []
            }
            else{
                var titles = this.$store.state.order.titles
                var sex = this.sex
                var xvalue = _.filter(titles, { 'sex': sex })
                
                return xvalue
            }
        },
      tgl:{
         get() {
             return this.$store.state.patient.tgl
                
         },
         set(val) {

            this.$store.commit("patient/update_tgl",val)
         }
      },
      bln:{
         get() {
             return this.$store.state.patient.bln
                
         },
         set(val) {
  
            this.$store.commit("patient/update_bln",val)
         }
      },
      thn:{
         get() {
             return this.$store.state.patient.thn
                
         },
         set(val) {
             //var newval = val.toString().padStart(2, "0")
               this.$store.commit("patient/update_thn",val)
         }
      },
      errors:{
         get() {
             return this.$store.state.order.errors
                
         },
         set(val) {
               this.$store.commit("order/update_errors",val)
         }
      },
       tgl:{
         get() {
             return this.$store.state.patient.tgl
                
         },
         set(val) {
               this.$store.commit("patient/update_tgl",val)
         }
      },
      bln:{
         get() {
             return this.$store.state.patient.bln
                
         },
         set(val) {
               this.$store.commit("patient/update_bln",val)
         }
      },
      thn:{
         get() {
             return this.$store.state.patient.thn
                
         },
         set(val) {
               this.$store.commit("patient/update_thn",val)
         }
      },
      deliveries:{
         get() {
             return this.$store.state.order.deliveries
                
         },
         set(val) {
               this.$store.commit("order/update_deliveries",val)
         }
      },
      localstorage:{
         get() {
             return this.$store.state.order.localstorage
                
         },
         set(val) {
               this.$store.commit("order/update_localstorage",val)
         }
      },
      dialog_form:{
         get() {
             return this.$store.state.order.dialog_form
                
         },
         set(val) {
               this.$store.commit("order/update_dialog_form",val)
         }
      },
      ids:{
         get() {
             return this.$store.state.order.ids
                
         },
         set(val) {
               this.$store.commit("order/update_ids",val)
         }
      },
       selected_id:{
         get() {
             return this.$store.state.order.selected_id
                
         },
         set(val) {
               this.$store.commit("order/update_selected_id",val)
         }
      },
      pramitaid:{
         get() {
             return this.$store.state.order.pramitaid
                
         },
         set(val) {
               this.$store.commit("order/update_pramitaid",val)
         }
      },
       address:{
         get() {
             return this.$store.state.order.address
                
         },
         set(val) {
               this.$store.commit("order/update_address",val)
         }
      },
      ktp:{
         get() {
             return this.$store.state.order.ktp
                
         },
         set(val) {
               this.$store.commit("order/update_ktp",val)
         }
      },
      name:{
         get() {
             return this.$store.state.order.name
                
         },
         set(val) {
               this.$store.commit("order/update_name",val)
         }
      },
      job:{
         get() {
             return this.$store.state.order.job
                
         },
         set(val) {
               this.$store.commit("order/update_job",val)
         }
      },
     sex:{
         get() {
             return this.$store.state.order.sex
                
         },
         set(val) {
               this.$store.commit("order/update_sex",val)
               this.selected_title = {}
         }
      },
      dob:{
         get() {
             return this.$store.state.order.dob
                
         },
         set(val) {
               this.$store.commit("order/update_dob",val)
         }
      },
      kelurahans:{
         get() {
             return this.$store.state.order.kelurahans
                
         },
         set(val) {
               this.$store.commit("order/update_kelurahans",val)
         }
      },
      selected_kelurahan:{
         get() {
             return this.$store.state.order.selected_kelurahan
                
         },
         set(val) {
               this.$store.commit("order/update_selected_kelurahan",val)
         }
      },
      districts:{
         get() {
             return this.$store.state.order.districts
                
         },
         set(val) {
               this.$store.commit("order/update_districts",val)
         }
      },
      selected_district:{
         get() {
             return this.$store.state.order.selected_district
                
         },
         set(val) {
               this.$store.commit("order/update_selected_district",val)
               this.$store.dispatch("order/getKelurahans", val)
         }
      },
      cities:{
         get() {
             return this.$store.state.order.cities
                
         },
         set(val) {
               this.$store.commit("order/update_cities",val)
         }
      },
      selected_city:{
         get() {
             return this.$store.state.order.selected_city
                
         },
         set(val) {
               this.$store.commit("order/update_selected_city",val)
               this.$store.dispatch("order/getDistricts", val)
         }
      },
      provinces:{
         get() {
             return this.$store.state.order.provinces
                
         },
         set(val) {
               this.$store.commit("order/update_provinces",val)
         }
      },
      selected_province:{
         get() {
             return this.$store.state.order.selected_province
                
         },
         set(val) {
               this.$store.commit("order/update_selected_province",val)
               this.$store.dispatch("order/getCities", val)
         }
      },
      selected_test_purpose:{
         get() {
             return this.$store.state.order.selected_test_purpose
                
         },
         set(val) {
               this.$store.commit("order/update_selected_test_purpose",val)
         }
      },
      test_purposes:{
         get() {
             return this.$store.state.order.test_purposes
                
         },
         set(val) {
               this.$store.commit("order/update_test_purposes",val)
         }
      },
      is_klinisi:{
         get() {
             return this.$store.state.order.is_klinisi
                
         },
         set(val) {
               this.$store.commit("order/update_is_klinisi",val)
               if(val)
                  this.show_ref = true
                else
                  this.show_ref = false
         }
      },
      referal_code:{
         get() {
             return this.$store.state.order.referal_code
                
         },
         set(val) {
               this.$store.commit("order/update_referal_code",val)
         }
      },
      selected_patient:{
         get() {
             return this.$store.state.order.selected_patient
                
         },
         set(val) {
               this.$store.commit("order/update_selected_patient",val)
         }
      },
      selected_branch:{
         get() {
             return this.$store.state.choosebranch.selected_branch
                
         },
         set(val) {
               this.$store.commit("choosebranch/update_selected_branch",val)
         }
      },
      tests:{
         get() {
             return this.$store.state.order.tests
                
         },
         set(val) {
               this.$store.commit("order/update_tests",val)
         }
      },
      selected_tests:{
         get() {
             return this.$store.state.order.selected_tests
         },
         set(val) {
               this.$store.commit("order/update_selected_tests",val)
         }
      },
        patients:{
         get() {
             return this.$store.state.order.patients
         },
         set(val) {
               this.$store.commit("order/update_patients",val)
         }
      },
      computedDateFormatted () {
        return this.formatDate(this.date)
      },
      date_myformat(){
        var d = new Date(this.localstorage.order.selected_date);
        var weekday = new Array(7);
        weekday[0] = "Minggu";
        weekday[1] = "Senin";
        weekday[2] = "Selasa";
        weekday[3] = "Rabu";
        weekday[4] = "Kamis";
        weekday[5] = "Jumat";
        weekday[6] = "Sabtu";

        var n = weekday[d.getDay()];
        //console.log(this.localstorage.order.selected_date)
        return n 
      }
    },
     methods:{
       changeTgl(event){
            //var val = event.target.value.toString().padStart(2, "0")
            this.tgl = event.target.value
            var dob = this.tgl+'-'+this.bln+'-'+this.thn
            var check_dob = this.$moment(dob, 'DD-MM-YYYY', true).isValid()
            this.error_dob = false
            if(!check_dob)
                this.error_dob = true
           
        },
        changeBln(event){
            this.bln = event.target.value
            var dob = this.tgl+'-'+this.bln+'-'+this.thn
            var check_dob = this.$moment(dob, 'DD-MM-YYYY', true).isValid()
            this.error_dob = false
            if(!check_dob)
                this.error_dob = true
        },
        changeThn(event){
            this.thn = event.target.value
            var dob = this.tgl+'-'+this.bln+'-'+this.thn
            var check_dob = this.$moment(dob, 'DD-MM-YYYY', true).isValid()
            this.error_dob = false
            if(!check_dob)
                this.error_dob = true
        },
        checkIdentity(){
          var ktp = this.ktp
          this.error_ktp = false
          this.error_ktp_msg = ''
          if(parseInt(this.selected_id.id) === 1 ){
            if(ktp.length < 16){
              this.error_ktp = true
              this.error_ktp_msg = 'KTP tidak valid'
            }
      
            if(isNaN(ktp)){
              //console.log('dasds')
              this.error_ktp = true
              this.error_ktp_msg = 'Hanya numerik'
            }
          }

          if(parseInt(this.selected_id.id) === 3 ){
            if(ktp.length == 0){
              this.error_ktp = true
              this.error_ktp_msg = 'Passport tidak valid'
            }
          }
        },
       thisPatient(patient){
         let selected = this.selected_patient
         let idx = _.findIndex(selected, function(o) { return o.M_PatientID == patient.M_PatientID})
         if(idx == -1){
           selected.push(patient)
           this.selected_patient = selected
         }
         else{
           selected.splice(idx, 1)
         }
       },
       checkExist(patient){
         let selected = this.selected_patient
         let idx = _.findIndex(selected, function(o) { return o.M_PatientID == patient.M_PatientID})
         if(idx == -1){
           return false
         }
         else{
           return true
         }
       },
        goBack(){
          this.$router.go(-1)
        },
        openForm(act){
          if(act === 'new'){
             this.$store.commit("order/update_patient_id",0)
             this.ktp = ''
             this.name = ''
             this.sex = ''
             this.tgl = ''
             this.bln = ''
             this.thn = ''
             this.hp = ''
             this.job = ''
             this.pramitaid = ''
             this.address = ''
             this.selected_province = {}
             this.selected_city = {}
             this.selected_district = {}
             this.selected_kelurahan = {}
             this.selected_id = {}
              this.selected_title = {}
             this.dialog_form = true
          }
        },
        savePatient(){
          var error = false
            this.error_dob = false
            var dob = this.tgl+'-'+this.bln+'-'+this.thn
            console.log(dob)
            var check_dob = this.$moment(dob, 'DD-MM-YYYY', true).isValid()
            console.log(check_dob)
            if(!check_dob)
                this.error_dob = true
          this.checkIdentity()
          if(this.$refs.form.validate()  && check_dob && !this.error_ktp){
            var patient = {
              act:this.act,
              idtype:this.selected_id,
              patient_id:this.$store.state.order.patient_id,
              ktp:this.ktp,
              title:this.selected_title,
              name:this.name,
              sex:this.sex,
              dob:this.thn+'-'+this.bln+'-'+this.tgl,
              job:this.job,
              pramitaid:this.pramitaid,
              address:this.address,
              province:this.selected_province,
              city:this.selected_city,
              district:this.selected_district,
              kelurahan:this.selected_kelurahan,
              hp:this.hp
            }
              this.$store.commit("beranda/update_dialog_loading",true)
            this.$store.dispatch("order/savePatient",patient)
          }
          
        },
        validate () {
          this.$refs.form.validate()
        },
        reset () {
          this.$refs.form.reset()
        },
        resetValidation () {
          this.$refs.form.resetValidation()
        },
        formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}-${month}-${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [ day,month, year] = date.split('-')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      clicktext(i,k,destination){
        var deliveries = this.deliveries
        if(destination.destination !== '' )
          deliveries[i].destinations[k].status = destination.status == 'Y'?'N':'Y'
        
        this.deliveries = deliveries
      },
      checkComplete(){
        console.log("alooooo")
        var selected_patient = this.selected_patient
        var selected_test_purpose = this.selected_test_purpose
        var deliveries = this.deliveries
        var errors = []
        if(selected_patient.length == 0){
            errors.push("Belum ada pasien yang dipilih")
        }
        if(selected_patient.length !== 0 && selected_patient.length < parseInt(this.localstorage.order.patient_number.id)){
            errors.push("Silahkan pilih "+this.localstorage.order.patient_number.id+" pasien")
        }
        if(selected_patient.length > parseInt(this.localstorage.order.patient_number.id)){
            errors.push("Pesanan hanya untuk "+this.localstorage.order.patient_number.id+" pasien")
        }
        if(_.isEmpty(selected_test_purpose)){
            errors.push("Pilih salah satu tujuan pemeriksaan")
        }
        var check_deliveries = []
        deliveries.forEach(function(delivery) {
          //console.log(delivery)
          delivery.destinations.forEach(function(destination) {
            //console.log(destination)
            if(destination.status === 'Y')
              check_deliveries.push(destination)
          })
        })
        console.log(errors)
        if(check_deliveries.length == 0)
          errors.push("Belum ada pengiriman hasil yang dipilih")

        if(errors.length > 0){
          console.log(errors)
          this.errors = errors
          this.dialog_warning = true
        }
        else{
          this.$store.commit("beranda/update_dialog_loading",true)
          var prm = {}
          prm.branch = this.localstorage.choosebranch.selected_branch
          prm.subcategory = this.localstorage.choosebranch.selected_subcategory
          prm.test = this.localstorage.order.selected_test_varian
          prm.result_option = this.localstorage.order.selected_result_option
          prm.date = this.localstorage.order.date_sampling
          prm.time = this.localstorage.order.selected_time
          prm.patient = this.selected_patient
          prm.purpose = this.selected_test_purpose
          prm.is_klinisi = this.is_klinisi
          prm.deliveries = this.deliveries
          this.$store.dispatch("order/checkOut",prm)
        }
      }
    },
    watch: {
      date (val) {
        this.dateFormatted = this.formatDate(this.date)
      },
      tgl (val) {
        if(val.length == 2){
            this.$refs.input_bln.focus()
        }
      },
      bln (val) {
        if(val.length == 2){
            this.$refs.input_thn.focus()
        }
      },
    },
    data: vm => ({
       search_province:'',
        search_city:'',
        search_district:'',
        search_kelurahan:'',
       error_dob:false,
        error_ktp:false,
      error_ktp_msg:'',
      dialog_warning:false,
      search:'',
      modal: false,
      date:'',
      dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
      value:'',
      valid: true,
      rulesdob: [
        v => !!vm.$moment(v, 'DD-MM-YYYY', true).isValid() || 'Format salah, harusnya dd-mm-yyyy',
        v => !!v || 'Harus diisi'
      ],
      email: '',
      select: null,
      items: [
        'Item 1',
        'Item 2',
        'Item 3',
        'Item 4',
      ],
      checkbox: false,
      switch2:false,
      show_ref:false,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    }),
  }
</script>
