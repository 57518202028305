<template>
    <div>
        <v-card class="mx-auto">
            <v-card-title class="mb-0 pb-0">
                <v-row align="center">
                    <v-col class="text-center" cols="3">
                        <v-icon color="green ligthen-2" x-large="x-large">fa-check-double</v-icon>
                    </v-col>
                    <v-col cols="auto">
                        <h4>SELAMAT</h4>
                        <p class="mb-0 body-1">Pesanan anda berhasil</p>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-row v-for="(order,k_i) in data_order" :key="k_i">
                    <v-col cols="12">
                        <v-card elevation="10">
                            
                            <v-list-item three-line>
                                <v-list-item-content>
                                  <p class="caption mb-1">Kode Booking</p>
                                  <kbd class="mb-2">
                                     {{order.qrcode}}
                                  </kbd>
                                  <v-list-item-subtitle><p class="mb-0 caption">{{order.order_date}} {{order.order_time}}</p></v-list-item-subtitle>
                                  <v-list-item-title class="title mb-1">
                                    {{order.patient_name}}
                                  </v-list-item-title>
                                  <v-list-item-subtitle>{{order.id_number}}</v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-avatar
                                  
                                  tile
                                  size="80"
                                  color="grey"
                                ><QRCanvas :options="{cellSize: 4,correctLevel: 'H',data: order.qrcode}"/></v-list-item-avatar>
                              </v-list-item>

                              <v-card-actions>
                                <v-btn
                                  text
                                  @click="openDetail(order,k_i)"
                                >
                                  <p v-if="order.xshow == 'N'" class="mb-0">LIHAT DETAIL</p>
                                    <p v-if="order.xshow == 'Y'" class="mb-0">TUTUP DETAIL</p>
                                </v-btn>
                              </v-card-actions>
                            <v-expand-transition>
                              <div  v-if="order.xshow == 'Y'">
                                  <v-divider></v-divider>
                                  <v-card-text>
                                      <v-row>
                                          <v-col class="pb-0" cols="6">
                                              <p class="caption grey--text mb-0">Status</p>
                                              <p class="mb-0 body-2 font-weight-bold">Pesanan Berhasil</p>
                                          </v-col>
                                          <v-col class="pb-0 text-right" cols="6">
                                              <p class="mb-0 caption grey--text">Tanggal Transaksi</p>
                                              <p class="mb-0 body-2  font-weight-bold">{{order.order_created}}</p>
                                          </v-col>
                                      </v-row>
                                      <v-row>
                                          <v-col cols="12">
                                              <v-divider/>
                                          </v-col>
                                      </v-row>
                                      <v-row>
                                          <v-col cols="12">
                                              <p class="caption grey--text mb-0">Pasien</p>
                                              <p class="body-1 font-weight-bold">{{order.patient_name}}</p>
                                          </v-col>
                                      </v-row>
                                      <v-row>
                                          <v-col cols="12">
                                              <p class="body-2 mb-1">DETAIL PEMBELIAN</p>
                                          </v-col>
                                      </v-row>
                                      <v-row v-for="(detail,nd) in order.details" :key="nd">
                                          <v-col cols="12">
                                              <v-row no-gutters="no-gutters">
                                                  <v-col cols="6">
                                                      <p class="mb-1 body-2">Pemeriksaan</p>
                                                  </v-col>
                                                  <v-col cols="6">
                                                      <p class="mb-1 body-2 text-right  font-weight-bold">{{detail.test_name}}
                                                      </p>
                                                      <p v-if="detail.result_option_name !== ''" class="mb-1 caption  text-right">
                                                          <kbd class="warning white--text">{{detail.result_option_name}}</kbd>
                                                      </p>
                                                  </v-col>
                                              </v-row>
                                          </v-col>
                                          <v-col cols="12">
                                              <v-row no-gutters="no-gutters">
                                                  <v-col cols="6">
                                                      <p class="mb-1 body-2">Harga</p>
                                                  </v-col>
                                                  <v-col cols="6">
                                                      <p class="mb-1 body-2 text-right font-weight-bold">{{detail.total}}</p>
                                                  </v-col>
                                              </v-row>
                                          </v-col>
                                      </v-row>
                                      <v-divider/>
                                      <v-row>
                                          <v-col cols="12">
                                              <p class="body-2 mb-1">TEMPAT PENGAMBILAN SPESIMEN</p>
                                          </v-col>
                                          <v-col cols="12">
                                              <v-row no-gutters="no-gutters">
                                                  <v-col cols="12">
                                                      <p class="mb-1 body-2  font-weight-bold">{{order.branch_name}}</p>
                                                  </v-col>
                                                  <v-col cols="12">
                                                      <p class="mb-1 body-2">{{order.branch_address}}</p>
                                                  </v-col>
                                              </v-row>
                                          </v-col>
                                      </v-row>
                                      <v-divider/>
                                      <v-row>
                                          <v-col cols="12">
                                              <p class="body-2 mb-1">WAKTU PENGAMBILAN SPESIMEN</p>
                                          </v-col>
                                          <v-col class="mb-2 pb-0 pt-0 mt-0" cols="12">
                                              <v-row no-gutters>
                                                  <v-col cols="12">
                                                      <p class="mb-1 font-weight-bold body-2">{{order.order_date}}
                                                          {{order.order_time}}</p>
                                                  </v-col>
                                              </v-row>
                                          </v-col>
                                      </v-row>
                                      <v-divider/>
                                      <v-row v-if="order.purpose_name !== ''">
                                          <v-col cols="12">
                                              <p class="body-2 mb-1">TUJUAN PEMERIKSAAN</p>
                                          </v-col>
                                          <v-col class="mb-2 pb-0 pt-0 mt-0" cols="12">
                                              <v-row no-gutters>
                                                  <v-col cols="12">
                                                      <p class="mb-1 font-weight-bold body-2">{{order.purpose_name}}</p>
                                                  </v-col>
                                              </v-row>
                                          </v-col>
                                      </v-row>
                                      <v-divider/>
                                      <v-row v-if="order.deliveries.length > 0">
                                          <v-col cols="12">
                                              <p class="body-2 mb-1">PENGIRIMAN HASIL</p>
                                          </v-col>
                                          <v-col cols="12">
                                              <v-row
                                                  v-for="(delivery,n_del) in order.deliveries"
                                                  :key="n_del"
                                                  no-gutters="no-gutters">
                                                  <v-col cols="5">
                                                      <p class="mb-2 body-2">{{delivery.delivery_type_name}}</p>
                                                  </v-col>
                                                  <v-col cols="7">
                                                      <p class="mb-2 body-2 font-weight-bold">{{delivery.destination}}</p>
                                                  </v-col>
                                              </v-row>
                                          </v-col>
                                      </v-row>
                                  </v-card-text>
                              </div>
                          </v-expand-transition>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
            

            
        </v-card>
        <v-card class="mx-auto mt-1 pb-12">
            <v-card-title class="mb-0 pb-0">
                <v-row align="center">
                    <v-col class="text-center" cols="2">
                        <v-icon color="warning" large="large">fa-exclamation</v-icon>
                    </v-col>
                    <v-col cols="auto">
                        <h4>PENTING</h4>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text class="pb-0 mb-0">
                <v-col cols="12">
                    <v-row align="center">
                        <v-col cols="2">
                            <p class="mb-1 body-2">
                                <v-icon>fa-chevron-right</v-icon>
                            </p>
                        </v-col>
                        <v-col cols="10">
                            <p class="mb-1 body-2 font-weight-bold">Harap datang sesuai dengan waktu yang ditentukan, untuk menghindari kerumunan</p>
                        </v-col>
                    </v-row>
                    <v-row align="center">
                        <v-col cols="2">
                            <p class="mb-1 body-2">
                                <v-icon>fa-chevron-right</v-icon>
                            </p>
                        </v-col>
                        <v-col cols="10">
                            <p class="mb-1 body-2 font-weight-bold">Jika datang terlambat, untuk pemeriksaan
                                <span class="warning--text font-weight-black">PCR</span>
                                dengan
                                <span class="red--text">hasil di hari yang sama</span>
                                menjadi batal dan dicarikan waktu pengganti</p>
                        </v-col>
                    </v-row>
                </v-col>
            </v-card-text>
        </v-card>


        <v-row style="width:100%;background:#fff" class="fixed-bottom-bg" justify="center">
            <v-col cols="12"  class="text-center mb-2">
                <v-card flat >
                    <v-row no-gutters>
                        <v-col class="text-left" cols="6">
                            <p class="mb-0 body-2 grey--text">TOTAL</p>
                            <p v-if="data_order.length > 0" class="mb-0 body-1 font-weight-bold">{{row_header.total}}</p>
                        </v-col>
                        <v-col class="text-right pt-1" cols="6">
                                <v-btn
                                    rounded
                                    color="red lighten-1"
                                    dark
                                    block
                                    @click="goBayar()"
                                >
                                Bayar
                                </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </div>

</template>
<style>


    .fixed-bottom-bg{
        position: fixed;
        left: 50%;
        bottom: -52px;
        transform: translate(-50%, -50%);
        margin: 0 auto;
        box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12) !important;
    }
    @media only screen and (min-device-width : 1024px) {
        /* Styles */
        /* width */
        ::-webkit-scrollbar {
            height: 8px;
            /* height of horizontal scrollbar ← You're missing this */
            width: 8px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #888;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
</style>
<script>
    import Choosebranch from '@/components/Choosebranch';
    import { SwipeableBottomSheet } from "vue-swipeable-bottom-sheet";
    import {QRCanvas} from 'qrcanvas-vue';
    export default {
        name: 'xcheckout',
        components: {
            QRCanvas,
            SwipeableBottomSheet
        },
        created() {
            /*const image = new Image();
      image.src = require('../assets/logotransparan.png');
      image.onload = () => {
        this.options = Object.assign({}, this.options, {
          logo: {
            image,
          },
        });
      };*/
        },
        mounted() {
            this.$store.commit("beranda/update_dialog_loading",true)
            this.$store.dispatch("order/getDataOrder", this.$route.params)

            if (this.$vuetify.breakpoint.name == 'xs') {
                this.window_width = window.innerWidth
            } else 
                this.window_width = '560px'

        },

        beforeRouteLeave(to, from, next) {
                if(to.name === 'xformpatient'){
                    next(false)
                }
                else
                    next()

        },
        computed: {
            row_header: {
                get() {
                    return this.$store.state.order.row_header

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_row_header", val)
                }
            },
            user: {
                get() {
                    return this.$store.state.beranda.user

                },
                set(val) {
                    this
                        .$store
                        .commit("beranda/update_user", val)
                }
            },
            opt_qrcode: {
                get() {
                    return this.$store.state.order.opt_qrcode

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_opt_qrcode", val)
                }
            },
            data_order: {
                get() {
                    return this.$store.state.order.data_order

                },
                set(val) {
                    this.$store.commit("order/update_data_order", val)
                }
            },
            lat: {
                get() {
                    return this.$store.state.beranda.lat

                },
                set(val) {
                    this
                        .$store
                        .commit("beranda/update_lat", val)
                }
            },
            lng: {
                get() {
                    return this.$store.state.beranda.lng

                },
                set(val) {
                    this
                        .$store
                        .commit("beranda/update_lng", val)
                }
            },
            window_width: {
                get() {
                    return this.$store.state.beranda.window_width

                },
                set(val) {
                    this
                        .$store
                        .commit("beranda/update_window_width", val)
                }
            },
            date_myformat(xdate) {
console.log(xdate)
                    var d = new Date(xdate);
                    var weekday = new Array(7);
                    weekday[0] = "Minggu";
                    weekday[1] = "Senin";
                    weekday[2] = "Selasa";
                    weekday[3] = "Rabu";
                    weekday[4] = "Kamis";
                    weekday[5] = "Jumat";
                    weekday[6] = "Sabtu";

                    var n = weekday[d.getDay()];
                    //console.log(this.localstorage.order.selected_date)
                    return n + ', ' + this.$moment(xdate).format('DD-MM-YYYY')


            }
        },
        methods: {
            goBayar(){
                var prm = this.$route.params
                this.$router.push({ path: `/order/id/${prm.id}`})
            },
          openDetail(order,i){
            var xdata = this.data_order
            xdata[i]['xshow'] = order.xshow == 'N' ? 'Y':'N'
           // console.log( xdata[i]['show'])
            //this.data_order = xdata
            this.$store.commit("order/update_data_order", xdata)
          },
            goToHome() {
                this
                    .$router
                    .push({name: 'xberanda'})
            },
            getWIdth() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return 320
                    case 'sm':
                        return 325
                    case 'md':
                        return 363
                    case 'lg':
                        return 363
                }
            },
            logout() {
                var user = JSON.parse(localStorage.getItem("user"))
                user.lat = this.$store.state.beranda.lat
                user.lng = this
                    .$store
                    .state
                    .beranda
                    .lng
                    this
                    .$store
                    .dispatch("login/logout", user)
            }
        },
        data: () => ({
            show: false,
            options: {
                cellSize: 8,
                correctLevel: 'H',
                data: 'F4REY'
            },
            isLogin: false,
            dialog_city: false,
            dialog_error: false,
            msg_error: '',
            reveal: false,
            dialog_branch: false,
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth,
            selected_city: {},
            categories: [
                {
                    id: 1,
                    name: 'PCR',
                    active: false
                }, {
                    id: 1,
                    name: 'Swab Antigen',
                    active: false
                }
            ],
            selected_categories: [],
            show: false,
            mylogic:'hihihi',
            cities: [
                {
                    name: 'Surabaya'
                }, {
                    name: 'Madiun'
                }, {
                    name: 'Salatiga'
                }, {
                    name: 'Magelang'
                }, {
                    name: 'Yogyakarta'
                }, {
                    name: 'Bandung'
                }
            ]
        })
    }
</script>