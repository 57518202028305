<template>
  <div>
        <v-dialog
        transition="dialog-top-transition"
        max-width="600"
        v-model="dialog"
      >
          <v-card>
            <v-toolbar
              color="warning"
              dark
              dense
            >Peringatan</v-toolbar>
            <v-card-text>
              <div v-html="msg" class="mt-3 pa-2"></div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="msg = ''"
              >Tutup</v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>
  </div>
</template>
<style>
</style>
<script>
  export default {
    name: 'dialogloading',
    computed:{
      msg:{
        get() {
            return this.$store.state.beranda.api_warning_message
                
        },
        set(val) {
              this.$store.commit("beranda/update_api_warning_message",val)
        }
      },
      dialog(){
          return this.$store.state.beranda.api_warning_message !== ''
      }
    },
    methods:{
    },
    data: () => ({
      
    }),
  }
</script>
