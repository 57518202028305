<template>
  <v-container class="fill-height pa-0">
    <v-dialog
      v-model="dialog_branch"
      persistent
      scrollable
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card :height="windowHeight">
        <v-card-title>
          <span class="title"><v-btn class="mr-3" @click="dialog_branch = false" style="min-width:30px;padding:0" text><v-icon>fa-times</v-icon></v-btn> Pilih Cabang Pramita</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text :style="{height: windowHeight}">
          <v-container>
            <v-row class="mx-auto">
              <v-col cols="12">
              <v-autocomplete
                v-model="selected_city"
                :items="cities"
                return-object
                color="red"
                item-color="red lighten-2"
                item-text="name"
                label="Kota"
                persistent-hint
                clearable
                prepend-icon="mdi-city"
              >
              </v-autocomplete>
            </v-col>
            </v-row>
            <v-container
              >
                <v-row
                  :align="align"
                  no-gutters
                  class="mx-auto"
                  style="height: 150px;"
                >
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-card
                      color="#d32b16"
                      dark
                      class="ma-1"
                    >
                      <v-card-title class="title">
                        Pramita Adityawarman
                      </v-card-title>

                      <v-card-subtitle>Jl. Adityawarman No.1 Surabaya</v-card-subtitle>

                      <v-card-actions>
                        <v-btn
                          flat
                          class="ma-2 white--text"
                        >
                          25 KM
                          <v-icon
                            right
                            dark
                          >
                            fa-map-marked
                          </v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text>
                          PILIH
                        </v-btn>
                        
                        
                      </v-card-actions>
                    </v-card>
                  </v-col>
                  
                  
                  
                </v-row>
              </v-container>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="dialog_info"
        width="500"
      >
        <v-card>
          <v-card-title class="white--text headline blue">
            Informasi
          </v-card-title>

          <v-card-text>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog_info = false"
            >
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-row grow no-gutters>
      
      <v-card 
        :min-height="windowHeight"
        width="100%"  class="mb-5 mx-auto  rounded-tr-0 rounded-tl-0">
          <p class="headline font-weight-bold ml-5 mt-5 mb-0">E Booking Pramitalab</p>
          <v-row no-gutters>
            <v-col
              cols="12"
              xs="12"
              class="pa-4"
            >
              <v-text-field
                  label="Cari pemeriksaan"
                  outlined
                  color="#363e61"
                  hide-details
                  dense
                >
                <template v-slot:prepend-inner>
                  <v-icon class="pt-1" small>
                        fa-search
                    </v-icon>
                </template>
                </v-text-field>
            </v-col>
          </v-row>
          
          <v-card
            class="no-gutters pl-1 d-flex pb-3"
            flat
            tile
            style="width:560px;overflow-y:auto"
          >
            <v-btn
              class="ma-1"
              small
              
              color="#d32b16"
              dark
            >
              Semua
            </v-btn>
            <v-btn
              class="ma-1"
              small
              outlined
              color="#d32b16"
              dark
            >
              PCR
            </v-btn>
            <v-btn
              class="ma-1"
              small
              outlined
              color="#d32b16"
              dark
            >
              Swab Antigen
            </v-btn>
            <v-btn
              class="ma-1"
              small
              outlined
              color="#d32b16"
              dark
            >
              Hasil 1 Hari
            </v-btn>
            <v-btn
              class="ma-1"
              small
              outlined
              color="#d32b16"
              dark
            >
              Hasil 2 Hari
            </v-btn>
            <v-btn
              class="ma-1"
              small
              outlined
              color="#d32b16"
              dark
            >
              Reguler
            </v-btn>
          </v-card>
          <v-card-text class="mb-12 pt-1">   
              <v-card
                    class="rounded mb-2 pa-2 d-flex"
                    outlined
                    elevation="4"
                  >
                    <v-card-text class="no-gutters pa-0 ma-0">
                      <v-list-item two-line
                      >
                        <v-list-item-avatar tile class="align-center">
                          <v-icon
                            class="red lighten-3"
                            dark
                          >
                            fa-syringe
                          </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>SARS-CoV-2 RNA </v-list-item-title>
                          <v-list-item-subtitle>Hasil 1 hari, PCR</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-row no-gutters>
                        <v-col cols="6" class="pt-2 text-left">
                         <v-btn
                          small
                          color="red"
                          text
                          @click="dialog_info = true"
                        >
                          lihat detail
                        </v-btn>
                      </v-col>
                        <v-col cols="6" class="pt-2 text-right">
                         <v-btn
                          small
                          color="red"
                          dark
                        >
                          Pesan Sekarang
                        </v-btn>
                      </v-col>
                      </v-row>
                    </v-card-text>  
                </v-card>
                
          </v-card-text>
        </v-card>
    </v-row>
    <v-bottom-navigation
      max-width="560px"
      :style="{left: '50%', transform:'translateX(-50%)', position:'fixed',bottom:'0px'}"
      grow
   
      value="0"
      color="#f8533f"
      dark
      class="mx-auto box-center justify-center text-center align-center"
    >
      <v-btn
        color="red"
        text
      >
        <span>Home</span>

        <v-icon>fa-home</v-icon>
      </v-btn>

      <v-btn
        color="deep-purple accent-4"
        text
      >
        <span>Riwayat</span>

        <v-icon>fa-receipt</v-icon>
      </v-btn>

      <v-btn
        color="deep-purple accent-4"
        text
      >
        <span>login</span>

        <v-icon>fa-sign-in-alt</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-container>
</template>
<style>
.box-center{
  position: fixed;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}
@media only screen and (min-device-width : 1024px){
/* Styles */
/* width */
::-webkit-scrollbar {
   height: 8px;              /* height of horizontal scrollbar ← You're missing this */
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
}


</style>
<script>
  export default {
    name: 'dashboard',
    mounted(){
      

    },
     methods:{
        goToChooseBranch(code){
          this.$router.push({name:'choosebranch',params:{code:code,branchID:0}})
        }
    },
    data: () => ({
      dialog_info:false,
      reveal:false,
      dialog_branch:false,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      selected_city: {},
      cities: [
          { name: 'Surabaya'},
          { name: 'Madiun'},
          { name: 'Salatiga'},
          { name: 'Magelang'},
          { name: 'Yogyakarta'},
          { name: 'Bandung'},
        ],
    }),
  }
</script>
