<template>
  <div>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="dialog_rincian"
      >
  
          <v-card>
            <v-toolbar
             color="blue lighten-1"
              dark
              elevation="0"
            >RINCIAN PEMESANAN</v-toolbar>
            <v-card-text>
              <v-row v-if="list_header" class="ma-2 mb-1 mt-1 mr-0" v-for="(patient,kpatient) in data_order" :key="kpatient">
                    <v-col class="pb-1 pt-1" cols="12">
                        <p class="mb-0 body-2 font-weight-bold">{{patient.patient_name}}</p>
                        <v-row v-for="(detail, kdetail) in patient.details" no-gutters>
                            <v-col class="pa-1 pl-0" cols="9">
                                <p class="mb-0 body-2">{{detail.test_name}}</p>
                            </v-col>
                            <v-col class="pa-1 pl-0" cols="3">
                                <p class="mb-0 body-2 text-right">{{detail.total}}</p>
                            </v-col>
                        </v-row>
                        <v-row  v-if="patient.fee_ehac !== '0'" no-gutters>
                            <v-col class="pa-1 pl-0" cols="4">
                                <p class="mb-0 body-2">Biaya eHAC</p>
                            </v-col>
                            <v-col class="pa-1 pl-0" cols="8">
                                <p class="mb-0 body-2 text-right">{{formatRupiah(patient.fee_ehac)}}</p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row  class="ma-2 mb-1 mt-1 mr-0">
                    <v-col class="pb-1 pt-1" cols="12">
                        <v-row  no-gutters>
                            <v-col class="pa-1 pl-0" cols="9">
                                <p class="mb-0 body-2">Biaya Layanan</p>
                            </v-col>
                            <v-col class="pa-1 pl-0" cols="3">
                                <p class="mb-0 body-2 text-right">{{list_header.fee}}</p>
                            </v-col>
                        </v-row>
                        <v-row  no-gutters>
                            <v-col class="pa-1 pl-0" cols="4">
                                <p class="mb-0 body-1 font-weight-black">GRAND TOTAL</p>
                            </v-col>
                            <v-col class="pa-1 pl-0" cols="8">
                                <p class="mb-0 body-1 font-weight-black text-right">{{list_header.total}}</p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="dialog_rincian = false"
              >Tutup</v-btn>
            </v-card-actions>
          </v-card>
      
      </v-dialog>

      <v-app-bar
      color="white"
      fixed
      elevation="1"
      height="59px"
    >
        <v-list-item class="mx-auto pa-0">
        <v-list-item-avatar>
            <v-icon style="cursor:pointer" @click="goBack()">mdi-arrow-left</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-subtitle class="font-weight-bold title">
            <v-row no-gutters>
                <v-col cols="8">
                Detail Pesanan
                </v-col>
            </v-row>
            </v-list-item-subtitle>
        </v-list-item-content>
        </v-list-item>
    </v-app-bar>
  <v-card
  v-if=" list_header.payment_status === 'Y'"
    color="#EF5350"
    class="mx-auto mt-16 mb-2"
    tile
  >
    <v-card-text>
                <v-row v-if="list_header.app_version == '1.0'" v-for="(order,k_i) in data_order" :key="k_i">
                    <v-col cols="12">
                        <v-card elevation="10">
                            
                            <v-list-item three-line>
                                <v-list-item-content>
                                  <p class="caption mb-1">Kode Booking</p>
                                  <kbd class="mb-2">
                                     {{order.qrcode}}
                                  </kbd>
                                  <v-list-item-subtitle><p class="mb-0 caption">{{order.order_date}} {{order.order_time}}</p></v-list-item-subtitle>
                                  <v-list-item-title class="title mb-1">
                                    {{order.patient_name}}
                                  </v-list-item-title>
                                  <v-list-item-subtitle>{{order.id_number}}</v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-avatar
                                  tile
                                  size="80"
                                  color="grey"
                                ><QRCanvas :options="{cellSize: 4,correctLevel: 'H',data: order.qrcode}"/></v-list-item-avatar>
                              </v-list-item>

                              <v-card-actions>
                                <v-btn
                                  text
                                  @click="openDetail(order,k_i)"
                                >
                                  <p v-if="order.xshow == 'N'" class="mb-0">LIHAT DETAIL</p>
                                    <p v-if="order.xshow == 'Y'" class="mb-0">TUTUP DETAIL</p>
                                </v-btn>
                              </v-card-actions>
                            <v-expand-transition>
                              <div  v-if="order.xshow == 'Y'">
                                  <v-divider></v-divider>
                                  <v-card-text>
                                      <v-row>
                                          <v-col class="pb-0" cols="6">
                                              <p class="caption grey--text mb-0">Status</p>
                                              <p class="mb-0 body-2 font-weight-bold">Pesanan Berhasil</p>
                                          </v-col>
                                          <v-col class="pb-0 text-right" cols="6">
                                              <p class="mb-0 caption grey--text">Tanggal Transaksi</p>
                                              <p class="mb-0 body-2  font-weight-bold">{{order.order_created}}</p>
                                          </v-col>
                                      </v-row>
                                      <v-row>
                                          <v-col cols="12">
                                              <v-divider/>
                                          </v-col>
                                      </v-row>
                                      <v-row>
                                          <v-col cols="12">
                                              <p class="caption grey--text mb-0">Pasien</p>
                                              <p class="body-1 font-weight-bold">{{order.patient_name}}</p>
                                          </v-col>
                                      </v-row>
                                      <v-row>
                                          <v-col cols="12">
                                              <p class="body-2 mb-1">DETAIL PEMBELIAN</p>
                                          </v-col>
                                      </v-row>
                                      <v-row v-for="(detail,nd) in order.details" :key="nd">
                                          <v-col cols="12">
                                              <v-row no-gutters="no-gutters">
                                                  <v-col cols="6">
                                                      <p class="mb-1 body-2">Pemeriksaan</p>
                                                  </v-col>
                                                  <v-col cols="6">
                                                      <p class="mb-1 body-2 text-right  font-weight-bold">{{detail.test_name}}
                                                      </p>
                                                      <p v-if="detail.result_option_name !== ''" class="mb-1 caption  text-right">
                                                          <kbd class="warning white--text">{{detail.result_option_name}}</kbd>
                                                      </p>
                                                  </v-col>
                                              </v-row>
                                          </v-col>
                                          <v-col cols="12">
                                              <v-row no-gutters="no-gutters">
                                                  <v-col cols="6">
                                                      <p class="mb-1 body-2">Harga</p>
                                                  </v-col>
                                                  <v-col cols="6">
                                                      <p class="mb-1 body-2 text-right font-weight-bold">{{detail.total}}</p>
                                                  </v-col>
                                              </v-row>
                                          </v-col>
                                      </v-row>
                                      <v-divider/>
                                      <v-row>
                                          <v-col cols="12">
                                              <p class="body-2 mb-1">TEMPAT PENGAMBILAN SPESIMEN</p>
                                          </v-col>
                                          <v-col cols="12">
                                              <v-row no-gutters="no-gutters">
                                                  <v-col cols="12">
                                                      <p class="mb-1 body-2  font-weight-bold">{{order.branch_name}}</p>
                                                  </v-col>
                                                  <v-col cols="12">
                                                      <p class="mb-1 body-2">{{order.branch_address}}</p>
                                                  </v-col>
                                              </v-row>
                                          </v-col>
                                      </v-row>
                                      <v-divider/>
                                      <v-row>
                                          <v-col cols="12">
                                              <p class="body-2 mb-1">WAKTU PENGAMBILAN SPESIMEN</p>
                                          </v-col>
                                          <v-col class="mb-2 pb-0 pt-0 mt-0" cols="12">
                                              <v-row no-gutters>
                                                  <v-col cols="12">
                                                      <p class="mb-1 font-weight-bold body-2">{{order.order_date}}
                                                          {{order.order_time}}</p>
                                                  </v-col>
                                              </v-row>
                                          </v-col>
                                      </v-row>
                                      <v-divider/>
                                      <v-row v-if="order.purpose_name !== ''">
                                          <v-col cols="12">
                                              <p class="body-2 mb-1">TUJUAN PEMERIKSAAN</p>
                                          </v-col>
                                          <v-col class="mb-2 pb-0 pt-0 mt-0" cols="12">
                                              <v-row no-gutters>
                                                  <v-col cols="12">
                                                      <p class="mb-1 font-weight-bold body-2">{{order.purpose_name}}</p>
                                                  </v-col>
                                              </v-row>
                                          </v-col>
                                      </v-row>
                                      <v-divider/>
                                      <v-row v-if="order.deliveries.length > 0">
                                          <v-col cols="12">
                                              <p class="body-2 mb-1">PENGIRIMAN HASIL</p>
                                          </v-col>
                                          <v-col cols="12">
                                              <v-row
                                                  v-for="(delivery,n_del) in order.deliveries"
                                                  :key="n_del"
                                                  no-gutters="no-gutters">
                                                  <v-col cols="5">
                                                      <p class="mb-2 body-2">{{delivery.delivery_type_name}}</p>
                                                  </v-col>
                                                  <v-col cols="7">
                                                      <p class="mb-2 body-2 font-weight-bold">{{delivery.destination}}</p>
                                                  </v-col>
                                              </v-row>
                                          </v-col>
                                      </v-row>
                                  </v-card-text>
                              </div>
                          </v-expand-transition>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-if="list_header.app_version == '2.0'" v-for="(order,k_i) in data_order" :key="k_i">
                    <v-col cols="12">
                        <v-card elevation="10">
                            
                            <v-list-item three-line>
                                <v-list-item-content>
                                  <p class="caption mb-1">Kode Booking</p>
                                  <kbd class="mb-2">
                                     {{order.qrcode}}
                                  </kbd>
                                  <v-list-item-subtitle><p class="mb-0 caption">{{order.order_date}} {{order.order_time}}</p></v-list-item-subtitle>
                                  <v-list-item-title class="title mb-1">
                                    {{order.patient_name}}
                                  </v-list-item-title>
                                  <v-list-item-subtitle>{{order.id_number}}</v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-avatar
                                  tile
                                  size="80"
                                  color="grey"
                                ><QRCanvas :options="{cellSize: 4,correctLevel: 'H',data: order.qrcode}"/></v-list-item-avatar>
                              </v-list-item>

                              <v-card-actions>
                                <v-btn
                                  text
                                  @click="openDetail(order,k_i)"
                                >
                                  <p v-if="order.xshow == 'N'" class="mb-0">LIHAT DETAIL</p>
                                    <p v-if="order.xshow == 'Y'" class="mb-0">TUTUP DETAIL</p>
                                </v-btn>
                              </v-card-actions>
                            <v-expand-transition>
                              <div  v-if="order.xshow == 'Y'">
                                  <v-divider></v-divider>
                                  <v-card-text>
                                      <v-row no-gutters>
                                          <v-col class="pb-0" cols="6">
                                              <p class="caption grey--text mb-0">Status</p>
                                              <p class="mb-0 body-2 font-weight-bold">Pesanan Berhasil</p>
                                          </v-col>
                                          <v-col class="pb-0 text-right" cols="6">
                                              <p class="mb-0 caption grey--text">Tanggal Transaksi</p>
                                              <p class="mb-0 body-2  font-weight-bold">{{order.order_created}}</p>
                                          </v-col>
                                      </v-row>
                                      <v-row>
                                          <v-col cols="12">
                                              <v-divider/>
                                          </v-col>
                                      </v-row>
                                      <v-row no-gutters class="mb-3">
                                          <v-col class="pb-0" cols="6">
                                              <p class="caption grey--text mb-0">Cabang</p>
                                              <p class="mb-0 body-2 font-weight-bold">{{list_header.branch_name}}</p>
                                          </v-col>
                                          <v-col class="pb-0  text-right" cols="6">
                                              <p class="caption grey--text mb-0">Waktu Kunjungan</p>
                                              <p class="mb-0 body-2 font-weight-bold">{{order.order_date}} {{order.order_time}}</p>
                                          </v-col>
                                      </v-row>
                                      <v-row>
                                          <v-col cols="12">
                                              <v-divider/>
                                          </v-col>
                                      </v-row>
                                      <v-row no-gutters >
                                          <v-col class="mb-0 pb-0" cols="12">
                                              <p class="body-2 font-weight-bold mb-1">RINCIAN PEMERIKSAAN</p>
                                          </v-col>
                                          <v-col cols="12">
                                              <v-row no-gutters v-for="(detail,kdetail) in order.details" :key="kdetail">
                                                  <v-col cols="12">
                                                      <p class="mb-1 body-2">1 x {{detail.test_name}}</p>
                                                  </v-col>
                                                  <!--<v-col class="text-right" cols="6">
                                                      <p class="mb-1 body-2">{{detail.total}}</p>
                                                  </v-col>-->
                                              </v-row>
                                          </v-col>
                                          <!--<v-row no-gutters v-if="parseInt(order.fee_ehac) > 0">
                                            <v-col cols="6">
                                                <p class="mb-1 body-2">Layanan eHAC</p>
                                            </v-col>
                                            <v-col class="text-right" cols="6">
                                                <p class="mb-1 body-2">{{formatRupiah(order.fee_ehac)}}</p>
                                            </v-col>
                                          </v-row>-->
                                      </v-row>
                                      <v-row v-if="order.schedules">
                                          <v-col cols="12">
                                              <v-divider/>
                                          </v-col>
                                      </v-row>
                                      <v-row v-if="order.schedules" no-gutters class="mb-3">
                                          <v-col class="mb-0 pb-0" cols="12">
                                              <p class="body-2 font-weight-bold mb-1">JADWAL PENGAMBILAN SAMPLE KHUSUS</p>
                                          </v-col>
                                      </v-row>
                                      <v-row v-if="!_.isEmpty(order.schedules)" v-for="(schedule,kschedule) in order.schedules" :key="kschedule" no-gutters class="mb-3">
                                          <v-col class="pb-0" cols="4">
                                              <p class="caption grey--text mb-0">Pemeriksaan</p>
                                              <p class="mb-0 body-2 font-weight-bold">{{schedule.test_name}}</p>
                                          </v-col>
                                          <v-col class="pb-0  text-right" cols="8">
                                              <p class="caption grey--text mb-0">Waktu</p>
                                              <p class="mb-0 body-2 font-weight-bold">{{schedule.schedule_date}} {{schedule.schedule_time}}</p>
                                          </v-col>
                                      </v-row>
                                      <v-divider/>
                                  </v-card-text>
                              </div>
                          </v-expand-transition>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-if="list_header.app_version == '3.0'" v-for="(order,k_i) in data_order" :key="k_i">
                    <v-col cols="12">
                        <v-card elevation="10">
                            
                            <v-list-item three-line>
                                <v-list-item-content>
                                  <p class="caption mb-1">Kode Booking</p>
                                  <kbd class="mb-2">
                                     {{order.qrcode}}
                                  </kbd>
                                  <v-list-item-subtitle><p class="mb-0 caption">{{order.order_date}} {{order.order_time}}</p></v-list-item-subtitle>
                                  <v-list-item-title class="title mb-1">
                                    {{order.patient_name}}
                                  </v-list-item-title>
                                  <v-list-item-subtitle>{{order.id_number}}</v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-avatar
                                  tile
                                  size="80"
                                  color="grey"
                                ><QRCanvas :options="{cellSize: 4,correctLevel: 'H',data: order.qrcode}"/></v-list-item-avatar>
                              </v-list-item>

                              <v-card-actions>
                                <v-btn
                                  text
                                  @click="openDetail(order,k_i)"
                                >
                                  <p v-if="order.xshow == 'N'" class="mb-0">LIHAT DETAIL</p>
                                    <p v-if="order.xshow == 'Y'" class="mb-0">TUTUP DETAIL</p>
                                </v-btn>
                              </v-card-actions>
                            <v-expand-transition>
                              <div  v-if="order.xshow == 'Y'">
                                  <v-divider></v-divider>
                                  <v-card-text>
                                      <v-row no-gutters>
                                          <v-col class="pb-0" cols="6">
                                              <p class="caption grey--text mb-0">Status</p>
                                              <p class="mb-0 body-2 font-weight-bold">Pesanan Berhasil</p>
                                          </v-col>
                                          <v-col class="pb-0 text-right" cols="6">
                                              <p class="mb-0 caption grey--text">Tanggal Transaksi</p>
                                              <p class="mb-0 body-2  font-weight-bold">{{order.order_created}}</p>
                                          </v-col>
                                      </v-row>
                                      <v-row>
                                          <v-col cols="12">
                                              <v-divider/>
                                          </v-col>
                                      </v-row>
                                      <v-row no-gutters class="mb-3">
                                          <v-col class="pb-0" cols="6">
                                              <p class="caption grey--text mb-0">Cabang</p>
                                              <p class="mb-0 body-2 font-weight-bold">{{list_header.branch_name}}</p>
                                          </v-col>
                                          <v-col class="pb-0  text-right" cols="6">
                                              <p class="caption grey--text mb-0">Waktu Kunjungan</p>
                                              <p class="mb-0 body-2 font-weight-bold">{{order.order_date}} {{order.order_time}}</p>
                                          </v-col>
                                      </v-row>
                                      <v-row>
                                          <v-col cols="12">
                                              <v-divider/>
                                          </v-col>
                                      </v-row>
                                      <v-row no-gutters >
                                          <v-col class="mb-0 pb-0" cols="12">
                                              <p class="body-2 font-weight-bold mb-1">RINCIAN PEMERIKSAAN</p>
                                          </v-col>
                                          <v-col cols="12">
                                              <v-row no-gutters v-for="(detail,kdetail) in order.details" :key="kdetail">
                                                  <v-col cols="12">
                                                      <p class="mb-1 body-2">1 x {{detail.test_name}}</p>
                                                  </v-col>
                                                  <!--<v-col class="text-right" cols="6">
                                                      <p class="mb-1 body-2">{{detail.total}}</p>
                                                  </v-col>-->
                                              </v-row>
                                          </v-col>
                                          <!--<v-row no-gutters v-if="parseInt(order.fee_ehac) > 0">
                                            <v-col cols="6">
                                                <p class="mb-1 body-2">Layanan eHAC</p>
                                            </v-col>
                                            <v-col class="text-right" cols="6">
                                                <p class="mb-1 body-2">{{formatRupiah(order.fee_ehac)}}</p>
                                            </v-col>
                                          </v-row>-->
                                      </v-row>
                                      <v-row v-if="order.schedules">
                                          <v-col cols="12">
                                              <v-divider/>
                                          </v-col>
                                      </v-row>
                                      <v-row v-if="order.schedules" no-gutters class="mb-3">
                                          <v-col class="mb-0 pb-0" cols="12">
                                              <p class="body-2 font-weight-bold mb-1">JADWAL PENGAMBILAN SAMPLE KHUSUS</p>
                                          </v-col>
                                      </v-row>
                                      <v-row v-if="!_.isEmpty(order.schedules)" v-for="(schedule,kschedule) in order.schedules" :key="kschedule" no-gutters class="mb-3">
                                          <v-col class="pb-0" cols="4">
                                              <p class="caption grey--text mb-0">Pemeriksaan</p>
                                              <p class="mb-0 body-2 font-weight-bold">{{schedule.test_name}}</p>
                                          </v-col>
                                          <v-col class="pb-0  text-right" cols="8">
                                              <p class="caption grey--text mb-0">Waktu</p>
                                              <p class="mb-0 body-2 font-weight-bold">{{schedule.schedule_date}} {{schedule.schedule_time}}</p>
                                          </v-col>
                                      </v-row>
                                      <v-row v-if="order.requirements && order.requirements.length > 0">
                                          <v-col cols="12">
                                              <v-divider/>
                                          </v-col>
                                      </v-row>
                                      <v-row v-if="order.requirements && order.requirements.length > 0" no-gutters class="mb-1">
                                          <v-col class="mb-0 pb-0" cols="12">
                                              <p class="body-2 font-weight-bold mb-1">PERSIAPAN SEBELUM PEMERIKSAAN</p>
                                          </v-col>
                                      </v-row>
                                      <v-row v-if="order.requirements && order.requirements.length > 0"  no-gutters class="mb-3">
                                          <v-col class="pt-0 pb-0" cols="12">
                                               
                                                <ul class="pl-0">
                                                    <li v-for="(requirement,krequirement) in order.requirements" :key="krequirement">{{requirement}}</li>
                                                </ul>
                                          </v-col>
                                      </v-row>
                                      <v-divider/>
                                  </v-card-text>
                              </div>
                          </v-expand-transition>
                        </v-card>
                    </v-col>
                </v-row>
                
            </v-card-text>
  </v-card>
  <v-card class="mt-16 ml-1 mr-1" v-if=" list_header.payment_status !== 'Y'">
            <v-toolbar
             color="red lighten-1"
              dark
              elevation="0"
            >RINCIAN PEMESANAN</v-toolbar>
            <v-card-text>
                <v-row v-if="list_header" class="mb-0 mt-0" v-for="(patient,kpatient) in data_order" :key="kpatient">
                     <v-col cols="12">
                         <v-card>
                        <v-list-item three-line>
                            <v-list-item-content>
                                <p class="caption mb-1">Kode Booking</p>
                                <kbd class="mb-2">
                                    {{patient.qrcode}}
                                </kbd>
                                <v-list-item-subtitle><p class="mb-0 caption">{{patient.order_date}} {{patient.order_time}}</p></v-list-item-subtitle>
                                <v-list-item-title class="title mb-1">
                                {{patient.patient_name}}
                                </v-list-item-title>
                                <v-list-item-subtitle>{{patient.id_number}}</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-avatar
                                tile
                                size="80"
                                color="grey"
                            ><QRCanvas :options="{cellSize: 4,correctLevel: 'H',data: patient.qrcode}"/></v-list-item-avatar>
                        </v-list-item>
                         </v-card>
                    </v-col>
                </v-row>
                <v-divider class="mt-2 mb-2"></v-divider>
              <v-row v-if="list_header" class="mb-1 mt-1" v-for="(patient,kpatient) in data_order" :key="kpatient">
                    
                    <v-col class="pb-1 pt-1" cols="12">
                        <p class="mb-0 body-2 font-weight-bold">{{patient.patient_name}}</p>
                        <v-row v-if="patient.requirements && patient.requirements.length > 0">
                            <v-col cols="12">
                                
                                <v-card outlined class="pa-2">
                                    <p class="body-2 font-weight-bold mb-1">Persiapan sebelum pemeriksaan :</p>
                                    <ul class="pl-0">
                                        <li v-for="(requirement,krequirement) in patient.requirements" :key="krequirement">{{requirement}}</li>
                                    </ul>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row v-for="(detail, kdetail) in patient.details" no-gutters>
                            <v-col class="pa-1 pl-0" cols="8">
                                <p class="mb-0 body-2">{{detail.test_name}}</p>
                            </v-col>
                            <v-col class="pa-1 pl-0" cols="4">
                                <p class="mb-0 body-2  text-right">{{detail.total}}</p>
                            </v-col>
                        </v-row>
                        <v-row  v-if="patient.fee_ehac !== '0'" no-gutters>
                            <v-col class="pa-1 pl-0" cols="4">
                                <p class="mb-0 body-2">Biaya eHAC</p>
                            </v-col>
                            <v-col class="pa-1 pl-0" cols="8">
                                <p class="mb-0 body-2 text-right">{{formatRupiah(patient.fee_ehac)}}</p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row  class="mb-1 mt-1">
                    <v-col class="pb-1 pt-1" cols="12">
                        <v-row  no-gutters>
                            <v-col class="pa-1 pl-0" cols="6">
                                <p class="mb-0 body-2">Biaya Layanan</p>
                            </v-col>
                            <v-col class="pa-1 pl-0" cols="6">
                                <p class="mb-0 body-2 text-right">{{list_header.fee}}</p>
                            </v-col>
                        </v-row>
                        <v-row v-if="parseInt(list_header.hs_cost) !== 0" no-gutters>
                            <v-col class="pa-1 pl-0" cols="4">
                                <p class="mb-0 body-12 ">Biaya Home Service</p>
                            </v-col>
                            <v-col class="pa-1 pl-0" cols="8">
                                <p class="mb-0 body-2 text-right">{{formatRupiah(list_header.hs_cost)}}</p>
                            </v-col>
                        </v-row>
                        <v-row v-if="parseInt(list_header.hs_cost) !== 0" no-gutters>
                            <v-col class="pa-1 pl-0" cols="4">
                                <p class="mb-0 body-2 ">Biaya Minimal Transaksi</p>
                            </v-col>
                            <v-col class="pa-1 pl-0" cols="8">
                                <p class="mb-0 body-2 text-right">{{formatRupiah(list_header.hs_costminorder)}}</p>
                            </v-col>
                        </v-row>
                        <v-row  no-gutters>
                            <v-col class="pa-1 pl-0" cols="4">
                                <p class="mb-0 body-1 font-weight-black">TOTAL</p>
                            </v-col>
                            <v-col class="pa-1 pl-0" cols="8">
                                <p class="mb-0 body-1 font-weight-black text-right">{{list_header.total}}</p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
           
          </v-card>
 <!--- <v-card>
      <v-row v-if="list_header.payment_status !== 'Y' && list_header.status == 'pending' && parseInt(list_header.local_pay) == 0" style="width:100%;background:#fff" class="box-center-detail-order" justify="center">
                      <v-col cols="12"  class="text-center mb-2">
                          <v-card flat >
                              <v-row no-gutters>
                                  <v-col class="text-left" cols="6">
                                      <p class="mb-0 body-2 grey--text">TOTAL</p>
                                      <p class="mb-0 body-1 font-weight-bold">{{list_header.total}}</p>
                                  </v-col>
                                  <v-col class="text-right pt-1" cols="6">
                                          <v-btn
                                              rounded
                                              color="red lighten-1"
                                              dark
                                              block
                                              @click="goBayar()"
                                          >
                                          Bayar
                                          </v-btn>
                                  </v-col>
                              </v-row>
                          </v-card>
                      </v-col>
                  </v-row>
  </v-card>-->
  </div>
                
</template>
<style>




.box-center-detail-order{
        position: fixed;
        left: 50%;
        bottom: -52px;
        transform: translate(-50%, -50%);
        margin: 0 auto;
        box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12) !important;
    }
@media only screen and (min-device-width : 1024px){
/* Styles */
/* width */
::-webkit-scrollbar {
  height: 8px;              /* height of horizontal scrollbar ← You're missing this */
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
}

</style>
<script>
import { QRCanvas } from 'qrcanvas-vue';

  export default {
    name: 'xdetailorder',
    components: {
      QRCanvas
    },
    mounted(){
      this.$store.commit("beranda/update_dialog_loading",true)
      this.$store.dispatch("order/getDataOrderList",this.$route.params)

      if(this.$vuetify.breakpoint.name == 'xs'){
        this.window_width = window.innerWidth
      }
      else
        this.window_width = '560px'

    },
    computed:{
      list_header:{
        get() {
            return this.$store.state.order.list_header
                
        },
        set(val) {
              this.$store.commit("order/update_list_header",val)
        }
      },
      list_payment:{
        get() {
            return this.$store.state.order.list_payment
                
        },
        set(val) {
              this.$store.commit("order/update_list_payment",val)
        }
      },
      user:{
        get() {
            return this.$store.state.beranda.user
                
        },
        set(val) {
              this.$store.commit("beranda/update_user",val)
        }
      },
      opt_qrcode:{
        get() {
            return this.$store.state.order.opt_qrcode
                
        },
        set(val) {
              this.$store.commit("order/update_opt_qrcode",val)
        }
      },
      data_order:{
        get() {
            return this.$store.state.order.data_order
                
        },
        set(val) {
              this.$store.commit("order/update_data_order",val)
        }
      },
      lat:{
        get() {
            return this.$store.state.beranda.lat
                
        },
        set(val) {
              this.$store.commit("beranda/update_lat",val)
        }
      },
      lng:{
        get() {
            return this.$store.state.beranda.lng
                
        },
        set(val) {
              this.$store.commit("beranda/update_lng",val)
        }
      },
      window_width:{
        get() {
            return this.$store.state.beranda.window_width
                
        },
        set(val) {
              this.$store.commit("beranda/update_window_width",val)
        }
      },
      date_myformat(){
        if(!_.isEmpty(this.$store.state.order.data_order)){
          var d = new Date(this.$store.state.order.data_order.order_date);
        var weekday = new Array(7);
        weekday[0] = "Minggu";
        weekday[1] = "Senin";
        weekday[2] = "Selasa";
        weekday[3] = "Rabu";
        weekday[4] = "Kamis";
        weekday[5] = "Jumat";
        weekday[6] = "Sabtu";

        var n = weekday[d.getDay()];
        //console.log(this.localstorage.order.selected_date)
        return n+', '+this.$moment(d).format('DD-MM-YYYY') 
        }
        else
        return 'dd-mm-yyyy'
        
      }
    },
    methods:{
      formatRupiah(angka, prefix) {
                var number_string = angka
                        .replace(/[^,\d]/g, '')
                        .toString(),
                    sisa = number_string.length % 3,
                    rupiah = number_string.substr(0, sisa),
                    ribuan = number_string
                        .substr(sisa)
                        .match(/\d{3}/gi);

                // tambahkan titik jika yang di input sudah menjadi angka satuan ribuan
                if (ribuan) {
                    let separator = sisa
                        ? '.'
                        : '';
                    rupiah += separator + ribuan.join('.');
                }

                return 'Rp' + rupiah
            },
      goBayar(){
        if(this.list_header.payment_status === 'N')
          this.$router.push({ path: `/payment/id/${this.list_header.id}`})
        else
          this.$router.push({ path: `/paymentva/id/${this.list_header.order_number}/code/${this.list_payment.payment_code}`})
      },
      openDetail(order,i){
            var xdata = this.data_order
            xdata[i]['xshow'] = order.xshow == 'N' ? 'Y':'N'
           // console.log( xdata[i]['show'])
            //this.data_order = xdata
            this.$store.commit("order/update_data_order", xdata)
          },
        goBack(){
          this.$router.push({name:'xlistorder'})
        },
        getWIdth(){
          switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 320
            case 'sm': return 325
            case 'md': return 363
            case 'lg': return 363
          }
        }
    },
    data: () => ({
      show:true,
      dialog_rincian:false
    }),
  }
</script>
