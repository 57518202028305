<template>
    <div>
        <v-bottom-sheet
            v-model="sheet_delete"
            inset
            max-width="350px"
            height="200px"
            >
        <v-sheet
            class="text-center rounded"
            
        >
            
            <div class="ma-4">
                <v-card  flat>
                    <v-card-text class="text-center">
                        <p class="mb-1">Yakin akan menghapus alamat <span style="font-family: 'Fraunces', serif;font-size:14px;line-height:1.2rem" class="font-weight-bold">{{selected_patient.label}}</span> ?</p>
                        <p>{{selected_patient.address}}</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-row>
                            
                             <v-col cols="6">
                                <v-btn
                                block
                            outlined
                            color="grey"
                
                            @click="sheet_delete = !sheet_delete"
                            >
                            Batal
                        </v-btn>
                            </v-col>
                            <v-col cols="6">
                                <v-btn
                            block
                            color="red lighten-1"
                            dark
                            @click="deletePermanent()"
                            >
                            Yakin
                        </v-btn>
                            </v-col>
                        </v-row>
                        
              
                    </v-card-actions>
                </v-card>
            </div>
        </v-sheet>
        </v-bottom-sheet>
        
        <v-card
            class="mx-auto"
            flat
        >
            <v-toolbar
            color="red lighten-1"
            tile
            dark
            >
                <v-icon style="cursor:pointer" @click="goBack()">mdi-arrow-left</v-icon>
                <v-spacer></v-spacer>

                <!--<v-btn @click="openForm('new')" color="warning lighten-2"  class="black--text  pr-5">
                    <v-icon color="black" class="pr-2">mdi-plus</v-icon> Tambah Pasien
                </v-btn>-->
            </v-toolbar>
            <v-list
            two-line
            subheader
            >
                <v-subheader>Data Alamat HS</v-subheader>
                <p class="caption text-center mt-5" v-if="adresses.length === 0">Belum ada data</p>
                <v-list-item v-if="adresses && adresses.length > 0" v-for="(address,kp) in adresses" :key="kp">
                    <v-list-item-content>
                    <v-list-item-title>{{address.label}}</v-list-item-title>
                    <v-list-item-subtitle>{{address.address}}</v-list-item-subtitle>
                    <v-list-item-subtitle class="warning--text"><p class="">{{address.detail}}</p></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-btn @click="deleteAddess(address)" icon>
                            <v-icon>fa-trash-alt</v-icon> 
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-card>
    </div>   
</template>
<style>

.fixed-bottom-bg-no-shadow{
        position: fixed;
        left: 50%;
        bottom: -46px;
        transform: translate(-50%, -50%);
        margin: 0 auto;
      
    }
.box-center{
  position: fixed;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}
@media only screen and (min-device-width : 1024px){
/* Styles */
/* width */
::-webkit-scrollbar {
  height: 8px;              /* height of horizontal scrollbar ← You're missing this */
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
}

</style>
<script>


  export default {
    name: 'xlistaddresshs',
    mounted(){
       var user = JSON.parse(localStorage.getItem("user"))
       this.$store.dispatch("patient/getAddressesHS",user)
       
       if(this.$vuetify.breakpoint.name == 'xs'){
         this.window_width = window.innerWidth
       }
       else
        this.window_width = '560px'

    },
    computed:{
        hp:{
         get() {
             return this.$store.state.order.hp
                
         },
         set(val) {
             var hp = val.replace('+','')
                var pref_hp = hp.substring(0,2).replace('62',0)
                var new_hp = pref_hp+hp.slice(2)
            this.$store.commit("order/update_hp",new_hp)
         }
      },
        titles(){
            if(!this.sex || this.sex === ''){
                return []
            }
            else{
                var titles = this.$store.state.order.titles
                var sex = this.sex
                var xvalue = _.filter(titles, { 'sex': sex })
                
                return xvalue
            }
        },
        selected_title:{
         get() {
             return this.$store.state.order.selected_title
                
         },
         set(val) {

            this.$store.commit("order/update_selected_title",val)
         }
      },
        computedDateFormatted () {
        return this.formatDate(this.date)
      },
      tgl:{
         get() {
             return this.$store.state.patient.tgl
                
         },
         set(val) {

            this.$store.commit("patient/update_tgl",val)
         }
      },
      bln:{
         get() {
             return this.$store.state.patient.bln
                
         },
         set(val) {
  
            this.$store.commit("patient/update_bln",val)
         }
      },
      thn:{
         get() {
             return this.$store.state.patient.thn
                
         },
         set(val) {
             //var newval = val.toString().padStart(2, "0")
               this.$store.commit("patient/update_thn",val)
         }
      },
      date_myformat(){
        var d = new Date(this.localstorage.order.selected_date);
        var weekday = new Array(7);
        weekday[0] = "Minggu";
        weekday[1] = "Senin";
        weekday[2] = "Selasa";
        weekday[3] = "Rabu";
        weekday[4] = "Kamis";
        weekday[5] = "Jumat";
        weekday[6] = "Sabtu";

        var n = weekday[d.getDay()];
        //console.log(this.localstorage.order.selected_date)
        return n 
      },
        errors:{
            get() {
                return this.$store.state.order.errors
                    
            },
            set(val) {
                this.$store.commit("order/update_errors",val)
            }
        },
        dialog_form:{
         get() {
             return this.$store.state.order.dialog_form
                
         },
         set(val) {
               this.$store.commit("order/update_dialog_form",val)
         }
      },
      ids:{
         get() {
             return this.$store.state.order.ids
                
         },
         set(val) {
               this.$store.commit("order/update_ids",val)
         }
      },
       selected_id:{
         get() {
             return this.$store.state.order.selected_id
                
         },
         set(val) {
               this.$store.commit("order/update_selected_id",val)
         }
      },
      pramitaid:{
         get() {
             return this.$store.state.order.pramitaid
                
         },
         set(val) {
               this.$store.commit("order/update_pramitaid",val)
         }
      },
       address:{
         get() {
             return this.$store.state.order.address
                
         },
         set(val) {
               this.$store.commit("order/update_address",val)
         }
      },
      ktp:{
         get() {
             return this.$store.state.order.ktp
                
         },
         set(val) {
               this.$store.commit("order/update_ktp",val)
         }
      },
      name:{
         get() {
             return this.$store.state.order.name
                
         },
         set(val) {
               this.$store.commit("order/update_name",val)
         }
      },
      job:{
         get() {
             return this.$store.state.order.job
                
         },
         set(val) {
               this.$store.commit("order/update_job",val)
         }
      },
     sex:{
         get() {
             return this.$store.state.order.sex
                
         },
         set(val) {
               this.$store.commit("order/update_sex",val)
               this.selected_title = {}
         }
      },
      dob:{
         get() {
             return this.$store.state.order.dob
                
         },
         set(val) {
               this.$store.commit("order/update_dob",val)
         }
      },
      kelurahans:{
         get() {
             return this.$store.state.order.kelurahans
                
         },
         set(val) {
               this.$store.commit("order/update_kelurahans",val)
         }
      },
      selected_kelurahan:{
         get() {
             return this.$store.state.order.selected_kelurahan
                
         },
         set(val) {
               this.$store.commit("order/update_selected_kelurahan",val)
         }
      },
      districts:{
         get() {
             return this.$store.state.order.districts
                
         },
         set(val) {
               this.$store.commit("order/update_districts",val)
         }
      },
      selected_district:{
         get() {
             return this.$store.state.order.selected_district
                
         },
         set(val) {
               this.$store.commit("order/update_selected_district",val)
               this.$store.dispatch("order/getKelurahans", val)
         }
      },
      cities:{
         get() {
             return this.$store.state.order.cities
                
         },
         set(val) {
               this.$store.commit("order/update_cities",val)
         }
      },
      selected_city:{
         get() {
             return this.$store.state.order.selected_city
                
         },
         set(val) {
               this.$store.commit("order/update_selected_city",val)
               this.$store.dispatch("order/getDistricts", val)
         }
      },
      provinces:{
         get() {
             return this.$store.state.order.provinces
                
         },
         set(val) {
               this.$store.commit("order/update_provinces",val)
         }
      },
      selected_province:{
         get() {
             return this.$store.state.order.selected_province
                
         },
         set(val) {
               this.$store.commit("order/update_selected_province",val)
               this.$store.dispatch("order/getCities",val)
         }
      },
      user:{
        get() {
            return this.$store.state.beranda.user
                
        },
        set(val) {
              this.$store.commit("beranda/update_user",val)
        }
      },
      selected_patient:{
        get() {
            return this.$store.state.patient.selected_patient
                
        },
        set(val) {
              this.$store.commit("patient/update_selected_patient",val)
        }
      },
      adresses:{
        get() {
            return this.$store.state.patient.adressesHS
                
        },
        set(val) {
              this.$store.commit("patient/update_adressesHS",val)
        }
      },
      window_width:{
        get() {
            return this.$store.state.beranda.window_width
                
        },
        set(val) {
              this.$store.commit("beranda/update_window_width",val)
        }
      },
    },
    methods:{
        goBack(){

          this.$router.push({name:'xmenu'})
 
        },
        deleteAddess(address){
            this.selected_patient = address
            this.sheet_delete = true
        },
        deletePermanent(){
            this.sheet_delete = false
            this.$store.commit("beranda/update_dialog_loading",true)
            this.$store.dispatch("patient/deleteAddressHS",this.selected_patient)
        },
        changeTgl(event){
            //var val = event.target.value.toString().padStart(2, "0")
            this.tgl = event.target.value
            var dob = this.tgl+'-'+this.bln+'-'+this.thn
            var check_dob = this.$moment(dob, 'DD-MM-YYYY', true).isValid()
            this.error_dob = false
            if(!check_dob)
                this.error_dob = true
           
        },
        changeBln(event){
            this.bln = event.target.value
            var dob = this.tgl+'-'+this.bln+'-'+this.thn
            var check_dob = this.$moment(dob, 'DD-MM-YYYY', true).isValid()
            this.error_dob = false
            if(!check_dob)
                this.error_dob = true
        },
        changeThn(event){
            this.thn = event.target.value
            var dob = this.tgl+'-'+this.bln+'-'+this.thn
            var check_dob = this.$moment(dob, 'DD-MM-YYYY', true).isValid()
            this.error_dob = false
            this.error_dobyear = false
            var xYear = new Date().getFullYear()
            if(parseInt(this.thn) > parseInt(xYear)){
                check_dob = false
                this.error_dobyear = true
            }
            if(!check_dob)
                this.error_dob = true
        },
        checkIdentity(){
          var ktp = this.ktp
          this.error_ktp = false
          this.error_ktp_msg = ''
          if(parseInt(this.selected_id.id) === 1 ){
            if(ktp.length < 16){
              this.error_ktp = true
              this.error_ktp_msg = 'KTP tidak valid'
            }
      
            if(isNaN(ktp)){
              //console.log('dasds')
              this.error_ktp = true
              this.error_ktp_msg = 'Hanya numerik'
            }
          }

          if(parseInt(this.selected_id.id) === 3 ){
            if(ktp.length == 0){
              this.error_ktp = true
              this.error_ktp_msg = 'Passport tidak valid'
            }
          }
        },
        goToHome(){
          this.$router.push({name:'xberanda'})
        },
        login(act){
          if(act === 'daftar'){
            this.$router.push({name:'xlogin',params:{act:act}})
          }
          else
          this.$router.push({name:'xlogin'})
        },
        getWIdth(){
          switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 320
            case 'sm': return 325
            case 'md': return 363
            case 'lg': return 363
          }
        },
        listorder(){
          this.$router.push({ name: 'xlistorder'})
        },
        logout(){
          var user =  JSON.parse(localStorage.getItem("user"))
          user.lat = this.$store.state.beranda.lat
          user.lng = this.$store.state.beranda.lng
          this.$store.dispatch("login/logout", user)
        },
        changeDOB(dob_inp){
            if(dob_inp == 'tgl'){
                var val_now = this.tgl.length
            }
        },
        openForm(act){
          if(act === 'new'){
             this.$store.commit("order/update_patient_id",0)
             this.tgl = ''
             this.bln = ''
             this.thn = ''
             this.ktp = ''
             this.name = ''
             this.sex = ''
             this.dob = ''
             this.hp = ''
             this.job = ''
             this.pramitaid = ''
             this.address = ''
             this.selected_province = {}
             this.selected_city = {}
             this.selected_district = {}
             this.selected_kelurahan = {}
             this.selected_id = {}
            this.selected_title = {}
             this.dialog_form = true

          }
        
        },
        openFormEdit(patient){
            this.selected_patient = patient
            this.$store.commit("order/update_patient_id",this.selected_patient.id)
            this.tgl = this.$moment(this.selected_patient.dob).format('DD')
            this.bln = this.$moment(this.selected_patient.dob).format('MM')
            this.thn = this.$moment(this.selected_patient.dob).format('YYYY')
            this.ktp = this.selected_patient.ktp
            this.name = this.selected_patient.name
            this.sex = this.selected_patient.sex_code
            this.dob = this.selected_patient.dob
            this.job = this.selected_patient.job
            this.pramitaid = this.selected_patient.pramitaid
            this.address = this.selected_patient.address
            this.selected_province = {id:this.selected_patient.province_id,name:this.selected_patient.province_name}
            this.provinces = [this.selected_province]
            this.selected_city = {id:this.selected_patient.city_id,name:this.selected_patient.city_name}
            this.selected_district = {id:this.selected_patient.district_id,name:this.selected_patient.district_name}
            this.selected_kelurahan = {id:this.selected_patient.kelurahan_id,name:this.selected_patient.kelurahan_name}
            this.selected_id = {id:this.selected_patient.id_type,name:this.selected_patient.id_name}
            this.dialog_form = true
        },
        savePatient(){
            var error = false
            this.error_dob = false
            this.error_dobyear = false
            var dob = this.tgl+'-'+this.bln+'-'+this.thn
            console.log(dob)
            var check_dob = this.$moment(dob, 'DD-MM-YYYY', true).isValid()
            console.log(check_dob)
            if(!check_dob)
                this.error_dob = true
            var xYear = new Date().getFullYear()
                if(parseInt(this.thn) > parseInt(xYear)){
                    check_dob = false
                    this.error_dobyear = true
                }

             this.checkIdentity()
          if(this.$refs.form.validate()  && check_dob && !this.error_ktp){
              var patient = {
              act:this.act,
              idtype:this.selected_id,
              patient_id:this.$store.state.order.patient_id,
              ktp:this.ktp,
              title:this.selected_title,
              name:this.name,
              sex:this.sex,
              dob:this.thn+'-'+this.bln+'-'+this.tgl,
              job:this.job,
              pramitaid:this.pramitaid,
              address:this.address,
              province:this.selected_province,
              city:this.selected_city,
              district:this.selected_district,
              kelurahan:this.selected_kelurahan,
              hp:this.hp
            }
            this.$store.commit("beranda/update_dialog_loading",true)
            this.$store.dispatch("patient/savePatient",patient)
          }
          
        },
        validate () {
          this.$refs.form.validate()
        },
        reset () {
          this.$refs.form.reset()
        },
        resetValidation () {
          this.$refs.form.resetValidation()
        },
        formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}-${month}-${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [ day,month, year] = date.split('-')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      clicktext(i,k,destination){
        var deliveries = this.deliveries
        if(destination.destination !== '' )
          deliveries[i].destinations[k].status = destination.status == 'Y'?'N':'Y'
        
        this.deliveries = deliveries
      },
      checkComplete(){
        console.log("alooooo")
        var selected_patient = this.selected_patient
        var selected_test_purpose = this.selected_test_purpose
        var deliveries = this.deliveries
        var errors = []
        if(selected_patient.length == 0){
            errors.push("Belum ada pasien yang dipilih")
        }
        if(selected_patient.length !== 0 && selected_patient.length < parseInt(this.localstorage.order.patient_number.id)){
            errors.push("Silahkan pilih "+this.localstorage.order.patient_number.id+" pasien")
        }
        if(selected_patient.length > parseInt(this.localstorage.order.patient_number.id)){
            errors.push("Pesanan hanya untuk "+this.localstorage.order.patient_number.id+" pasien")
        }
        if(_.isEmpty(selected_test_purpose)){
            errors.push("Pilih salah satu tujuan pemeriksaan")
        }
        var check_deliveries = []
        deliveries.forEach(function(delivery) {
          //console.log(delivery)
          delivery.destinations.forEach(function(destination) {
            //console.log(destination)
            if(destination.status === 'Y')
              check_deliveries.push(destination)
          })
        })
        console.log(errors)
        if(check_deliveries.length == 0)
          errors.push("Belum ada pengiriman hasil yang dipilih")

        if(errors.length > 0){
          console.log(errors)
          this.errors = errors
          this.dialog_warning = true
        }
        else{
          this.$store.commit("beranda/update_dialog_loading",true)
          var prm = {}
          prm.branch = this.localstorage.choosebranch.selected_branch
          prm.subcategory = this.localstorage.choosebranch.selected_subcategory
          prm.test = this.localstorage.order.selected_test_varian
          prm.result_option = this.localstorage.order.selected_result_option
          prm.date = this.localstorage.order.date_sampling
          prm.time = this.localstorage.order.selected_time
          prm.patient = this.selected_patient
          prm.purpose = this.selected_test_purpose
          prm.is_klinisi = this.is_klinisi
          prm.deliveries = this.deliveries
          this.$store.dispatch("order/checkOut",prm)
        }
      }
    },
    watch: {
        
      date (val) {
        this.dateFormatted = this.formatDate(this.date)
      },
      tgl (val) {
        if(val.length == 2){
            this.$refs.input_bln.focus()
        }
      },
      bln (val) {
        if(val.length == 2){
            this.$refs.input_thn.focus()
        }
      },
    },
   data: vm => ({
       sheet_delete:false,
       search_province:'',
        search_city:'',
        search_district:'',
        search_kelurahan:'',
       error_dob:false,
       error_dobyear:false,
           error_ktp:false,
      error_ktp_msg:'',
      isLogin:false,
      isTrue:false,
      dialog_city:false,
      dialog_error:false,
      msg_error:'',
      reveal:false,
      dialog_branch:false,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      show: false,
      dialog_warning:false,
      search:'',
      modal: false,
      date:'',
      dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
      value:'',
      
      valid: true,
      rulesdob: [
        v => !!vm.$moment(v, 'DD-MM-YYYY', true).isValid() || 'Format salah, harusnya dd-mm-yyyy',
        v => !!v || 'Harus diisi'
      ],
      email: '',
    }),
  }
</script>
