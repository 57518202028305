<template>
    <div>
        <v-dialog
            v-model="dialog_kunjungan"
            width="500"
            persistent
            >

            <v-card>
            <v-card-title class="pt-2 pb-2 font-weight-bold body-2">
              <p v-if="selected_schedule_iskunjungan === 'Y'" class="body-2 font-weight-bold mb-1">PILIH WAKTU KUNJUNGAN </p>
              <p v-if="selected_schedule_iskunjungan !== 'Y'" class="body-2 font-weight-bold mb-1">PILIH JADWAL PEMERIKSAAN </p>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pt-2 pb-2">
              <v-row align="center">
                <v-col cols="12">
                    <v-date-picker
                        v-model="selected_date_dialog"
                        :min="this.selected_schedule_iskunjungan === 'Y' ?this.mindate:this.mindate_sampling"
                        :max="this.selected_schedule_iskunjungan === 'Y' ?this.maxdate:this.maxdate_sampling"
                        full-width
                        scrollable
                        show-current
                    ></v-date-picker>
                </v-col>
              </v-row>
              <v-row >
                <v-col>
                  <v-card outline flat rounded color="red-lighten-2">
                  <v-list>
                    <v-list-item-group
                      v-model="selected_time_dialog"
                    >
                      <template v-for="(item, i) in visittimes">
                        <v-list-item
                          :key="`item-${i}`"
                          :value="item"
                          class="ml-0 pl-0 pt-0 pb-0"
                          active-class="amber--text"
                        >
                          <template v-slot:default="{ active }">
                            <v-list-item-content class="ma-0 pa-0">
                              <v-list-item-title>
                                  <v-list-item two-line>
                                    <v-list-item-content class="ma-0 pa-0">
                                      <v-list-item-title>
                                        <span v-if="parseInt(item.kuota) > 0">{{item.name}}</span>
                                        <span class="text-decoration-line-through" v-if="parseInt(item.kuota) == 0">{{item.name}}</span>
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        <span v-if="parseInt(item.kuota) > 0">{{item.kuota}} Kuota tersisa</span>
                                        <span v-if="parseInt(item.kuota) == 0">Penuh</span>
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                              </v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action class="pr-0">
                              <v-checkbox
                                :input-value="active"
                                v-if="parseInt(item.kuota) > 0"
                                color="red accent-4"
                              ></v-checkbox>
                            </v-list-item-action>
                          </template>
                        </v-list-item>
                        <v-divider></v-divider>
                      </template>
                    </v-list-item-group>
                  </v-list>
                  </v-card>
                </v-col>
              </v-row>
              
            </v-card-text>
            <v-card-actions>
                <v-row>
                  <v-col class="text-right" cols="12">
                      <v-btn
                        color="red"
                        text
                        @click="dialog_kunjungan = false"
                    >
                        Batal
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click="closeDialogKunjungan"
                    >
                        Simpan
                    </v-btn>
                  </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
            </v-dialog>
        <v-app-bar color="white" fixed="fixed" elevation="1" height="59px">
            <v-list-item class="mx-auto pa-0">
                <v-list-item-avatar>
                    <v-icon style="cursor:pointer;color:#e0282c" @click="goBack()">mdi-arrow-left</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-subtitle class="font-weight-bold title">
                        <v-row no-gutters="no-gutters">
                            <v-col cols="8">
                                <div class="bold-poppins red-text-default font-weight-bold">Waktu Pengambilan Sample</div>
                            </v-col>
                        </v-row>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-app-bar>
        <v-card class="ma-2 mt-16 mb-0">
            <v-card-text>
                <v-row no-gutters>
                    <v-col cols="12">
                        <p class="mb-2 font-weight-black body-1">
                            {{xlocalstate.choosebranch.selected_branch.name}}
                        </p>
                    </v-col>
                </v-row>
            
                <v-row no-gutters>
                    <v-col class="pa-2 pl-0 mt-0 pt-0" cols="12">
                        <v-text-field
                            label="Tentukan Waktu Kunjungan"
                            placeholder="DD-MM-YYYY HH:II"
                            outlined
                            v-model="datetimevisit"
                            clearable
                            dense
                            @click="openDialogKunjungan('Y')"
                            hide-details="auto"
                        ></v-text-field>
                        <code v-if="datetimevisit  === ''">waktu kunjungan harus diisi</code>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card class="ma-2 pb-0 mb-16">
        <v-card-title class="body-1 pt-2 pb-2  red lighten-1 white--text">
          <div class="mt-2 body-1 mb-2 ">Detail Pemesanan <span v-if="order_hs" >Home Service</span></div>
        </v-card-title>

        <v-card-text class="pb-0 mb-1">
            <v-row class="pb-0" v-if="cart.length > 0" v-for="(xorderpatient,kxorpat) in cart" :key="kxorpat">
                <v-col cols="12">
                    <v-row no-gutters>
                        <v-col class="mt-2" cols="12">
                            <p class="mb-1 h6 font-weight-bold">{{xorderpatient.patient.name}}</p>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mb-2"  v-for="(order,korder) in xorderpatient.order" :key="korder">
                        <v-col cols="12">
                            <v-row no-gutters style="margin-bottom:4px">
                                <v-col cols="7">
                                    <p class="mb-1 light-poppins pt-0">{{order.name}}</p>
                                </v-col>
                                <v-col class="text-right light-poppins pt-0" cols="5">
                                    <p class="mb-1">{{formatRupiah(order.price)}}</p>
                                </v-col>
                            </v-row>
                            
                            <!---<v-divider v-if="order.schedule.length > 0"></v-divider>-->
                            <!----<p class="mt-2 mb-1 font-weight-bold" v-if="order.schedule.length > 0">PILIH WAKTU SAMPLING</p>-->
                            <!----<v-row v-if="order.schedule" v-for="(schedule,ksc) in order.schedule" :key="ksc" class="mb-2">
                                <v-col  cols="12">
                                    <v-text-field
                                        :disabled="datetimevisit === ''"
                                        hide-details="auto"
                                        :label="schedule.testname"
                                        v-model="schedule.datetime"
                                        placeholder="DD-MM-YYYY HH:II"
                                        outlined
                                        @click="openDialogSchedule(schedule,kxorpat,korder,ksc)"
                                        dense
                                        clearable
                                    ></v-text-field>
                                    <code v-if="schedule.datetime  === ''">jangan lupa diisi</code>
                                </v-col>
                            </v-row>-->
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mb-5">
                        <v-col class="pt-3" cols="7">
                            <p class="mb-1 semi-poppins">Total Pemeriksaan</p>
                        </v-col>
                        <v-col class="pt-3 text-right" cols="5">
                            <p class="mb-1 light-poppins font-weight-bold">{{getTotalPerPatient(xorderpatient.order)}}</p>
                        </v-col>
                    </v-row>
                    <v-card flat class="mb-1">
                        <v-row no-gutters>
                            <v-col class="pb-0 mb-1" cols="12">
                                <v-card :color="xorderpatient.is_klinisi== false ? '#525050':'#ff5252'" dark class="mb-1"> 
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                        <v-list-item-title class="mono font-weight-bold">Dengan rekomendasi dokter</v-list-item-title>
                                        <v-list-item-subtitle class="caption pt-2">*) aktifkan jika ada surat rekomendasi dari dokter</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action class="mr-0 pr-0" align="right">
                                        <v-switch
                                           v-model="xorderpatient.is_klinisi"
                                            inset
                                            ></v-switch>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row v-if="xorderpatient.is_covid" no-gutters>
                            <v-col class="pa-2 pl-2 pt-0 mt-2 pb-0 pr-2 mb-1" cols="12">
                                <v-select
                                    class="mb-0"
                                    v-model="xorderpatient.covid_purpose"
                                    :items="test_purpose"
                                    hide-selected
                                    item-text="name"
                                    clearable
                                    hide-details="auto"
                                    persistent-hint
                                    return-object
                                    label="Tujuan Pemeriksaan (COVID) *"
                                    outlined
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card flat> 
                        <v-row align="center" no-gutters>
                            <v-col class="mt-0" cols="12">
                                <v-card flat class="pa-3 pb-0">
                            
                                            <p class="body-1 mb-2">Pilih Pengiriman Hasil</p>
                                            <v-list-item class="pa-0" v-for="(delivery,kdelivery) in xorderpatient.deliveries" :key="kdelivery">
                                                <v-list-item-action>
                                                    <v-checkbox
                                                        v-model="delivery.selected"
                                                        @change="changeDeliveryCbx(delivery.selected,kdelivery,kxorpat)"
                                                        color="#ff5252"
                                                    ></v-checkbox>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title v-if="parseInt(delivery.M_DeliveryID) === 1">Ambil di {{delivery.xvalue}}</v-list-item-title>
                                                    <v-list-item-title v-if="parseInt(delivery.M_DeliveryID) !== 1">
                                                        <v-text-field
                                                            :disabled="!delivery.selected"
                                                            :label="delivery.M_DeliveryName"
                                                            v-model="delivery.xvalue"
                                                            @change="changeDeliveryValue(delivery.xvalue,kdelivery,kxorpat)"
                                                            outlined
                                                            class="ma-1 mb-1 ml-0"
                                                            hide-details
                                                            dense
                                                        ></v-text-field>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <p class="red--text caption mb-0">*) Hasil pemeriksaan pasien bersifat rahasia</p>
                                            <p class="red--text caption mb-0">Harap menggunakan email / whatsapp pasien bersangkutan</p>
                             
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
      </v-card>
        <v-snackbar
            top
            v-model="snackbar"
            timeout="5000"
        >
            {{this.xerror_msg}}
        </v-snackbar>
        <v-row v-if="!dialog_kunjungan" >
            <v-card class="fixed-bottom-schedule">
            <v-col
                cols="12"
                class="text-right mb-2 "
            > 
                <v-btn
                    block
                    @click="checkout()"
                    color="red lighten-1"
                    dark
                    style="width:100%;display:inline-block"
                >
                    Checkout
                </v-btn>
            </v-col>
            </v-card>
        </v-row>
    </div>

</template>
<style>
.poppins-bold{
  font-family: 'Poppins', sans-serif;
  font-weight:800;
}
.semi-poppins{
  font-family: 'Poppins', sans-serif;
  font-weight:600;
}
.poppins-light{
  font-family: 'Poppins', sans-serif;
  font-weight:300;
}

.red-text-default{
    color:#e0282c
}
    .fixed-bottom-schedule {
        position: fixed;
        left: 50%;
        width:100%;
        bottom:-40px;
        transform: translate(-50%, -50%);
        margin: 0 auto;
       box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2), 0 10px 14px 1px rgba(0, 0, 0, 0.14), 0 4px 18px 3px rgba(0, 0, 0, 0.12) !important;
    }
    .box-center-schedule {
        position: fixed;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, -50%);
        margin: 0 auto;
    }
    @media only screen and (min-device-width : 1024px) {
        /* Styles */
        /* width */
        ::-webkit-scrollbar {
            height: 8px;
            /* height of horizontal scrollbar ← You're missing this */
            width: 8px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #888;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
</style>
<script>
import moment from 'moment';
    export default {
        name: 'xsettingschedule',
        mounted() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            /*this
                .$store
                .commit("beranda/update_dialog_loading", true)*/
            var xlocalstate = JSON.parse(localStorage.getItem("oneState"))
            this.xlocalstate = xlocalstate
            this.cart = this.xlocalstate.test.cart
            console.log(this.cart)
            this.selected_branch = xlocalstate.choosebranch.selected_branch
            this.$store.commit("choosebranch/update_selected_hs_address",xlocalstate.choosebranch.selected_hs_address)
            this.$store.dispatch("test/getTestPurpose", this.selected_branch )
            //console.log(this.xlocalstate.test)
            if (this.$vuetify.breakpoint.name == 'xs') {
                this.window_width = window.innerWidth
            } else 
                this.window_width = '560px'

        },
        computed: {
            selected_branch: {
                get() {
                    return this.$store.state.choosebranch.selected_branch

                },
                set(val) {
                    this
                        .$store
                        .commit("choosebranch/update_selected_branch", val)
                }
            },
        order_hs:{
            get() {
                return this.$store.state.beranda.order_hs
                    
            },
            set(val) {
                this.$store.commit("beranda/update_order_hs",val)
            }
        },
        xerror_msg:{
            get() {
                return this.$store.state.test.xerror_msg
                    
            },
            set(val) {
                this.$store.commit("test/update_xerror_msg",val)
            }
        },
        test_purpose:{
            get() {
                return this.$store.state.test.test_purpose
                    
            },
            set(val) {
                this.$store.commit("test/update_test_purpose",val)
            }
        },
        is_klinisi:{
            get() {
                return this.$store.state.test.is_klinisi
                    
            },
            set(val) {
                this.$store.commit("test/update_is_klinisi",val)
            }
        },
        datetimevisit:{
            get() {
                if(this.datevisit && !_.isEmpty(this.timevisit))
                    return this.datevisit+' '+this.timevisit.name
                else
                    return ''  
            },
            set(val) {
               this.timevisit = {}
               this.datevisit = ''
            }
        },
        dialog_kunjungan:{
         get() {
             return this.$store.state.test.dialog_kunjungan
                
         },
         set(val) {
               this.$store.commit("test/update_dialog_kunjungan",val)
         }
        },
        selected_date_dialog:{
         get() {
             return this.$store.state.test.selected_date_dialog
                
         },
         set(val) {
               this.$store.commit("test/update_selected_date_dialog",val)
         }
        },
        selected_time_dialog:{
         get() {
             return this.$store.state.test.selected_time_dialog
                
         },
         set(val) {
               this.$store.commit("test/update_selected_time_dialog",val)
         }
        },
        visittimes:{
         get() {
             return this.$store.state.test.visittimeshs
                
         },
         set(val) {
               this.$store.commit("test/update_visittimeshs",val)
         }
      },
        days:{
         get() {
             return this.$store.state.order.days
                
         },
         set(val) {
               this.$store.commit("order/update_days",val)
         }
      },
            dates() {
                return this.days.map(day => day.date);
            },
            attributes() {
                return this.dates.map(date => ({
                highlight: true,
                dates: date,
                }))
            },
            datevisit: {
                get() {
                    return this.$store.state.test.datevisit

                },
                set(val) {

                    this
                        .$store
                        .commit("test/update_datevisit", val)
                }
            },
            timevisit: {
                get() {
                    return this.$store.state.test.timevisit

                },
                set(val) {

                    this
                        .$store
                        .commit("test/update_timevisit", val)
                }
            },
            cart: {
                get() {
                    return this.$store.state.test.cart

                },
                set(val) {

                    this
                        .$store
                        .commit("test/update_cart", val)
                }
            },
           xlocalstate: {
                get() {
                    return this.$store.state.test.xlocalstate

                },
                set(val) {
                    this
                        .$store
                        .commit("test/update_xlocalstate", val)
                }
            },
            list_header: {
                get() {
                    return this.$store.state.order.list_header

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_list_header", val)
                }
            },
            window_width: {
                get() {
                    return this.$store.state.beranda.window_width

                },
                set(val) {
                    this
                        .$store
                        .commit("beranda/update_window_width", val)
                }
            }
        },
        methods: {
            getTotalPerPatient(order){
                var total = 0 
                order.forEach(value => {
                    total = total + parseInt(value.price)
                });

                return this.formatRupiah(total)
            },
            changeDeliveryCbx(val,i,iparent){
                var cart = this.cart
                cart[iparent].deliveries[i].selected  = val
                this.cart = cart
            },
            changeDeliveryValue(val,i,iparent){
                var cart = this.cart
                cart[iparent].deliveries[i].xvalue  = val
                this.cart = cart
            },
            openDialogSchedule(value,kcart,korder,kschedule){
                //console.log(value,kcart,korder,kschedule)
                this.selected_schedule_iskunjungan = 'N'
                this.idx_cart_selected = kcart
                this.idx_order_selected = korder
                this.idx_schedule_selected = kschedule
                this.selected_date_dialog = this.mindate_sampling
                
                
                if(value.date !== ''){
                    let datesplit = value.date.split('-')
                    //console.log(moment(datesplit[2]+'-'+datesplit[1]+'-'+datesplit[0]).isValid())
                   this.selected_date_dialog = moment(datesplit[2]+'-'+datesplit[1]+'-'+datesplit[0]).format('YYYY-MM-DD')
                }
                this.selected_time_dialog = {}
                if(!_.isEmpty(value.time))
                    this.selected_time_dialog = value.time
                
                this.$store.commit("beranda/update_dialog_loading",true)
                var prm = this.cart[this.idx_cart_selected].order[this.idx_order_selected].schedule[this.idx_schedule_selected]
                    prm.old_date = ''
                    prm.new_date = this.selected_date_dialog
                    prm.id = this.xlocalstate.choosebranch.selected_branch.id
                    prm.time_visit = this.timevisit
                    prm.date_visit = this.datevisit
                    this
                        .$store
                        .dispatch("test/getTimesByDate", prm)

            },
            openDialogKunjungan(){
                this.selected_schedule_iskunjungan = 'Y'
                
                if(this.datevisit !== ''){
                    let datesplit = this.datevisit.split('-')
                    //console.log(moment(datesplit[2]+'-'+datesplit[1]+'-'+datesplit[0]).isValid())
                    this.selected_date_dialog = moment(datesplit[2]+'-'+datesplit[1]+'-'+datesplit[0]).format('YYYY-MM-DD')
                   // console.log(this.selected_date_dialog)
                }
                else{
                    this.selected_date_dialog = moment(new Date()).format('YYYY-MM-DD')
                }
                
                if(!_.isEmpty(this.timevisit)){
                    this.selected_time_dialog = this.timevisit
                }
                else{
                    this.selected_time_dialog = {}
                }
                    
                
                //console.log(this.datevisit)
                //this.dialog_kunjungan = true
                this.$store.commit("beranda/update_dialog_loading",true)
                var xprm =  this.xlocalstate.choosebranch.selected_branch
                xprm.selected_date = this.selected_date_dialog
                this.$store.dispatch("test/getTimesKunjunganHS", xprm)
            },
            closeDialogKunjungan(){
                if(this.selected_schedule_iskunjungan === 'Y'){
                    console.log(!_.isEmpty(this.selected_time_dialog))
                    if(this.selected_date_dialog && !_.isEmpty(this.selected_time_dialog)){
                        this.mindate_sampling = moment(this.selected_date_dialog).format('YYYY-MM-DD')
                        console.log(this.mindate_sampling)
                        this.datevisit = moment(this.selected_date_dialog).format('DD-MM-YYYY')
                        this.timevisit = this.selected_time_dialog
                    }
                }
                else{
                    if(this.selected_date_dialog && !_.isEmpty(this.selected_time_dialog)){
                        var cart = this.cart
                        var scx = cart[this.idx_cart_selected].order[this.idx_order_selected].schedule[this.idx_schedule_selected]
                        scx.time = this.selected_time_dialog
                        scx.date = moment(this.selected_date_dialog).format('DD-MM-YYYY')
                        scx.datetime = scx.date+' '+scx.time.name
                    }
                }
                
                this.dialog_kunjungan = false
            },
            onDayClick(day){
                const idx = this.days.findIndex(d => d.id === day.id);
                if (idx >= 0) {
                this.days.splice(idx, 1);
                } else {
                if(day.date >= new Date(Date.now() - 1 * 24 * 60 * 60 * 1000) && day.date <= new Date(Date.now() + 13 * 24 * 60 * 60 * 1000) ){
                    this.days = []
                    this.days.push({
                        id: day.id,
                        date: day.date,
                    })
                    this.selected_date_dialog = moment(day.date).format('DD-MM-YYYY')
                    //this.date_sampling = moment(day.date).format('YYYY-MM-DD')
                //console.log(day)
                }
            }
        },
            formatRupiah(angka, prefix) {
                var number_string = angka.toString(),
                    sisa = number_string.length % 3,
                    rupiah = number_string.substr(0, sisa),
                    ribuan = number_string
                        .substr(sisa)
                        .match(/\d{3}/gi);

                // tambahkan titik jika yang di input sudah menjadi angka satuan ribuan
                if (ribuan) {
                    let separator = sisa
                        ? '.'
                        : '';
                    rupiah += separator + ribuan.join('.');
                }

                return 'Rp' + rupiah
            },
            goBayar() {
                if (this.list_header.payment_status === 'N') 
                    this
                        .$router
                        .push({path: `/order/id/${this.list_header.id}`})
                else 
                    this
                        .$router
                        .push(
                            {path: `/payment/id/${this.list_header.order_number}/code/${this.list_payment.payment_code}`}
                        )
                },
            openDetail(order, i) {
                var xdata = this.data_order
                xdata[i]['xshow'] = order.xshow == 'N'
                    ? 'Y'
                    : 'N'
                // console.log( xdata[i]['show']) this.data_order = xdata
                this
                    .$store
                    .commit("order/update_data_order", xdata)
            },
            goBack() {

                    this.$router.push({name:'xcarths'})
            },
            getWIdth() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return 320
                    case 'sm':
                        return 325
                    case 'md':
                        return 363
                    case 'lg':
                        return 363
                }
            },
            checkout(){
                this.xerror = false
                var xerror_msg = ''
                if(this.datetimevisit === ''){
                    xerror_msg += '<p class="mb-1">Anda belum memilih waktu kunjungan</p>'
                }

                var carts = this.cart
                carts.forEach(function (cart) {
                    if(cart.is_covid && _.isEmpty(cart.covid_purpose)){
                        xerror_msg += '<p class="mb-1">Pilih Tujuan pemeriksaan (covid) <span class="font-weight-bold">'+cart.patient.M_PatientName+'</span></p>'
                    }
                    /*cart.order.forEach(function (order) {
                        order.schedule.forEach(function (xschedule) {
                            if(xschedule.datetime === ''){
                                xerror_msg += '<p class="mb-1">Pemeriksaan <span class="red--text">'+xschedule.testname+'</span> jadwal pengambilan sample belum dipilih <span class="text--blue font-weight-bold">'+cart.patient.M_PatientName+'</span></p>'
                            }
                        })
                    }*/
                    
                    var filter_check_delivery = _.filter(cart.deliveries, function(o) { return o.selected })
                    if(filter_check_delivery.length == 0){
                        xerror_msg += '<p class="mb-1">Pilih pengiriman hasil pasien <span class="font-weight-bold">'+cart.patient.M_PatientName+'</span></p>'
                    }
                })

                if(xerror_msg !== ''){
                    this.$store.commit("beranda/update_api_error_message",xerror_msg)
                }
                else{
                    this.$store.commit("beranda/update_dialog_loading",true)
                    let branch = this.xlocalstate.choosebranch.selected_branch
                    this.getDistance(branch)
                    
                }
            },
            getDistance(branch){
                console.log(branch)
                this.now_branch = branch
                var origin = {"lat":this.xlocalstate.choosebranch.selected_hs_address.lat*1, "lng":this.xlocalstate.choosebranch.selected_hs_address.lng*1};
                var destination = {"lat":branch.branch_lat*1, "lng":branch.branch_lng*1};
                this.$gmapApiPromiseLazy().then(() => {
                var service = new google.maps.DistanceMatrixService();
                service.getDistanceMatrix(
                {
                    origins: [origin],
                    destinations: [destination],
                    travelMode: "DRIVING",
                    avoidHighways: false,
                    avoidTolls: false
                }, this.callback);
                });
                

            },
            callback(response, status) {
                if (status != google.maps.DistanceMatrixStatus.OK) {
                    alert('Error was: ' + status);
                } else {
                    
                    console.log(response)
                    var totalDistance = response.rows[0].elements[0].distance.value;
                    var totalTime = response.rows[0].elements[0].duration.value;
                    var distance_km = Math.round((totalDistance/1000)  * 10) / 10
                    var randomstring = Math.floor(Date.now() / 1000)
                    this.$store.dispatch("test/checkout",{
                        is_klinisi : this.is_klinisi,
                        datevisit:this.datevisit,
                        timevisit:this.timevisit,
                        branch:this.xlocalstate.choosebranch.selected_branch,
                        code_dist:distance_km+'FHM'+randomstring,
                        cart:this.cart,
                        order_hs:this.xlocalstate.beranda.order_hs,
                        selected_hs_address:this.xlocalstate.choosebranch.selected_hs_address
                    })
                }
            },
        },
        data: () => ({
            now_branch:{},
            code_disc:0,
            hp:'087731453789',
            email:'sas.fajri@gmail.com',
            cbx_email:false,
            snackbar:false,
            xerror:false,
            show: true,
            localstorage:{},
            schedule_selected:{},
            idx_order_selected : -1,
            idx_schedule_selected:-1,
            idx_cart_selected:-1,
            selected_schedule_iskunjungan:'Y',
            mindate:moment(new Date()).format('YYYY-MM-DD'),
            maxdate:moment(new Date(Date.now() + 13 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD'),
            mindate_sampling:moment(new Date()).format('YYYY-MM-DD'),
            maxdate_sampling:moment(new Date(Date.now() + 13 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD')
        }),
        watch: {
            selected_date_dialog(newValue, oldValue) {
                console.log(this.idx_cart_selected)
                if(this.selected_schedule_iskunjungan === 'N'){
                    var prm = this.cart[this.idx_cart_selected].order[this.idx_order_selected].schedule[this.idx_schedule_selected]
                    prm.old_date = oldValue
                    prm.new_date = newValue
                    prm.id = this.xlocalstate.choosebranch.selected_branch.id
                    this
                        .$store
                        .dispatch("test/getTimesByDate", prm)
                }
                else{
                    let xxlocalstate = JSON.parse(localStorage.getItem("oneState"))
                    var xprm =  xxlocalstate.choosebranch.selected_branch
                    xprm.selected_date = newValue
                    this.$store.dispatch("test/getTimesKunjunganHS", xprm)
                }
                
            }
        }
    }
</script>