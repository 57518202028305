<template>
    <div style="overflow-x:hidden;overflow-y:hidden">
         <v-app-bar
            color="red lighten-1"
            fixed
            dark
            dense
            flat
            height="59px"
        >

            <v-list-item class="mx-auto pa-0">
              <v-list-item-avatar >
                  <v-icon style="cursor:pointer" @click="goBack()">mdi-close</v-icon>
              </v-list-item-avatar>
              <v-list-item-content class="font-weight-bold">
                Filter
              </v-list-item-content>
            </v-list-item>
        </v-app-bar>
        <v-row class="mb-0 mt-14" justify="space-around">
            <v-card>

            </v-card>
        </v-row>
    </div>
</template>
<style>
    
    @media only screen and (min-device-width : 1024px) {
        /* Styles */
        /* width */
        ::-webkit-scrollbar {
            height: 8px;
            /* height of horizontal scrollbar ← You're missing this */
            width: 8px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #888;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
</style>
<script>
    
    export default {
        name: 'xlistingtestfilter',
        mounted() {
            var localstorage = JSON.parse(localStorage.getItem("oneState"))
            this.selected_branch = localstorage
                .choosebranch
                .selected_branch
               
            

            if (this.$vuetify.breakpoint.name == 'xs') {
                this.window_width = window.innerWidth
            } else 
                this.window_width = '560px'

        },
        computed: {
            selected_branch: {
                get() {
                    return this.$store.state.choosebranch.selected_branch

                },
                set(val) {
                    this
                        .$store
                        .commit("choosebranch/update_selected_branch", val)
                }
            },
            window_width: {
                get() {
                    return this.$store.state.beranda.window_width

                },
                set(val) {
                    this
                        .$store
                        .commit("beranda/update_window_width", val)
                }
            }
        },
        methods: {

        },
        data: () => ({
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth
        })
    }
</script>