<template>
  <div style="overflow-x:hidden;overflow-y:hidden">
    <v-dialog
      v-model="dialog_daterange"
      width="400px"
    >

      <v-card>
        <v-card-text class="pb-0">
            <v-row>
                <v-col
                  class="pl-0 pr-0 mb-0 pb-0"
                    cols="12"
                >
                  <v-date-picker
                    v-model="range_date"
                    range
                    scrollable
                    color="red lighten-1"
                    elevation="0"
                    
                    full-width
                   
                ></v-date-picker>

                </v-col>
            </v-row>
            
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            
            text
            @click="changeType"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-app-bar
      color="white"
      fixed
      elevation="1"
      height="59px"
    >
        <v-list-item class="mx-auto pa-0">
        <v-list-item-avatar>
            <v-icon style="cursor:pointer" @click="goBack()">mdi-arrow-left</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-subtitle class="font-weight-bold title">
            <v-row no-gutters>
                <v-col cols="8">
                Daftar Pesanan
                </v-col>
            </v-row>
            </v-list-item-subtitle>
        </v-list-item-content>
        </v-list-item>
    </v-app-bar>
    <v-row class="mt-14 mx-auto">
        <v-col cols="12">
            <p class="font-weight-bold body-1 mb-0">FILTER :</p>
        </v-col>
        <v-col class="mt-0 pt-0" cols="12">
            <v-text-field
            color="grey"
                v-model="dateRangeText"
                label="Tanggal Pemesanan"
                prepend-inner-icon="mdi-calendar"
                readonly
                hide-details
                outlined
                @click="openCalendar()"
               @click:prepend-inner="openCalendar()"
            ></v-text-field>
        </v-col>
    </v-row>
    <v-row no-gutters>
        <v-col cols="12">
            <v-divider></v-divider>
        </v-col>
    </v-row>
    <v-row 
      class="pb-6"
    >
        <v-col 

        class="overflow-y-auto mt-0 pt-0 el-table__body-wrapper"
      :style="{'max-height':'600px'}"
        cols="12">
            <v-card
                v-for="(order,ko) in orders"
                :key="ko"

                style="cursor:pointer"
                class="pa-0 ml-2 mt-2 mr-2 mb-1"
                outlined
                elevation="4"
                
                >
                <v-list
                    two-line
                    class="pa-0" style="min-height:48px"
                >
                    <v-list-item class="pa-0 ma-0"
                    >
                    <v-list-item-avatar class="mt-0 pt-0 mb-0 pb-0" tile>
                        <v-icon 
                        class="red lighten-2"
                        :class="{'grey ligthen-1 grey--text':order.status === 'expired'}"
                        dark
                        >fa-circle-notch</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                         
                        <v-list-item-title class="mb-0">
                            <div class="body-1 font-weight-bold">{{order.branch_name}}</div>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <div class="body-2">{{order.order_created}}</div>    
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-row
                    align="center"
                    no-gutters
                >
                    <v-col
                        cols="12"
                        class="pa-2"
                    >
                    <v-list-item  dense v-if="order.invoice_number !== ''" class="mb-0 pb-0 mt-0 pt-0">
                      <v-list-item-content class="mb-0 pb-0 mt-0 pt-0">
                        <v-list-item-title class="mb-0 pb-0 mt-0 pt-0 body-2">
                          INVOICE : <a style="text-decoration: none;" class="body-2" @click="download_invoice(order.id,order.is_hs)" href="javascript:void(0)">{{order.invoice_number}} <v-icon color="blue ligthen-2" small>fa-file-pdf</v-icon></a>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="order['patients']" two-line>
                        <v-list-item-content>
                            <v-list-item-subtitle class="caption font-weight-bold grey--text">{{order['patients'][0].id_name}} : {{order['patients'][0].id_number}}</v-list-item-subtitle>
                            <v-list-item-title class="body-1 font-weight-bold black--text">
                              <p class="mb-1 body-1">{{order['patients'][0].patient_name}}</p>
                              <p class="caption grey--text" v-if="order['patients'].length > 1">+ {{order['patients'].length -1}} pasien lainnya</p>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    </v-col>
                </v-row>
                <p class="light-poppins mb-1 ml-2" style="font-size:12px" v-if="order.status === 'pending' && order.pay_at_pramita == 'N'">Bayar sebelum : {{order.expired_time_text}}</p>
                
                <v-divider/>
                <v-row>
                  <v-col cols="6">
                    <v-btn
                      depressed
                      disabled
                      tile
                      v-if="order.status === 'expired'"
                    >
                      <v-icon class="pr-3">fa-trash</v-icon> KEDALUWARSA
                    </v-btn>
                    <v-btn
                      depressed
                      color="warning"
                      tile
                      v-if="order.status === 'pending'"
                    >
                      <v-icon class="pr-3">fa-exclamation</v-icon> BELUM BAYAR
                    </v-btn>
                    
                    <v-btn
                      depressed
                      color="success"
                      tile
                      v-if="order.status === 'done'"
                    >
                      <v-icon class="pr-3">fa-check-double</v-icon> LUNAS
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <v-btn
                      color="red ligthen-1"
                      tile
                      text
                      @click="goToDetail(order.id)"
                      v-if="order.status !== 'pending' || order.pay_at_pramita == 'Y'"
                    dark
                    >
                      LIHAT DETAIL
                    </v-btn>
                    <v-btn
                      color="red ligthen-1"
                      tile
                      text
                      @click="goToDetail(order.id)"
                      v-if="order.status === 'pending' && order.pay_at_pramita == 'N'"
                    dark
                    >
                      BAYAR SEKARANG
                    </v-btn>
                  </v-col>
                </v-row>
            </v-card>
            <infinite-loading spinner="bubles"
      slot="append"
      :identifier="infiniteId" @infinite="infiniteHandler"
      force-use-infinite-wrapper=".el-table__body-wrapper">

        <div slot="no-more">
          <v-row class="pa-2 mt-0 pt-0">
            <v-col cols="12">
          <v-alert
      dismissible
      class="mx-auto"
      color="black"
      border="left"
      elevation="2"
      colored-border
      icon="mdi-warning"
    >
      Tidak ada lagi data
    </v-alert>
            </v-col>
          </v-row>
        </div>
        <div slot="no-results">
           <v-row class="pa-2 mt-0 pt-0">
            <v-col cols="12">
          <v-alert
      dismissible
      class="mx-auto"
      color="red"
      border="left"
      elevation="2"
      colored-border
      icon="mdi-warning"
    >
      Belum ada pemesanan
    </v-alert>
            </v-col>
           </v-row>
        </div>

    </infinite-loading>
        </v-col>
    </v-row>
<!--<v-bottom-navigation 
                    background-color="red lighten-1"
                    color="yellow lighten-3"
                  dark elevation="1" grow fixed>
                  <v-btn  @click="goBack()">
                    <span>BERANDA</span>

                    <v-icon  class="pb-1">fa-home</v-icon>
                  </v-btn>

                  <v-btn  v-if="isLogin">
                    <span>PESANAN</span>

                    <v-icon color="warning lighten-3" class="pb-1">fa-calendar-alt</v-icon>
                  </v-btn>
                  <v-btn @click="login('daftar')" v-if="!isLogin">
                    <span>DAFTAR</span>

                    <v-icon class="pb-1">fa-user-plus</v-icon>
                  </v-btn>
                  <v-btn @click="login()" v-if="!isLogin">
                    <span>LOGIN</span>

                    <v-icon class="pb-1">fa-sign-in-alt</v-icon>
                  </v-btn>
                  <v-btn @click="menu()" v-if="isLogin">
                    <span>LAINNYA</span>

                    <v-icon class="pb-1">fa-address-card</v-icon>
                  </v-btn>
                </v-bottom-navigation>-->

                <v-bottom-navigation height="80"
                    background-color="white"
                    
                   elevation="1" grow fixed>
                  
                    <v-row>
                      <v-col  class="text-center" cols="4" >
                        <v-img @click="goBack()"
                          class="mx-auto"
                          :lazy-src="require('../assets/icon-home-grey.png')"
                          :src="require('../assets/icon-home-grey.png')"
                          width="40"
                          height="40"
                        ></v-img>
                        <p style="font-size:14px;" class="poppins-light mx-auto ">Beranda</p>
                      </v-col>
                      <v-col v-if="!isLogin"  class="text-center" cols="4" >
                        <v-img  @click="login('daftar')"
                          class="mx-auto"
                          :lazy-src="require('../assets/icon-daftar-grey.png')"
                          :src="require('../assets/icon-daftar-grey.png')"
                          width="40"
                          height="40"
                        ></v-img>
                        <p style="font-size:14px" class="poppins-light mx-auto ">Daftar</p>
                      </v-col>
                      <v-col v-if="!isLogin" class="text-center" cols="4" >
                        <v-img  @click="login()"
                          class="mx-auto"
                          :lazy-src="require('../assets/icon-login-grey.png')"
                          :src="require('../assets/icon-login-grey.png')"
                          width="40"
                          height="40"
                        ></v-img>
                        <p style="font-size:14px" class="poppins-light mx-auto ">Login</p>
                      </v-col>
                      <v-col v-if="isLogin"  class="text-center" cols="4" >
                        <v-img  @click="listorder()"
                          class="mx-auto"
                          :lazy-src="require('../assets/icon_pesanan_red.png')"
                          :src="require('../assets/icon_pesanan_red.png')"
                          width="40"
                          height="40"
                        ></v-img>
                        <p style="font-size:14px;color:#e0282c" class="poppins-light mx-auto ">Pesanan</p>
                      </v-col>
                  
                  <v-col v-if="isLogin"  class="text-center" cols="4" >
                        <v-img  @click="menu()"
                          class="mx-auto"
                          :lazy-src="require('../assets/icon_akunblack.png')"
                          :src="require('../assets/icon_akunblack.png')"
                          width="40"
                          height="40"
                        ></v-img>
                        <p style="font-size:14px" class="poppins-light mx-auto ">Akun</p>
                      </v-col>
                  
                    </v-row>
                        
                </v-bottom-navigation>
                  



                  
                

                  
</div>
                
</template>
<style>


.box-center{
  position: fixed;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}
@media only screen and (min-device-width : 1024px){
/* Styles */
/* width */
::-webkit-scrollbar {
  height: 8px;              /* height of horizontal scrollbar ← You're missing this */
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
}

</style>
<script>
import axios from 'axios';
import InfiniteLoading from 'vue-infinite-loading';

  export default {
    name: 'xlistorder',
    components: {
      InfiniteLoading
    },
    mounted(){
      //this.orders = []
      this.$store.commit("order/update_selected_time",{})
      if (localStorage.getItem("user")){
        this.user = JSON.parse(localStorage.getItem("user"))
        this.isLogin = true
        this.orders = []
        this.page = 1
         if(this.orders.length == 0){
           this.$store.commit("order/update_start_date",this.$moment().clone().startOf('month').format('YYYY-MM-DD'))
          this.$store.commit("order/update_end_date",this.$moment().clone().endOf('month').format('YYYY-MM-DD'))
          this.range_date = [this.$moment().clone().startOf('month').format('YYYY-MM-DD'),this.$moment().clone().endOf('month').format('YYYY-MM-DD')]
         }
      }
      else
        this.isLogin = false

        console.log(this.$moment().clone().startOf('month').format('YYYY-MM-DD'))
        
      //this.getOrders()

      if(this.$vuetify.breakpoint.name == 'xs'){
        this.window_width = window.innerWidth
      }
      else
        this.window_width = '560px'

    },
    computed:{
      attributes_start() {
        return this.dates.map(date => ({
          highlight: true,
          dates: date,
        }))
      },
      attributes_end() {
        return this.dates.map(date => ({
          highlight: true,
          dates: date,
        }))
      },
        dateRangeText () {
            var min = ''
            var max = ''
        if(this.$moment(this.range_date[0]) < this.$moment(this.range_date[1])){
            min = this.$moment(this.range_date[0])
            max = this.$moment(this.range_date[1])
        }
        else{
            max = this.$moment(this.range_date[0])
            min = this.$moment(this.range_date[1])
        }

        var start_date = this.$moment(min).format("DD-MM-YYYY")
        var end_date = this.$moment(max).format("DD-MM-YYYY")
        this.$store.commit("order/update_start_date",start_date)
        this.$store.commit("order/update_end_date",end_date)
        return start_date+" ~ "+end_date
      },
        range_date:{
        get() {
            return this.$store.state.order.range_date
                
        },
        set(val) {
              this.$store.commit("order/update_range_date",val)
        }
      },
      page:{
        get() {
            return this.$store.state.order.page
                
        },
        set(val) {
              this.$store.commit("order/update_page",val)
        }
      },
      orders:{
        get() {
            return this.$store.state.order.orders
                
        },
        set(val) {
              this.$store.commit("order/update_orders",val)
        }
      },
      selected_branch:{
        get() {
            return this.$store.state.choosebranch.selected_branch
                
        },
        set(val) {
              this.$store.commit("choosebranch/update_selected_branch",val)
        }
      },
      lat:{
        get() {
            return this.$store.state.beranda.lat
                
        },
        set(val) {
              this.$store.commit("beranda/update_lat",val)
        }
      },
      lng:{
        get() {
            return this.$store.state.beranda.lng
                
        },
        set(val) {
              this.$store.commit("beranda/update_lng",val)
        }
      },
      window_width:{
        get() {
            return this.$store.state.beranda.window_width
                
        },
        set(val) {
              this.$store.commit("beranda/update_window_width",val)
        }
      },
    },
    methods:{
      download_invoice(order,is_hs){
        //console.log('dasdasd')
        //var order  = this.order
        //console.log(order)
        if(!is_hs || is_hs == 'N')
        window.open('/birt/frameset?__report=report/one/fo/rpt_online_invoice.rptdesign&__format=pdf&username=regonline&PID='+order+'&ts='+Math.round(+new Date()/1000), '_blank')
        else
        window.open('/birt/frameset?__report=report/one/fo/rpt_online_invoice_hs.rptdesign&__format=pdf&username=regonline&PID='+order+'&ts='+Math.round(+new Date()/1000), '_blank')
      

      },
        goBack(){
            this.$router.push({name:'xberanda'})
        },
        goToDetail(id){
            //var id = 11
            this.$router.push({ path: `/detailorder/id/${id}` })
        },
        goToDetailNotLunas(id){
            //var id = 11
            this.$router.push({ path: `/detailordernotlunas/id/${id}` })
        },
        login(act){
          if(act === 'daftar'){
            this.$router.push({name:'xlogin',params:{act:act}})
          }
          else
          this.$router.push({name:'xlogin'})
        },
        getWIdth(){
          switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 320
            case 'sm': return 325
            case 'md': return 363
            case 'lg': return 363
          }
        },
        menu(){
          this.$router.push({name:'xmenu'})
        },
        logout(){
          var user =  JSON.parse(localStorage.getItem("user"))
          user.lat = this.$store.state.beranda.lat
          user.lng = this.$store.state.beranda.lng
          this.$store.dispatch("login/logout", user)
        },
        getOrders(){
            this.dialog_daterange = false
            var prm  =  {start_date:this.$store.state.order.start_date,end_date:this.$store.state.order.end_date}
            var fcm = JSON.parse(localStorage.getItem("fcm"))
            //console.log(fcm)
            if(fcm)
              prm.fcm = fcm
 
            this.$store.dispatch("test/getOrdersx",prm)
        },
        openCalendar(){
            this.dialog_daterange = true
        },
        changeType() {
          this.page = 1;
          this.orders = [];
          this.infiniteId += 1;
          this.dialog_daterange = false
            },
            infiniteHandler($state) {
              var user = JSON.parse(localStorage.getItem("user"))
              var prm = {
              current_page: this.page,
              start_date:this.$store.state.order.start_date,
              end_date:this.$store.state.order.end_date,
              token : user.token
            }
            var fcm = JSON.parse(localStorage.getItem("fcm"))
           // console.log(fcm)
            if(fcm)
              prm.fcm = fcm.token
          axios.get(window.apix+"/one_api_coba/mobile/test/getOrders", {
            params: prm,
          }).then(({ data }) => {
            if (data.data.length) {
              this.page += 1;
              this.orders.push(...data.data);
              $state.loaded();
            } else {
              $state.complete();
            }
          });
        }
    },
    data: () => ({
      infiniteId: +new Date(),
        masks: {
      input: 'DD-MM-YYYY',
    },
    infiniteId:1,
    dialog_daterange:false,
      isLogin:false,
      dialog_city:false,
      dialog_error:false,
      msg_error:'',
      reveal:false,
      dialog_branch:false,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      selected_city: {},
      categories:[{id:1,name:'PCR',active:false},{id:1,name:'Swab Antigen',active:false}],
      selected_categories:[],
      show: false,
      cities: [
          { name: 'Surabaya'},
          { name: 'Madiun'},
          { name: 'Salatiga'},
          { name: 'Magelang'},
          { name: 'Yogyakarta'},
          { name: 'Bandung'},
        ],
    }),
  }
</script>
