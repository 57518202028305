<template>
    <div style="overflow-x:hidden">
        <v-dialog
            style="z-index:9999999999999999999"
            v-model="dialog_privacy_policy_x"
            width="500"
            scrollable="scrollable"
            persistent="persistent">

            <v-card>
                <v-toolbar dark="dark" color="#e0282c">

                    <v-toolbar-title class="poppins-bold">KEBIJAKAN PRIVASI</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon="icon" dark="dark" @click="dialog_privacy_policy_x = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text class="pt-3 pb-3">
                    <p class="semi-poppins subheader">
                        Privasi Anda sangat penting bagi kami.
                    </p>
                    <p class="poppins-light">Kami sangat menyadari adanya kebutuhan pelanggan kami akan privasi dan
                        keamanan. Untuk itu, Kebijakan Privasi ini ditujukan untuk memberitahu Anda
                        tentang penggunaan informasi pribadi yang Anda berikan kepada kami saat Anda
                        mengakses Situs Web maupun Aplikasi Web kami.
                    </p>
                    <p class="poppins-light">Dengan mengunjungi dan menggunakan layanan pada situs Web maupun Aplikasi Web
                        kami, Anda dianggap telah setuju untuk terikat dengan ketentuan-ketentuan dalam
                        Kebijakan Privasi ini.</p>
                    <p class="semi-poppins subheader">
                        PENGUMPULAN DATA ANDA
                    </p>
                    <p class="poppins-light">Semua orang dapat mengakses, melihat dan melakukan transaksi di situs web
                        kami. Namun ada beberapa fitur layanan yang membutuhkan pengisian data pribadi
                        agar dapat diakses.</p>

                    <ul class="mb-5">
                        <li class="mb-2 semi-poppins ">
                            Fitur Hubungi :
                            <br/>
                            <span class="poppins-light">Kami mengumpulkan dan menggunakan informasi pribadi Anda meliputi : Nama
                            Lengkap, Alamat Rumah, Alamat Kantor, Email, dan Nomor Telepon</span>
                        </li>
                        <li class="mb-2 semi-poppins ">
                            Fitur Registrasi Online :<br/>
                            <span class="poppins-light">Kami mengumpulkan dan menggunakan informasi pribadi Anda meliputi nomor
                            KTP/SIM/Paspor, Nama, Tanggal Lahir, Jenis Kelamin, Nomor Telepon, Pekerjaan,
                            Alamat, dan Alamat Email. Serta informasi tambahan lainnya seperti informasi
                            mengenai tujuan permintaan pemeriksaan Anda dan mendeteksi lokasi persis (
                            location ) perangkat Anda saat melakukan pendaftaran online.</span>
                        </li>
                    </ul>
                    <p class="semi-poppins subheader">
                        TUJUAN PENGUMPULAN DATA ANDA
                    </p>
                    <p class="poppins-light">Tujuan pengumpulan data pribadi Anda adalah agar kami dapat memberikan
                        informasi dan layanan yang Anda butuhkan dengan lebih baik dan efektif.</p>
                    <p class="poppins-light">Data pribadi yang Anda berikan, dikumpulkan dan digunakan untuk :</p>
                    <ul class="mb-5">
                        <li class="poppins-light mb-2">
                            Memfasilitasi Anda dalam mengakses dan menggunakan layanan yang tersedia pada
                            situs web atau aplikasi web kami.
                        </li>
                        <li class="poppins-light mb-2">
                            Identitas pada dokumen hasil pemeriksaan, pengelolaan pelaporan data hasil
                            pemeriksaan, verifikasi dan validasi hasil pemeriksaan.
                        </li>
                        <li class="poppins-light mb-2">
                            Memberikan informasi, menangani keluhan, pertanyaan, atau permasalahan yang Anda
                            sampaikan.
                        </li>
                        <li class="poppins-light mb-2">
                            Memproses permintaan yang Anda kirimkan.
                        </li>
                        <li class="poppins-light mb-2">
                            Mengumpulkan data transaksi Anda.
                        </li>
                        <li class="poppins-light mb-2">
                            Mengarahkan Anda ke lokasi terdekat Kami, berdasarkan info lokasi persis /
                            location yang terdeteksi dari perangkat Anda, saat Anda melakukan pendaftaran
                            pemeriksaan secara online.
                        </li>
                        <li class="poppins-light mb-2">
                            Mengelola dan memverifikasi akun serta transaksi keuangan yang berkaitan dengan
                            pembayaran yang anda buat secara online.
                        </li>
                    </ul>
                    <p class="poppins-light">Kami menjamin kerahasiaan data pribadi anda. Dalam hal untuk penyelesaian
                        masalah jika terjadi kegagalan dalam transaksi maka kami akan memberikan data
                        pribadi Anda secara terbatas ( sesuai keperluan ) kepada afiliasi kami.</p>
                    <p class="poppins-light">Karena kami menghormati privasi Anda, maka kami akan melindungi dan
                        menggunakan data pribadi Anda hanya untuk kepentingan sebagaimana yang
                        dinyatakan dalam kebijakan privasi ini.</p>
                    <p class="poppins-light">Jika Anda memberikan informasi pribadi pihak ketiga kepada Kami, maka akan
                        dianggap dan diasumsikan bahwa Anda telah memperoleh izin yang diperlukan dari
                        pihak ketiga tersebut untuk memberikan informasi pribadi kepada kami.</p>
                    <p class="semi-poppins subheader">
                        PERUBAHAN KEBIJAKAN PRIVASI
                    </p>
                    <p class="poppins-light">Secara berkala, Kami dapat meninjau kecukupan dari Kebijakan Privasi ini.
                        Kami dapat mengubah isi kebijakan privasi ini dari waktu ke waktu. Perubahan
                        terhadap kebijakan privasi ini akan kami cantumkan pada halaman ini. Bacalah
                        kebijakan privasi ini setiap Anda mengunjungi situs web kami.</p>
                    <p class="semi-poppins subheader">
                        CARA MENGHUBUNGI KAMI
                    </p>
                    <p class="poppins-light">Jika Anda ingin menghubungi kami terkait kebijakan privasi ini, silahkan
                        menghubungi kami melalui :</p>
                    <p class="poppins-light">Kantor Pusat Laboratorium Klinik Pramita.Jl. Adityawarman No. 73 – 75
                        Surabaya Telp. 031-5682416 Email: info@pramita.co.id</p>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    
                    <v-btn rounded block color="#e0282c" dark @click="showDaftar()">
                        Saya setuju
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_confirmation" persistent="persistent" max-width="290">
            <v-card>
                <v-card-title class="headline">
                    Apakah sudah benar ?
                </v-card-title>
                <v-card-text>Nomor handphone anda
                    <b>{{daftar_hp}}</b>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="black light-2" text="text" @click="dialog_confirmation = false">
                        Batal
                    </v-btn>
                    <v-btn color="red lighten-1" text="text" @click="showMethode()">
                        Benar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-app-bar color="white" dense="dense" outlined="outlined" fixed="fixed">
            <v-btn @click="goToHome()" icon="icon">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <!--<a
                v-if="show_login"
                href="javascript: void(0)"
                @click="openPrivacyPolicy()"
                class="mr-0 pr-0 red--text text-decoration-none">Daftar</a>-->

        </v-app-bar>
        <div v-if="show_login">
            <v-row class="mx-auto mt-15 mb-5 ml-2">
                <v-col>
                    <div style="color:#e0282c;font-size:38px;font-weight:800" class="poppins-bold">Masuk</div>
                </v-col>
            </v-row>
            <v-row class="mx-auto ">
                <v-col>
                    <v-card flat="flat">
                        <v-alert
                            border="bottom"
                            colored-border="colored-border"
                            class="ma-3"
                            :type="alert_login.type"
                            elevation="2"
                            v-if="alert_login.status">
                            {{alert_login.msg}}
                        </v-alert>
                        <v-row class="mb-5">
                            <v-col cols="12" >
                                <v-text-field
                                label="Handphone"
                              
                                    filled
                                    rounded
                                    type="number"
                                    color="red"
                                    class="ma-0 poppins-light"
                                    v-model="login_hp"
                                    hide-details="auto"
                                    hint="Contoh : 087731331112"
                                ></v-text-field>
                                <!--<v-text-field
                                    type="number"
                                    color="red"
                                    class="ma-0"
                                    v-model="login_hp"
                                    label="Nomor Handphone"
                                    hint="Contoh : 087731331112"></v-text-field>-->
                            </v-col>
                            <v-col cols="12" class="mt-2 pt-0">
                                <v-text-field
              
                              
                                    filled
                                    rounded
        
                                    class="ma-0 poppins-light"
               
                    
                                    color="red"
                                    v-model="login_password"
                                    :append-icon="login_show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="login_show_password ? 'text' : 'password'"
                                    name="input-10-1"
                                    label="Kata Sandi"
                                    @click:append="login_show_password = !login_show_password"
                                ></v-text-field>
                                <!--<v-text-field
                                    color="red"
                                    v-model="login_password"
                                    :append-icon="login_show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="login_show_password ? 'text' : 'password'"
                                    name="input-10-1"
                                    label="Password"
                                    @click:append="login_show_password = !login_show_password"></v-text-field>-->
                            </v-col>
                        </v-row>
                        <v-alert
                            border="bottom"
                            colored-border="colored-border"
                            class="ma-3"
                            type="error"
                            elevation="2"
                            v-if="error_login">
                            Nomor Handphone dan password tidak cocok, silahkan ulangi lagi
                        </v-alert>
                        <v-card-actions>
                            <v-row class="mt-0 pt-0">
                                <v-col class="pt-0 mt-0 mb-0" style="padding-bottom:0px" cols="12">
                                    <!--<v-btn
                                        color="red lighten-1"
                                        block="block"
                                        dark="dark"
                                        @click="login()"
                                        medium="medium">
                                        LOGIN
                                    </v-btn>-->
                                    <v-card @click="login()"  color="#e0282c" dark  class="mx-auto pa-3  rounded-pill">
              <div style="font-size:22px" class="text-center semi-poppins">Masuk</div>
            </v-card>
                                </v-col>
                                <v-col class="poppins-light text-center mt-5">Lupa kata sandi?
                                    <a
                                        href="javascript: void(0)"
                                        @click="forgetPassword()"
                                        class="poppins-light red--text text-decoration-none">Klik di sini</a>
                                </v-col>

                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <div class="box-center">
            <v-divider></v-divider>
            <v-row class="mt-4 mb-0 ">
                <v-col cols="12" class="poppins-light mb-4 text-center">Belum punya akun?
                    <a
                        href="javascript: void(0)"
                        @click="openPrivacyPolicy()"
                        class="poppins-light red--text text-decoration-none">Daftar</a>
                </v-col>
            </v-row>
            </div>

        </div>
        <div v-if="show_daftar">
            <v-row class="mx-auto mt-15">
                <v-col>
                    <v-card flat="flat">
                        <v-row class="mb-3 mt-16">
                            <v-col cols="12" class="mt-16 pl-8 pr-8">
                                <v-text-field
                                label="Handphone, Contoh : 087731331112"
                              
                                    filled
                                    rounded
                                    type="number"
                                    color="red"
                                    class="ma-0 poppins-light"
                                    v-model="daftar_hp"
                                    hide-details="auto"
                                 
                                    :append-icon="result_checking_hp  === 'success'?'mdi-check':''"
                                    :messages="msg_checking_hp"
                                    :loading="checkLoading()"
                                    :error="result_checking_hp === 'error'"
                                    :success="result_checking_hp === 'success'"
                                ></v-text-field>
                                <!--<v-text-field
                                    color="red"
                                    class="ma-0"
                                    type="number"
                                    v-model="daftar_hp"
                                    label="Masukkan Nomor Handphone"
                                    hint="Contoh : 087731331112"
                                    :append-icon="result_checking_hp  === 'success'?'mdi-check-circle':''"
                                    :messages="msg_checking_hp"
                                    :loading="checkLoading()"
                                    :error="result_checking_hp === 'error'"
                                    :success="result_checking_hp === 'success'"></v-text-field>-->
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <!--<v-btn
                                color="#1976d2"
                                block="block"
                                dark="dark"
                                v-if="result_checking_hp !== 'success'"
                                @click="checkingExist()"
                                medium="medium">
                                CHECK
                            </v-btn>-->
                            <v-card width="300px"  @click="checkingExist()" v-if="result_checking_hp !== 'success'"  color="#e0282c" dark  class="mx-auto pa-3  rounded-pill">
                                <div style="font-size:18px" class="text-center poppins-light">Cek</div>
                            </v-card>
                            <v-card width="300px" @click="daftar()" v-if="result_checking_hp == 'success'"  color="#e0282c" dark  class="mx-auto pa-3  rounded-pill">
                                <div style="font-size:18px" class="text-center poppins-light">Lanjutkan</div>
                            </v-card>
                            <!--<v-btn
                                color="red lighten-1"
                                block="block"
                                dark="dark"
                                v-if="result_checking_hp == 'success'"
                                @click="daftar()"
                                medium="medium">
                                LANJUTKAN
                            </v-btn>-->
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <div class="box-center">
            <v-divider></v-divider>
            <v-row class="mt-4 mb-0 ">
                <v-col cols="12" class="poppins-light mb-4 text-center">Sudah punya akun?
                    <a
                        href="javascript: void(0)"
                        @click="goToLogin()"
                        class="poppins-light red--text text-decoration-none">Masuk</a>
                </v-col>
            </v-row>
            </div>

        </div>
        <div v-if="show_methode">
            <v-card flat="flat" class="mx-auto mt-16" width="100px">
                <v-icon class="pa-4 pb-1" style="font-size:62px">mdi-cellphone-key</v-icon>
            </v-card>
            <v-row class="mt-1  pt-1 mx-auto">
                <v-col>
                    <v-card flat="flat" class="mx-auto">
                        <v-row>
                            <v-col class="text-center mb-5" cols="12">
                                <p class="body-1 mb-1 font-weight-bold">PILIH METODE PENGIRIMAN OTP</p>
                                <p class="body-2 mb-1">(<span >ONE TIME PASSWORD</span>)</p>
                            </v-col>
                            <v-col class="text-center" cols="12">
                                <v-btn rounded @click="getOTP('sms')" dark="dark" color="blue lighten-1">
                                    <v-icon class="mr-2">mdi-email</v-icon>
                                    SMS(
                                    <span v-if="act !== 'login'">{{daftar_hp}}</span>
                                    <span v-if="act == 'login'">{{login_hp}}</span>
                                    )</v-btn>
                            </v-col>
                            <v-col class="text-center" cols="12">
                                <v-btn rounded @click="getOTP('wa')" dark="dark" color="green">
                                    <v-icon class="mr-2">mdi-whatsapp</v-icon>
                                    WhatsApp (
                                    <span v-if="act !== 'login'">{{daftar_hp}}</span>
                                    <span v-if="act == 'login'">{{login_hp}}</span>
                                    )</v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <div v-if="show_daftar_otp">
            <v-row justify="center" class="mx-auto mt-15">
                <v-col>
                    <v-card flat="flat">
                        <v-row class="mb-5">
                            <v-col class="text-center" cols="12">
                                <p class="mb-5">
                                    <v-icon>fa-mobile-alt</v-icon>
                                </p>
                                <p class="h4">Masukkan Kode Verifikasi</p>
                                <p class="body-2">Kode verifikasi telah dikirim ke</p>
                                <p class="body-2">
                                    <span v-if="act === 'login'">{{login_hp}}</span>
                                    <span v-else="v-else">{{daftar_hp}}</span>
                                </p>
                            </v-col>
                        </v-row>
                        <v-row class="mb-5">
                            <v-col class="mx-auto" cols="3">
                                <v-text-field
                                    color="red"
                                    class="ma-0"
                                    type="number"
                                    @keyup="keyupOTP"
                                    hide-details="hide-details"></v-text-field>
                                <p class="mt-2 body-2 text-center">Kode OTP</p>
                            </v-col>
                        </v-row>
                        <v-row class="mb-3">
                            <v-col class="mx-auto" cols="12">
                                <p v-if="!show_resend_otp" class="body-2 text-center">Mohon tunggu dalam
                                    {{otp_counting}}
                                    detik untuk kirim ulang</p>
                                <p v-if="show_resend_otp" class="body-2 text-center">
                                    <v-btn small="small" @click="showMethode()" color="secondary" dark="dark">
                                        Kirim Ulang OTP
                                    </v-btn>
                                </p>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <div v-if="show_insert_password">
            <v-form ref="form" v-model="valid" lazy-validation="lazy-validation">
                <v-row class="mx-auto mt-15">
                    <v-col>
                        <v-card flat="flat">
                            <v-card class="pa-2 pl-3 pr-3 mb-3" elevation="9">
                                <v-card-title style="font-size:24px;color:#e0282c"  class="poppins-bold">DATA LOGIN</v-card-title>
                                <v-card-text>
                                    <v-row class="mb-5">
                                        <v-col cols="12">
                                            <v-text-field
                                                color="red"
                                                class="ma-0"
                                                filled
                                                rounded
                                                 hide-details="auto"
                                                v-model="insert_password_hp"
                                                label="Nomor Handphone"
                                                disabled="disabled"
                                                hint="Contoh : 087731331112"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                color="red"
                                                filled
                                                rounded
                                                v-model="insert_password_password"
                                                :append-icon="insert_password_show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                                :type="insert_password_show_password ? 'text' : 'password'"
                                                name="input-10-1"
                                                 hide-details="auto"
                                                hint="minimal 6 karakter"
                                                :error="insert_password_password.length < 6"
                                                label="Password"
                                                @click:append="insert_password_show_password = !insert_password_show_password"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                color="red"
                                                filled
                                                rounded
                                                hide-details="auto"
                                                :error="insert_password_password_reenter.length < 6 "
                                                v-model="insert_password_password_reenter"
                                                :append-icon="insert_password_show_password_reenter ? 'mdi-eye' : 'mdi-eye-off'"
                                                :type="insert_password_show_password_reenter ? 'text' : 'password'"
                                                name="input-10-1"
                                                hint="minimal 6 karakter dan harus sama dengan di atas"
                                                label="Ulangi Password"
                                                @click:append="insert_password_show_password_reenter = !insert_password_show_password_reenter"></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row class="mt-4 mb-0 ">
                                    <v-col v-if="act === 'forgetpass'"  cols="12" class="poppins-light mb-4 text-center">
                                                        <v-card  @click="saveData()" color="#e0282c" dark  class="mx-auto pa-3  rounded-pill">
              <div style="font-size:22px" class="text-center semi-poppins">Ganti</div>
            </v-card>
                                      
                                    </v-col>
                                </v-row>
                                    
                                </v-card-text>
                            </v-card>
                            <v-card v-if="act !== 'forgetpass'" class="pa-2 pl-3 pr-3 mb-3" elevation="9">
                                <v-card-title style="font-size:24px;color:#e0282c"  class="poppins-bold">DATA PASIEN</v-card-title>
                                <v-card-text>

                                    <v-row>
                                        <v-col cols="12">
                                            <v-select
                                                :items="ids"
                                                hide-details="auto"
                                                label="Tipe Identitas"
                                                @change="checkIdentity()"
                                                v-model="selected_id"
                                                :rules="[v => !_.isEmpty(v)|| 'Pilih salah satu']"
                                                item-text="name"
                                                return-object="return-object"
                                                required="required"></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field
                                                v-model="ktp"
                                                hide-details="auto"
                                                :rules="[v => !!v || 'Harus diisi']"
                                                label="Nomor Identitas"
                                                hint="* jika anak-anak diisi nomor KK"
                                                persistent-hint="persistent-hint"
                                                @change="checkIdentity()"
                                                required="required"></v-text-field>
                                            <p :class="{'error--text':error_ktp}" class="caption mb-0">{{error_ktp_msg}}</p>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <div>Jenis Kelamin</div>
                                            <v-radio-group
                                                class="mt-0 pt-2"
                                                v-model="sex"
                                                hide-details="auto"
                                                required="required"
                                                :rules="[v => !!v || 'Pilih salah satu']"
                                                row="row">
                                                <v-radio label="Laki-laki" value="L"></v-radio>
                                                <v-radio label="Perempuan" value="P"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" sm="4" md="2">
                                            <v-select
                                                :items="titles"
                                                item-text="name"
                                                :rules="[v => !_.isEmpty(v)|| 'Harus diisi']"
                                                return-object="return-object"
                                                label="Sapaan"
                                                v-model="selected_title"
                                                required="required"></v-select>
                                        </v-col>
                                        <v-col cols="8" sm="8" md="10">
                                            <v-text-field
                                                v-model="name"
                                                :rules="[v => !!v || 'Harus diisi']"
                                                label="Nama Lengkap"
                                                hide-details="auto"
                                                required="required"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row align="center">
                                        <v-col cols="12">
                                            <p class="mb-0">Tanggal Lahir</p>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-0 pt-0" align="center">
                                        <v-col class="mt-0 pt-0 mr-0 pr-0" cols="4">
                                            <v-text-field
                                                label="Tgl"
                                                ref="input_tgl"
                                                type="number"
                                                outlined="outlined"
                                                @blur="changeTgl($event)"
                                                clearable="clearable"
                                                dense="dense"
                                                v-model="tgl"
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                        <v-col class="mt-0 pt-0 mr-0 pr-0" cols="4">
                                            <v-text-field
                                                label="Bln"
                                                ref="input_bln"
                                                type="number"
                                                outlined="outlined"
                                                @blur="changeBln($event)"
                                                clearable="clearable"
                                                dense="dense"
                                                v-model="bln"
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                        <v-col class="mt-0 pt-0 pr-0" cols="4">
                                            <v-text-field
                                                label="Thn"
                                                type="number"
                                                ref="input_thn"
                                                @blur="changeThn($event)"
                                                outlined="outlined"
                                                clearable="clearable"
                                                dense="dense"
                                                v-model="thn"
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row align="center">
                                        <v-col class="mt-0 pt-0 pb-0 mb-0" cols="12">
                                            <p :class="{'error--text':error_dob}" class="caption mb-0">Format DD-MM-YYYY, contoh : 01-06-1988</p>
                                            <p v-if="error_dobyear" :class="{'error--text':error_dobyear}" class="caption mb-0">Tahun kelahiran tidak valid</p>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="hp"
                                                readonly
                                                :rules="[v => !!v || 'Harus diisi']"
                                                label="No. HP"
                                                required="required"
                                                hint="contoh : 081123321111"
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="email"
                                                label="Alamat Email"
                                                @blur="checkValidEmail()"
                                                hide-details="auto"></v-text-field>
                                            <p :class="{'error--text':error_email}" class="caption mb-0">{{error_email_msg}}</p>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="job"
                                                :rules="[v => !!v || 'Harus diisi']"
                                                label="Pekerjaan"
                                                required="required"
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <!--<v-row> <v-col cols="12"> <v-text-field v-model="pramitaid" label="Pramita
                                    ID" hint="Isi jika ada" persistent-hint="persistent-hint"
                                    hide-details="auto"></v-text-field> </v-col> </v-row>-->
                                    <v-row>
                                        <v-col cols="12">
                                            <v-autocomplete
                                                v-model="selected_province"
                                                :rules="[v => !_.isEmpty(v)|| 'Harus diisi']"
                                                :items="provinces"
                                                :search-input.sync="search_province"
                                                item-text="name"
                                                clearable="clearable"
                                                return-object="return-object"
                                                required="required"
                                                clear-icon="fa-times"
                                                label="Propinsi"
                                                hide-details="auto"></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-autocomplete
                                                v-model="selected_city"
                                                :rules="[v => !_.isEmpty(v)|| 'Harus diisi']"
                                                :items="cities"
                                                :search-input.sync="search_city"
                                                item-text="name"
                                                clearable="clearable"
                                                return-object="return-object"
                                                required="required"
                                                clear-icon="fa-times"
                                                label="Kota"
                                                hide-details="auto"></v-autocomplete>

                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-autocomplete
                                                v-model="selected_district"
                                                :rules="[v => !_.isEmpty(v)|| 'Harus diisi']"
                                                :items="districts"
                                                :search-input.sync="search_district"
                                                item-text="name"
                                                clearable="clearable"
                                                return-object="return-object"
                                                required="required"
                                                clear-icon="fa-times"
                                                label="Kecamatan"
                                                hide-details="auto"></v-autocomplete>

                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-autocomplete
                                                v-model="selected_kelurahan"
                                                :rules="[v => !_.isEmpty(v)|| 'Harus diisi']"
                                                :items="kelurahans"
                                                :search-input.sync="search_kelurahan"
                                                item-text="name"
                                                clearable="clearable"
                                                return-object="return-object"
                                                required="required"
                                                clear-icon="fa-times"
                                                label="Kelurahan"
                                                hide-details="auto"></v-autocomplete>

                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-textarea
                                                clearable="clearable"
                                                clear-icon="mdi-close"
                                                label="Alamat"
                                                hide-details="auto"
                                                v-model="address"
                                                :rules="[v => !!v || 'Harus diisi']"
                                                required="required"></v-textarea>
                                        </v-col>
                                    </v-row>

                                    <v-row class="mt-3">
                                    <v-col class="pt-0 mt-0 mb-0" style="padding-bottom:0px" cols="12">
                                        <v-btn
                                            v-if="act !== 'forgetpass' && (insert_password_password_reenter.length >= 6 &&  valid && !error_dob && !error_ktp && !_.isEmpty(selected_title) && !error_email )"
                                            color="red lighten-1"
                                            block="block"
                                            dark="dark"
                                            rounded
                                            @click="savePatient()"
                                            medium="medium">
                                            SIMPAN
                                        </v-btn>
                                        <v-btn
                                            v-if="act !== 'forgetpass' && (insert_password_password_reenter.length < 6 || !valid || error_dob || error_ktp || _.isEmpty(selected_title) || error_email )"
                                            color="grey lighten-1"
                                            block="block"
                                            rounded
                                            dark="dark"
                                        
                                            medium="medium">
                                            SIMPAN
                                        </v-btn>
                                        <v-btn
                                        rounded
                                            v-if="act === 'forgetpass'"
                                            color="red lighten-1"
                                            block="block"
                                            :readonly="insert_password_password_reenter.length < 6 || insert_password_password.length < 6"
                                            dark="dark"
                                            @click="saveData()"
                                            medium="medium">
                                            SIMPAN
                                        </v-btn>
                                    </v-col>

                                </v-row>

                                </v-card-text>
                            </v-card>

                           
                        </v-card>
                    </v-col>
                </v-row>
            </v-form>

        </div>

    </div>

</template>
<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
.poppins-bold{
  font-family: 'Poppins', sans-serif;
  font-weight:800;
}
.semi-poppins{
  font-family: 'Poppins', sans-serif;
  font-weight:600;
}
.poppins-light{
  font-family: 'Poppins', sans-serif;
  font-weight:300;
}

    .box-center {
        position: fixed;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, -50%);
        margin: 0 auto;
    }
    @media only screen and (min-device-width : 1024px) {
        /* Styles */
        /* width */
        ::-webkit-scrollbar {
            height: 8px;
            /* height of horizontal scrollbar ← You're missing this */
            width: 8px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #888;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
</style>
<script>
    export default {
        name: 'xlogin',
        mounted() {
            this.$store.dispatch("login/getInitForm")

            var user = JSON.parse(localStorage.getItem("user"))
            if (user && user.token !== '') {
                //console.log('dasdsa')
                this
                    .$router
                    .push({path: `/`})
            }

            if (this.$route.params) {
                if (this.$route.params.act === 'daftar') {
                    this.show_login = false
                    this.show_methode = false
                    this.show_insert_password = false
                    this.show_daftar = true
                    this.show_daftar_otp = false
                    this.result_checking_hp = ''
                } else {
                    this.show_login = true
                    this.show_insert_password = false
                    this.show_methode = false
                    this.show_daftar = false
                    this.show_daftar_otp = false
                    if (user) 
                        this.login_hp = user.username
                }

            } else {
                this.show_login = true
                this.show_insert_password = false
                this.show_methode = false
                this.show_daftar = false
                this.show_daftar_otp = false
                if (user) 
                    this.login_hp = user.username
            }

            this.act = ''
            if (this.$store.state.beranda.lat == 0 && this.$store.state.beranda.lng == 0) 
                this.trackPosition()

            if (this.$vuetify.breakpoint.name == 'xs') {
                this.window_width = window.innerWidth
            } else 
                this.window_width = '560px'

        },
        computed: {
            show_methode: {
                get() {
                    return this.$store.state.login.show_methode

                },
                set(val) {
                    this
                        .$store
                        .commit("login/update_show_methode", val)
                }
            },
            alert_login: {
                get() {
                    return this.$store.state.login.alert_login

                },
                set(val) {
                    this
                        .$store
                        .commit("login/update_alert_login", val)
                }
            },
            error_login: {
                get() {
                    return this.$store.state.login.error_login

                },
                set(val) {
                    this
                        .$store
                        .commit("login/update_error_login", val)
                }
            },
            result_checking_hp: {
                get() {
                    return this.$store.state.login.result_checking_hp

                },
                set(val) {
                    this
                        .$store
                        .commit("login/update_result_checking_hp", val)
                }
            },
            msg_checking_hp: {
                get() {
                    return this.$store.state.login.msg_checking_hp

                },
                set(val) {
                    this
                        .$store
                        .commit("login/update_msg_checking_hp", val)
                }
            },
            color_checking_hp: {
                get() {
                    return this.$store.state.login.color_checking_hp

                },
                set(val) {
                    this
                        .$store
                        .commit("login/update_color_checking_hp", val)
                }
            },
            status_checking_hp: {
                get() {
                    return this.$store.state.login.status_checking_hp

                },
                set(val) {
                    this
                        .$store
                        .commit("login/update_status_checking_hp", val)
                }
            },
            show_insert_password: {
                get() {
                    return this.$store.state.login.show_insert_password

                },
                set(val) {
                    this
                        .$store
                        .commit("login/update_show_insert_password", val)
                }
            },
            insert_password_password_reenter: {
                get() {
                    return this.$store.state.login.insert_password_password_reenter

                },
                set(val) {
                    this
                        .$store
                        .commit("login/update_insert_password_password_reenter", val)
                }
            },
            insert_password_password: {
                get() {
                    return this.$store.state.login.insert_password_password

                },
                set(val) {
                    this
                        .$store
                        .commit("login/update_insert_password_password", val)
                }
            },
            insert_password_hp: {
                get() {
                    return this.$store.state.login.insert_password_hp

                },
                set(val) {
                    this
                        .$store
                        .commit("login/update_insert_password_hp", val)
                }
            },
            show_resend_otp: {
                get() {
                    return this.$store.state.login.show_resend_otp

                },
                set(val) {
                    this
                        .$store
                        .commit("login/update_show_resend_otp", val)
                }
            },
            otp_counting: {
                get() {
                    return this.$store.state.login.otp_counting

                },
                set(val) {
                    this
                        .$store
                        .commit("login/update_otp_counting", val)
                }
            },
            dialog_confirmation: {
                get() {
                    return this.$store.state.login.dialog_confirmation

                },
                set(val) {
                    this
                        .$store
                        .commit("login/update_dialog_confirmation", val)
                }
            },
            login_hp: {
                get() {
                    return this.$store.state.login.login_hp

                },
                set(val) {
                    this
                        .$store
                        .commit("login/update_login_hp", val)
                }
            },
            login_password: {
                get() {
                    return this.$store.state.login.login_password

                },
                set(val) {
                    this
                        .$store
                        .commit("login/update_login_password", val)
                }
            },
            daftar_hp: {
                get() {
                    return this.$store.state.login.daftar_hp

                },
                set(val) {
                    this
                        .$store
                        .commit("login/update_daftar_hp", val)
                }
            },
            window_width: {
                get() {
                    return this.$store.state.beranda.window_width

                },
                set(val) {
                    this
                        .$store
                        .commit("beranda/update_window_width", val)
                }
            },
            show_login: {
                get() {
                    return this.$store.state.login.show_login

                },
                set(val) {
                    this
                        .$store
                        .commit("login/update_show_login", val)
                }
            },
            show_daftar: {
                get() {
                    return this.$store.state.login.show_daftar

                },
                set(val) {
                    this
                        .$store
                        .commit("login/update_show_daftar", val)
                }
            },
            show_daftar_otp: {
                get() {
                    return this.$store.state.login.show_daftar_otp

                },
                set(val) {
                    this
                        .$store
                        .commit("login/update_show_daftar_otp", val)
                }
            },
            otp: {
                get() {
                    return this.$store.state.login.otp

                },
                set(val) {
                    this
                        .$store
                        .commit("login/update_otp", val)
                }
            },
            act: {
                get() {
                    return this.$store.state.login.act

                },
                set(val) {
                    this
                        .$store
                        .commit("login/update_act", val)
                }
            },
            hp: {
                get() {
                    return this.$store.state.order.hp

                },
                set(val) {
                    var hp = val.replace('+', '')
                    var pref_hp = hp
                        .substring(0, 2)
                        .replace('62', 0)
                    var new_hp = pref_hp + hp.slice(2)
                    this
                        .$store
                        .commit("order/update_hp", new_hp)
                }
            },
            titles() {
                if (!this.sex || this.sex === '') {
                    return []
                } else {
                    var titles = this.$store.state.order.titles
                    var sex = this.sex
                    var xvalue = _.filter(titles, {'sex': sex})

                    return xvalue
                }
            },
            tgl: {
                get() {
                    return this.$store.state.patient.tgl

                },
                set(val) {

                    this
                        .$store
                        .commit("patient/update_tgl", val)
                }
            },
            bln: {
                get() {
                    return this.$store.state.patient.bln

                },
                set(val) {

                    this
                        .$store
                        .commit("patient/update_bln", val)
                }
            },
            thn: {
                get() {
                    return this.$store.state.patient.thn

                },
                set(val) {
                    //var newval = val.toString().padStart(2, "0")
                    this
                        .$store
                        .commit("patient/update_thn", val)
                }
            },
            errors: {
                get() {
                    return this.$store.state.order.errors

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_errors", val)
                }
            },
            tgl: {
                get() {
                    return this.$store.state.patient.tgl

                },
                set(val) {
                    this
                        .$store
                        .commit("patient/update_tgl", val)
                }
            },
            bln: {
                get() {
                    return this.$store.state.patient.bln

                },
                set(val) {
                    this
                        .$store
                        .commit("patient/update_bln", val)
                }
            },
            thn: {
                get() {
                    return this.$store.state.patient.thn

                },
                set(val) {
                    this
                        .$store
                        .commit("patient/update_thn", val)
                }
            },
            ids: {
                get() {
                    return this.$store.state.order.ids

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_ids", val)
                }
            },
            selected_id: {
                get() {
                    return this.$store.state.order.selected_id

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_selected_id", val)
                }
            },
            pramitaid: {
                get() {
                    return this.$store.state.order.pramitaid

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_pramitaid", val)
                }
            },
            address: {
                get() {
                    return this.$store.state.order.address

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_address", val)
                }
            },
            ktp: {
                get() {
                    return this.$store.state.order.ktp

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_ktp", val)
                }
            },
            name: {
                get() {
                    return this.$store.state.order.name

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_name", val)
                }
            },
            job: {
                get() {
                    return this.$store.state.order.job

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_job", val)
                }
            },
            email: {
                get() {
                    return this.$store.state.order.email

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_email", val)
                }
            },
            sex: {
                get() {
                    return this.$store.state.order.sex

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_sex", val)
                    this.selected_title = {}
                }
            },
            selected_title: {
                get() {
                    return this.$store.state.order.selected_title

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_selected_title", val)
                }
            },
            dob: {
                get() {
                    return this.$store.state.order.dob

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_dob", val)
                }
            },
            kelurahans: {
                get() {
                    return this.$store.state.order.kelurahans

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_kelurahans", val)
                }
            },
            selected_kelurahan: {
                get() {
                    return this.$store.state.order.selected_kelurahan

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_selected_kelurahan", val)
                }
            },
            districts: {
                get() {
                    return this.$store.state.order.districts

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_districts", val)
                }
            },
            selected_district: {
                get() {
                    return this.$store.state.order.selected_district

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_selected_district", val)
                    this
                        .$store
                        .dispatch("order/getKelurahansRegister", val)
                }
            },
            cities: {
                get() {
                    return this.$store.state.order.cities

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_cities", val)
                }
            },
            selected_city: {
                get() {
                    return this.$store.state.order.selected_city

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_selected_city", val)
                    this
                        .$store
                        .dispatch("order/getDistrictsRegister", val)
                }
            },
            provinces: {
                get() {
                    return this.$store.state.order.provinces

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_provinces", val)
                }
            },
            selected_province: {
                get() {
                    return this.$store.state.order.selected_province

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_selected_province", val)
                    this
                        .$store
                        .dispatch("order/getCitiesRegister", val)
                }
            }
        },
        methods: {
            goToLogin(){
                var user = JSON.parse(localStorage.getItem("user"))
                this.show_login = true
                this.show_insert_password = false
                this.show_methode = false
                this.show_daftar = false
                this.show_daftar_otp = false
                if (user) 
                    this.login_hp = user.username
            },
            openPrivacyPolicy() {
                //console.log('dasdasd')
                this.dialog_privacy_policy_x = true
                //console.log(this.dialog_privacy_policy_x)
            },
            trackPosition() {
                if (navigator.geolocation) {
                    navigator
                        .geolocation
                        .getCurrentPosition(this.successPosition, this.failurePosition, {
                            enableHighAccuracy: true,
                            timeout: 15000,
                            maximumAge: 0
                        })
                } else {
                    console.log(`Browser doesn't support Geolocation`)
                }
            },
            successPosition: function (position) {
                this
                    .$store
                    .commit("beranda/update_lat", position.coords.latitude)
                this
                    .$store
                    .commit("beranda/update_lng", position.coords.longitude)
            },
            failurePosition: function (err) {
                this
                    .$store
                    .commit("beranda/update_lat", 0)
                this
                    .$store
                    .commit("beranda/update_lng", 0)
            },
            goToHome() {
                this
                    .$router
                    .push({name: 'xberanda'})
            },
            goToDetail() {
                this
                    .$router
                    .push({
                        name: 'xdetails',
                        params: {
                            branch: 1,
                            test: '008967'
                        }
                    })
            },
            getWidth() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return 320
                    case 'sm':
                        return 325
                    case 'md':
                        return 363
                    case 'lg':
                        return 363
                }
            },
            showDaftar() {
                console.log('aneh malem jumat')
                console.log(this.$store.state.login.daftar_hp)
                this.dialog_privacy_policy_x = false
                this.act = 'register'
                this.show_daftar = true
                this.show_login = false
                this.show_daftar_otp = false
                
                this.result_checking_hp = ''

                this.$store.commit("order/update_patient_id",0)
             this.tgl = ''
             this.bln = ''
             this.thn = ''
             this.ktp = ''
             this.name = ''
             this.sex = ''
             this.dob = ''
             this.email = ''
             this.hp = this.daftar_hp
             this.job = ''
             this.pramitaid = ''
             this.address = ''
             this.selected_province = {}
             this.selected_city = {}
             this.selected_district = {}
             this.selected_kelurahan = {}
             this.selected_id = {}
            this.selected_title = {}
            
            },
            daftar() {
                this.dialog_confirmation = true
            },
            showMethode() {
                this.show_daftar = false
                this.show_login = false
                this.dialog_confirmation = false
                this.show_methode = true
                this.show_daftar_otp = false
            },
            getOTP(methode) {
                this.show_methode = false
                let hp = this.act === 'login'
                    ? this.login_hp
                    : this
                    .daftar_hp
                    this
                    .$store
                    .commit("beranda/update_dialog_loading", true)
                this
                    .$store
                    .dispatch("login/getOTP", {
                        act: methode,
                        hp: hp
                    })
            },
            keyupOTP(event) {
                const value = event.target.value
                this.otp = value
                let hp = this.act === 'login'
                    ? this.login_hp
                    : this.daftar_hp
                if (value.length == 4) {
                    this
                        .$store
                        .commit("beranda/update_dialog_loading", true)
                    this
                        .$store
                        .dispatch("login/checkOTP", {
                            otp: this.otp,
                            hp: hp
                        })
                }
            },
            saveData() {
                if (this.$store.state.beranda.lat == 0 && this.$store.state.beranda.lng == 0) 
                    this.trackPosition()

                this.show_alert_password = false
                if (this.insert_password_password === this.insert_password_password_reenter) {
                    if (this.act === 'forgetpass') {
                        this
                            .$store
                            .commit("beranda/update_dialog_loading", true)
                        this
                            .$store
                            .dispatch("login/forgetPass", {
                                hp: this.insert_password_hp,
                                password: this.insert_password_password,
                                lat: this.$store.state.beranda.lat,
                                lng: this.$store.state.beranda.lng
                            })
                    }
                }
                    
               
            },
            checkingExist() {
                if (this.daftar_hp !== '') {
                    var hp = this
                        .daftar_hp
                        .replace('+', '')
                    var pref_hp = hp
                        .substring(0, 2)
                        .replace('62', 0)
                    var new_hp = pref_hp + hp.slice(2)
                    this.daftar_hp = new_hp
                    this.status_checking_hp = true
                    this.color_checking_hp = 'warning'
                    this.msg_checking_hp = 'Sedang mengecek nomor HP'
                    this
                        .$store
                        .commit("beranda/update_dialog_loading", true)
                    this
                        .$store
                        .dispatch("login/checkHP", {
                            hp: this.daftar_hp,
                            act: this.act
                        })
                }

            },
            checkLoading() {
                if (this.status_checking_hp) {
                    return this.color_checking_hp
                } else 
                    return false
            },
            forgetPassword() {
                this.show_login = false
                this.show_insert_password = false
                this.show_daftar = true
                this.show_daftar_otp = false
                this.act = 'forgetpass'
                this.daftar_hp = ''
                this.result_checking_hp = ''
            },
            login() {
                this.act = 'login'
                this.showMethode()
                // this.$store.dispatch("login/login",
                // {hp:this.login_hp,password:this.login_password})
            },
            checkValidEmail(){
                this.error_email = false
                this.error_email_msg = ''
                var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                if(!this.email.match(mailformat) && this.email !== ''){
                    this.error_email = true
                    this.error_email_msg = 'Email tidak valid'
                
                }
            },
            savePatient() {
                if (this.$store.state.beranda.lat == 0 && this.$store.state.beranda.lng == 0) 
                    this.trackPosition()
                var error = false
                
                this.error_dob = false
                this.error_dobyear = false
                var dob = this.tgl + '-' + this.bln + '-' + this
                    .thn
                    console
                    .log(dob)
                var check_dob = this
                    .$moment(dob, 'DD-MM-YYYY', true)
                    .isValid()
                var xYear = new Date().getFullYear()
                if(parseInt(this.thn) > parseInt(xYear)){
                    check_dob = false
                    this.error_dobyear = true
                }
                console.log(check_dob)
                if (!check_dob) 
                    this.error_dob = true
                this.checkIdentity()
                if(this.$refs.form.validate()  && check_dob && !this.error_ktp && this.insert_password_password === this.insert_password_password_reenter){
                    this.dialog_form = false
                    var patient = {
                        act: this.act,
                        idtype: this.selected_id,
                        patient_id: this.$store.state.order.patient_id,
                        ktp: this.ktp,
                        title: this.selected_title,
                        name: this.name,
                        sex: this.sex,
                        dob: this.thn + '-' + this.bln + '-' + this.tgl,
                        job: this.job,
                        pramitaid: this.pramitaid,
                        address: this.address,
                        province: this.selected_province,
                        city: this.selected_city,
                        district: this.selected_district,
                        kelurahan: this.selected_kelurahan,
                        hp: this.hp,
                        email:this.email,
                        hp: this.insert_password_hp,
                        password: this.insert_password_password,
                        lat: this.$store.state.beranda.lat,
                        lng: this.$store.state.beranda.lng
                    }
                    this
                        .$store
                        .commit("beranda/update_dialog_loading", true)
                    this
                        .$store
                        .dispatch("login/newUser", patient)
                }
                else{
                    if(this.insert_password_password !== this.insert_password_password_reenter){
                        this
                        .$store
                        .commit("beranda/update_api_error_message","Password dan Ulangi Password tidak cocok")
                    }
                }

            },
            validate() {
                this
                    .$refs
                    .form
                    .validate()
            },
            reset() {
                this
                    .$refs
                    .form
                    .reset()
            },
            resetValidation() {
                this
                    .$refs
                    .form
                    .resetValidation()
            },
            formatDate(date) {
                if (!date) 
                    return null

                const [year, month, day] = date.split('-')
                return `${day}-${month}-${year}`
            },
            parseDate(date) {
                if (!date) 
                    return null

                const [day, month, year] = date.split('-')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
            changeTgl(event) {
                //var val = event.target.value.toString().padStart(2, "0")
                this.tgl = event.target.value
                var dob = this.tgl + '-' + this.bln + '-' + this.thn
                var check_dob = this
                    .$moment(dob, 'DD-MM-YYYY', true)
                    .isValid()
                this.error_dob = false
                if (!check_dob) 
                    this.error_dob = true

            },
            changeBln(event) {
                this.bln = event.target.value
                var dob = this.tgl + '-' + this.bln + '-' + this.thn
                var check_dob = this
                    .$moment(dob, 'DD-MM-YYYY', true)
                    .isValid()
                this.error_dob = false
                if (!check_dob) 
                    this.error_dob = true
            },
            changeThn(event) {
                this.thn = event.target.value
                var dob = this.tgl + '-' + this.bln + '-' + this.thn
                var check_dob = this
                    .$moment(dob, 'DD-MM-YYYY', true)
                    .isValid()
                this.error_dob = false
                  this.error_dobyear = false
                var xYear = new Date().getFullYear()
                if(parseInt(this.thn) > parseInt(xYear)){
                    check_dob = false
                    this.error_dobyear = true
                }
                if (!check_dob) 
                    this.error_dob = true
            },
            checkIdentity(){
          var ktp = this.ktp
          this.error_ktp = false
          this.error_ktp_msg = ''
          if(parseInt(this.selected_id.id) === 1 ){
            if(ktp.length < 16){
              this.error_ktp = true
              this.error_ktp_msg = 'KTP tidak valid'
            }
      
            if(isNaN(ktp)){
              //console.log('dasds')
              this.error_ktp = true
              this.error_ktp_msg = 'Hanya numerik'
            }
          }

          if(parseInt(this.selected_id.id) === 3 ){
            if(ktp.length == 0){
              this.error_ktp = true
              this.error_ktp_msg = 'Passport tidak valid'
            }
          }
        },
        },
  watch: {
      date (val) {
        this.dateFormatted = this.formatDate(this.date)
      },
      tgl (val) {
        if(val.length == 2){
            this.$refs.input_bln.focus()
        }
      },
      bln (val) {
        if(val.length == 2){
            this.$refs.input_thn.focus()
        }
      },
    },
        data: vm => ({
            dialog_privacy_policy_x: false,
            show_alert_password: false,
            login_show_password: false,
            insert_password_show_password: false,
            insert_password_show_password_reenter: false,
            dialog_city: false,
            dialog_error: false,
            msg_error: '',
            reveal: false,
            dialog_branch: false,
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth,
            categories: [
                {
                    id: 1,
                    name: 'PCR',
                    active: false
                }, {
                    id: 1,
                    name: 'Swab Antigen',
                    active: false
                }
            ],
            selected_categories: [],
            show: false,

        dialog_rejected_add_test:false,
        rejected_add_test:[],
        dialog_form:false,
        search_province: '',
        search_city: '',
        search_district: '',
        search_kelurahan: '',
        error_dob: false,
        error_dobyear:false,
        error_ktp:false,
        error_ktp_msg:'',
        error_email:false,
        error_email_msg:'',
        date: '',
        dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
        value: '',
        valid: true,
        rulesdob: [
            v => !!vm
                .$moment(v, 'DD-MM-YYYY', true)
                .isValid() || 'Format salah, harusnya dd-mm-yyyy',
            v => !!v || 'Harus diisi'
        ]
    }),
    }
</script>