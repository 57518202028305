<template>
<div v-touch="{
      up: () => resizeheight(),
    }" class="bg-home" :style="{'width':window_width,'height':heightx+'px','background-image':'url('+require('../assets/gb-home.jpeg')+')'}" > 
              <v-img
                  style="margin-top:48%"
                  class="mx-auto"
                  :lazy-src="require('../assets/logo_pramita_panjang.png')"
                  :src="require('../assets/logo_pramita_panjang.png')"
                
                max-width="78%"
                ></v-img>
<v-card @click="goToLogin()" style="margin-top:22%" width="210px" color="#e0282c" dark  class="mx-auto pa-3  rounded-pill">
              <div style="font-size:18px" class="text-center semi-poppins">Masuk / Daftar</div>
            </v-card>
<v-card @click="goToHome()" tile style="margin-top:8%" width="210px" color="#e0282c" dark  class="mx-auto pa-3  rounded-pill">
              <div style="font-size:18px" class="text-center semi-poppins">Cari Tau</div>
            </v-card>
            <!--<v-card  style="margin-top:7%;border:2px solid #e0282c!important" width="210px" color="white"   class="mx-auto pa-3  rounded-pill">
              <div style="font-size:18px" class="text-center semi-poppins">Cari Tau</div>
            </v-card>-->

</div>
          
            <!--<v-card
              class="mx-auto pb-5"
              flat
            >
              <p class="ma-0 pb-2"></p>
              <v-img
                  class="mx-auto"
                  :lazy-src="require('../assets/pramita_kotak.png')"
                  :src="require('../assets/pramita_kotak.png')"
                  width="100"
                  height="50"
                ></v-img>
              <div class="mt-4 pa-5" dstyle="background-color:#fafafaeb;">
              <v-img
                  class=" pl-4 pr-4"
                  :lazy-src="require('../assets/welcome-img.png')"
                  :src="require('../assets/welcome-img.png')"
                   height="190"
                  contain
                ></v-img>
              </div>

              <v-card-title class="body-1 font-weight-bold mt-2">Selamat datang di Layanan Pramita Online</v-card-title>

              <v-card-text>
                <p class="body-1">Salam Sehat</p>
                <p class="text-justify">
                  Jadilah salah satu member kami dan  temukan cara mudah mendaftarkan pemeriksaan COVID-19 secara OnLine di Pramita Lab, tanpa antri dan tetap menjalankan protokol kesehatan. 
Anda akan mendapatkan urutan layanan pemeriksaan dengan jadwal yang lebih pasti sehingga Anda bisa menghemat waktu dan tenaga.
                </p>
              </v-card-text>
              <v-card-actions style="background:#fffff">
                <v-row class="mt-0">
                  <v-col class="pt-0" cols="6">
                 <v-btn
                                        rounded
                                        dark
                                        @click="goToLogin()"
                                        color="red"
                                        block
                                    >
                                    LOGIN / DAFTAR
                                    </v-btn>
                  </v-col>
                  <v-col class="pt-0" cols="6">
                <v-btn
                                        rounded
                                        dark
                                        @click="goToHome()"
                                        color="info"
                                        block
                                    >
                                    EXPLORE
                                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>-->

            
         

      
                
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
.semi-poppins{
  font-family: 'Poppins', sans-serif;
  font-weight:600;
}
.bg-home{
overflow-y: hidden;

background-position: 'center center';
  background-repeat: repeat;
  background-size: cover;

}

.fixed-bottom-bg-no-shadow{
        position: fixed;
        left: 50%;
        bottom: -46px;
        transform: translate(-50%, -50%);
        margin: 0 auto;
      
    }
.box-center{
  position: fixed;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}
@media only screen and (min-device-width : 1024px){
/* Styles */
/* width */
::-webkit-scrollbar {
  height: 8px;              /* height of horizontal scrollbar ← You're missing this */
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
}

</style>
<script>


  export default {
    name: 'xwelcome',
    mounted(){
      console.log('break : '+this.$vuetify.breakpoint.name)
      console.log(window.innerWidth)
       var user = JSON.parse(localStorage.getItem("user"))
      if (user && user.token != ''){
         this.$router.push({name:'xberanda'})
      }

      

    },
    computed:{
      heightx:{
        get() {
            return window.innerHeight
                
        },
        set(val) {
              this.$store.commit("beranda/update_heightx",val)
        }
      },
      user:{
        get() {
            return this.$store.state.beranda.user
                
        },
        set(val) {
              this.$store.commit("beranda/update_user",val)
        }
      },
      selected_branch:{
        get() {
            return this.$store.state.choosebranch.selected_branch
                
        },
        set(val) {
              this.$store.commit("choosebranch/update_selected_branch",val)
        }
      },
      lat:{
        get() {
            return this.$store.state.beranda.lat
                
        },
        set(val) {
              this.$store.commit("beranda/update_lat",val)
        }
      },
      lng:{
        get() {
            return this.$store.state.beranda.lng
                
        },
        set(val) {
              this.$store.commit("beranda/update_lng",val)
        }
      },
      window_width(){
      
            if(this.$vuetify.breakpoint.name == 'xs'){
            return window.innerWidth
          }
          else
            return '560px'
                
        
      },
    },
    methods:{
      resizeheight(){
        this.heightx = this.heightx 
        console.log('resizeheight')
        console.log(this.heightx)
      },
        goToLogin(){
          this.$router.push({name:'xlogin'})
        },
        goToHome(){
          this.$router.push({name:'xberanda'})
        },
        login(act){
          if(act === 'daftar'){
            this.$router.push({name:'xlogin',params:{act:act}})
          }
          else
          this.$router.push({name:'xlogin'})
        },
        getWIdth(){
          switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 320
            case 'sm': return 325
            case 'md': return 363
            case 'lg': return 363
          }
        },
        listorder(){
          this.$router.push({ name: 'xlistorder'})
        },
        logout(){
          var user =  JSON.parse(localStorage.getItem("user"))
          user.lat = this.$store.state.beranda.lat
          user.lng = this.$store.state.beranda.lng
          this.$store.dispatch("login/logout", user)
        }
    },
    data: () => ({
      windowHeight: window.innerHeight,
            windowWidth: window.innerWidth,
      isLogin:false,
      isTrue:false,
      dialog_city:false,
      dialog_error:false,
      msg_error:'',
      reveal:false,
      dialog_branch:false,
      selected_city: {},
      categories:[{id:1,name:'PCR',active:false},{id:1,name:'Swab Antigen',active:false}],
      selected_categories:[],
      show: false,
      cities: [
          { name: 'Surabaya'},
          { name: 'Madiun'},
          { name: 'Salatiga'},
          { name: 'Magelang'},
          { name: 'Yogyakarta'},
          { name: 'Bandung'},
        ],
    }),
  }
</script>
