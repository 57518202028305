<template>
  <div style="overflow-x:hidden;">
        <v-dialog
            v-model="dialog_form"
            persistent="persistent"
            scrollable="scrollable"
            fullscreen="fullscreen"
            hide-overlay="hide-overlay"
            transition="dialog-bottom-transition">
            <v-card class="mx-auto" :height="windowHeight">
                <v-toolbar
                    dark
                    dense="dense"
                    tile="tile"
                    color="#e0282c">
                    <v-btn icon="icon"  @click="dialog_form = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>FORM PASIEN</v-toolbar-title>
                    <v-spacer></v-spacer>

                </v-toolbar>
                <v-card-text :style="{height: windowHeight}">
                    <v-container>
                        <v-row class="pr-4">
                            <v-col cols="12">

                                <v-form ref="form" v-model="valid" lazy-validation="lazy-validation">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-select
                                                :items="ids"
                                                hide-details="auto"
                                                label="Tipe Identitas"
                                                @change="checkIdentity()"
                                                v-model="selected_id"
                                                :rules="[v => !_.isEmpty(v)|| 'Pilih salah satu']"
                                                item-text="name"
                                                return-object="return-object"
                                                required="required"></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field
                                                v-model="ktp"
                                                hide-details="auto"
                                                :rules="[v => !!v || 'Harus diisi']"
                                                label="Nomor Identitas"
                                                hint="* jika anak-anak diisi nomor KK"
                                                persistent-hint="persistent-hint"
                                                @change="checkIdentity()"
                                                required="required"></v-text-field>
                                                <p :class="{'error--text':error_ktp}" class="caption mb-0">{{error_ktp_msg}}</p>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <div>Jenis Kelamin</div>
                                            <v-radio-group
                                                class="mt-0 pt-2"
                                                v-model="sex"
                                                hide-details="auto"
                                                required="required"
                                                :rules="[v => !!v || 'Pilih salah satu']"
                                                row="row">
                                                <v-radio label="Laki-laki" value="L"></v-radio>
                                                <v-radio label="Perempuan" value="P"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" sm="4" md="2">
                                            <v-select
                                                :items="titles"
                                                item-text="name"
                                                :rules="[v => !_.isEmpty(v)|| 'Harus diisi']"
                                                return-object="return-object"
                                                label="Sapaan"
                                                v-model="selected_title"
                                                required="required"></v-select>
                                        </v-col>
                                        <v-col cols="8" sm="8" md="10">
                                            <v-text-field
                                                v-model="name"
                                                :rules="[v => !!v || 'Harus diisi']"
                                                label="Nama Lengkap"
                                                hide-details="auto"
                                                required="required"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row align="center">
                                        <v-col cols="12">
                                            <p class="mb-0">Tanggal Lahir</p>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-0 pt-0" align="center">
                                        <v-col class="mt-0 pt-0 mr-0 pr-0" cols="4">
                                            <v-text-field
                                                label="Tgl"
                                                ref="input_tgl"
                                                type="number"
                                                outlined="outlined"
                                                @blur="changeTgl($event)"
                                                clearable="clearable"
                                                dense="dense"
                                                v-model="tgl"
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                        <v-col class="mt-0 pt-0 mr-0 pr-0" cols="4">
                                            <v-text-field
                                                label="Bln"
                                                ref="input_bln"
                                                type="number"
                                                outlined="outlined"
                                                @blur="changeBln($event)"
                                                clearable="clearable"
                                                dense="dense"
                                                v-model="bln"
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                        <v-col class="mt-0 pt-0 pr-0" cols="4">
                                            <v-text-field
                                                label="Thn"
                                                type="number"
                                                ref="input_thn"
                                                @blur="changeThn($event)"
                                                outlined="outlined"
                                                clearable="clearable"
                                                dense="dense"
                                                v-model="thn"
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row align="center">
                                        <v-col class="mt-0 pt-0 pb-0 mb-0" cols="12">
                                            <p :class="{'error--text':error_dob}" class="caption mb-0">Format DD-MM-YYYY, contoh : 01-06-1988</p>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="hp"
                                                :rules="[v => !!v || 'Harus diisi']"
                                                label="No. HP"
                                                required="required"
                                                hint="contoh : 081123321111"
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="email"
                                                label="Alamat Email"
                                                @blur="checkValidEmail()"
                                                hide-details="auto"></v-text-field>
                                                <p :class="{'error--text':error_email}" class="caption mb-0">{{error_email_msg}}</p>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="job"
                                                :rules="[v => !!v || 'Harus diisi']"
                                                label="Pekerjaan"
                                                required="required"
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <!--<v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="pramitaid"
                                                label="Pramita ID"
                                                hint="Isi jika ada"
                                                persistent-hint="persistent-hint"
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                    </v-row>-->
                                    <v-row>
                                        <v-col cols="12">
                                            <v-autocomplete
                                                v-model="selected_province"
                                                :rules="[v => !_.isEmpty(v)|| 'Harus diisi']"
                                                :items="provinces"
                                                :search-input.sync="search_province"
                                                item-text="name"
                                                clearable="clearable"
                                                return-object="return-object"
                                                required="required"
                                                clear-icon="fa-times"
                                                label="Propinsi"
                                                hide-details="auto"></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-autocomplete
                                                v-model="selected_city"
                                                :rules="[v => !_.isEmpty(v)|| 'Harus diisi']"
                                                :items="cities"
                                                :search-input.sync="search_city"
                                                item-text="name"
                                                clearable="clearable"
                                                return-object="return-object"
                                                required="required"
                                                clear-icon="fa-times"
                                                label="Kota"
                                                hide-details="auto"></v-autocomplete>

                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-autocomplete
                                                v-model="selected_district"
                                                :rules="[v => !_.isEmpty(v)|| 'Harus diisi']"
                                                :items="districts"
                                                :search-input.sync="search_district"
                                                item-text="name"
                                                clearable="clearable"
                                                return-object="return-object"
                                                required="required"
                                                clear-icon="fa-times"
                                                label="Kecamatan"
                                                hide-details="auto"></v-autocomplete>

                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-autocomplete
                                                v-model="selected_kelurahan"
                                                :rules="[v => !_.isEmpty(v)|| 'Harus diisi']"
                                                :items="kelurahans"
                                                :search-input.sync="search_kelurahan"
                                                item-text="name"
                                                clearable="clearable"
                                                return-object="return-object"
                                                required="required"
                                                clear-icon="fa-times"
                                                label="Kelurahan"
                                                hide-details="auto"></v-autocomplete>

                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-textarea
                                                clearable="clearable"
                                                clear-icon="mdi-close"
                                                label="Alamat"
                                                hide-details="auto"
                                                v-model="address"
                                                :rules="[v => !!v || 'Harus diisi']"
                                                required="required"></v-textarea>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-btn
                                               :disabled="!valid || error_dob || error_ktp || _.isEmpty(selected_title) || error_email"
                                                color="warning ligthen-2"
                                                block="block"
                                                class="black--text pa-5 mt-5"
                                                @click="savePatient()">
                                                Simpan
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>

                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_rejected_add_test"
            width="500"
            >

            <v-card>
                <v-card-title class="headline grey lighten-2">
                Perhatian
                </v-card-title>

                <v-card-text>
                    <div v-if="rejected_add_test.length > 0">
                        <p v-for="(reject,kr) in rejected_add_test" :key="kr">
                            Pemeriksaan {{reject.test.name}} tidak bisa ditambahkan ke pasien {{reject.patient.M_PatientName}} karna ada pemeriksaan yang sama
                        </p>
                    </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="dialog_rejected_add_test = false"
                >
                    Tutup
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--<v-app-bar
                height="85px"
                dark
                flat
                
                color="#e42e2a"
                fixed
                style="overflow-x:hidden"
                class="pa-0 ma-0"
                >
                <v-toolbar-title class="ml-0 mb-5">
                    <v-btn icon="icon" dark="dark" @click="goToBefore()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Keranjang</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items></v-toolbar-items>
                </v-toolbar>
                </v-app-bar>-->

                 <v-app-bar
       height="65px"
      dark
      
    
      color="#e42e2a"
      fixed
      style="overflow-x:hidden"
      class="pa-0 ma-0"
    >

    <v-icon class="mr-2" @click="goToBefore()">mdi-close</v-icon>


      <v-toolbar-title >
          Keranjang
      </v-toolbar-title>

      <v-spacer class="mr-2"></v-spacer>
         
    </v-app-bar>
    <div style="overflow-x:hidden;" class="overflow-y-auto">
            <v-card class="mt-14">
                
                <v-row >
                    <v-col class="mb-0 pb-0" cols="12">
                        <v-card class="ma-2 mt-0 pt-3">
                            <v-row class="mb-5">
                                <v-col class="pt-0 pb-0" cols="2"></v-col>
                                <v-col class="pt-0 pb-0 text-right pr-5" cols="10">
                                    <v-btn
                                        @click="goToBefore()"
                                        small
                                        color="#e0282c"
                                        dark
                                        class="poppins-light"
                                        >
                                        + Pemeriksaaan
                                        </v-btn>
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="selected_tests"
                                v-for="(seltest, kseltest) in selected_tests"
                                :key="kseltest"
                                class="ma-2 pb-0">
                                <v-col class="pt-2 pb-2 " cols="10">
                                    <v-checkbox color="red" hide-details class="pa-0 ma-0" v-model="seltest.chx">
                                        <template v-slot:label>
                                            <div class="ml-2">
                                            <p style="font-size:14px" class="semi-poppins mb-1">{{seltest.name}}</p>
                                            <p style="font-size:12.9px" class="mb-1 poppins-light">{{formatRupiah(seltest.price)}}</p>
                                            </div>
                                        </template>
                                        </v-checkbox>
                                    <!--<p class="mb-1">{{seltest.name}}</p>
                                    <p class="mb-1">{{formatRupiah(seltest.price)}}</p>-->
                                </v-col>
                                <v-col class="text-right" cols="2">
                                    <v-btn @click="removeTest(seltest)" text="text">
                                        <v-icon small>fa-trash</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-if="selected_tests.length === 0" class="ma-2 pb-0">
                                <v-col cols="12">
                                    <p class="mb-1 poppins-light">Silahkan pilih kembali pemeriksaan untuk dimasukkan ke keranjang</p>
                                </v-col>

                            </v-row>
                            <v-divider></v-divider>
                            <v-row v-if="selected_tests.length !== 0">
                                <v-col cols="12">
                                    <v-row no-gutters="no-gutters">
                                                <v-col class="mt-0 mb-3" cols="8">
                                                    <p  style="font-size:16px" class="mb-2 ml-5 bold-poppins">
                                                        Silahkan Pilih Pasien
                                                    </p>
                                                </v-col>
                                                <v-col class="text-right pr-2" cols="4">
                                                    <v-btn
                                        @click="openForm('new')"
                                        small
                                        color="#e0282c"
                                        dark
                                        class="poppins-light"
                                        >
                                        + Pasien
                                        </v-btn>
                                                </v-col>
                                    </v-row>
                                     
                                    <v-card flat="flat">
                                        <v-card-text class="pa-3 pt-0">
                                            <p class="ml-3" v-if="loading_patients">Sedang mengakses data pasien ...</p>
                                            <v-list v-if="!loading_patients" two-line="two-line">
                                                <template v-for="patient in patients">
                                                    <div :style="{'background':checkExist(patient)?'#faebd7':'#fff'}">
                                                        <v-list-item @click="thisPatient(patient)">
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    <p style="font-size:14px" class="mb-1 bold-poppins" :class="{'red--text':checkExist(patient)}">{{patient.name}}</p>
                                                                </v-list-item-title>
                                                                <v-list-item-subtitle style="font-size:12px" class="poppins-light" v-text="patient.M_PatientIDNumber"></v-list-item-subtitle>
                                                            </v-list-item-content>
                                                            <v-list-item-action>
                                                                <v-icon v-if="checkExist(patient) === false">mdi-checkbox-blank-outline</v-icon>
                                                                <v-icon color="red accent-4" v-if="checkExist(patient)">mdi-checkbox-marked</v-icon>
                                                            </v-list-item-action>
                                                        </v-list-item>
                                                    </div>
                                                </template>
                                            </v-list>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-divider v-if="selected_tests.length !== 0"></v-divider>
                            <v-row v-if="selected_tests.length !== 0" class="pa-2">
                                <v-col class="pa-2 pl-3 pr-3" cols="12">
                                    <v-btn  v-if="!show_btn_add_cart" depressed disabled block>
                                        Masukkan Keranjang
                                    </v-btn>
                                    <v-btn v-if="show_btn_add_cart" @click="addCart()" color="#e0282c" dark block>
                                        Masukkan Keranjang
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-card elevation="10" class="ma-2">
                            <v-card-title style="font-size:18px" class="bold-poppins">
                                Keranjang Anda
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <div v-if="cart.length > 0">
                                    <v-row  v-for="(xorderpatient,kxorpat) in cart" :key="kxorpat">
                                        <v-col cols="12">
                                            <v-row no-gutters>
                                                <v-col cols="10">
                                                    <p class="mb-1 h6 font-weight-bold">{{xorderpatient.patient.name}}</p>
                                                </v-col>
                                                <v-col class="text-right" cols="2">
                                                    <v-btn @click="removeOrderPatient(kxorpat,xorderpatient)" text="text">
                                                        <v-icon small>fa-trash</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-2" v-for="(order,korder) in xorderpatient.order" :key="korder">
                                                <v-col cols="10">
                                                    <p class="mb-1">{{order.name}}</p>
                                                    <p class="mb-1">{{formatRupiah(order.price)}}</p>
                                                </v-col>
                                                    <v-col class="text-right" cols="2">
                                                    <v-btn @click="removeOrderTest(kxorpat,korder,order)" text="text">
                                                        <v-icon small>fa-trash</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-divider></v-divider>
                                    <v-row align="center">
                                        <v-col class="pa-2" cols="12">
                                            <v-btn  @click="goToSettingSchedule()" color="#e0282c" dark block>
                                                Lanjutkan
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div class="poppins-light" v-if="cart.length === 0">Belum ada yang dimasukkan keranjang</div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

            </v-card>
    </div>
  </div>
</template>
<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins');
.poppins-bold{
  font-family: 'Poppins', sans-serif;
  font-weight:800;
}
.semi-poppins{
  font-family: 'Poppins', sans-serif;
  font-weight:600;
}
.poppins-light{
  font-family: 'Poppins', sans-serif;
  font-weight:300;
}

.red-text-default{
    color:#e0282c
}
</style>
<script>
  export default {
    name: 'xcarths',
    mounted() {
            //this.$store.dispatch("choosebranch/getCategories")
            this.$store.dispatch("test/getDeliveries")
            this
                    .$store
                    .commit("test/update_loading_patients", true)
            this
                        .$store
                        .dispatch("test/getPatients",{})
                         var localstorage = JSON.parse(localStorage.getItem("oneState"))
                         this.localstoragex = localstorage
            //this.selected_branch = localstorage.choosebranch.selected_branch
            this.$store.commit("choosebranch/update_selected_branch", localstorage.choosebranch.selected_branch)
            this.$store.commit("choosebranch/update_selected_hs_address",localstorage.choosebranch.selected_hs_address)
            this.cart = localstorage.test.cart
            this.selected_tests = localstorage.test.selected_tests
           // this.selected_category = localstorage.choosebranch.selected_category
           // this.categories = localstorage.choosebranch.categories

        },
    computed:{
        loading_patients: {
            get() {
                return this.$store.state.test.loading_patients

            },
            set(val) {
                this
                    .$store
                    .commit("test/update_loading_patients", val)
            }
        },
        deliveries: {
            get() {
                return this.$store.state.test.deliveries

            },
            set(val) {
                this
                    .$store
                    .commit("test/update_deliveries", val)
            }
        },
        selected_patient: {
            get() {
                return this.$store.state.test.selected_patient

            },
            set(val) {
                this
                    .$store
                    .commit("test/update_selected_patient", val)
            }
        },
        patients: {
            get() {
                return this.$store.state.test.patients

            },
            set(val) {
                this
                    .$store
                    .commit("test/update_patients", val)
            }
        },
        show_btn_add_cart(){
            var selected_tests = this.selected_tests
            var selected_patient = this.selected_patient
            if(selected_tests.length > 0 && selected_patient.length > 0){
                return true
            }
            else
                return false
        },
        dialog_cart:{
            get() {
                return this.$store.state.beranda.dialog_cart
                    
            },
            set(val) {
                this.$store.commit("beranda/update_dialog_cart",val)
            }
        },
        cart: {
            get() {
                return this.$store.state.test.cart

            },
            set(val) {

                this
                    .$store
                    .commit("test/update_cart", val)
            }
        },
        selected_tests: {
            get() {
                return this.$store.state.test.selected_tests

            },
            set(val) {
                this
                    .$store
                    .commit("test/update_selected_tests", val)
            }
        },
        hp: {
                get() {
                    return this.$store.state.order.hp

                },
                set(val) {
                    var hp = val.replace('+', '')
                    var pref_hp = hp
                        .substring(0, 2)
                        .replace('62', 0)
                    var new_hp = pref_hp + hp.slice(2)
                    this
                        .$store
                        .commit("order/update_hp", new_hp)
                }
            },
            titles() {
                if (!this.sex || this.sex === '') {
                    return []
                } else {
                    var titles = this.$store.state.order.titles
                    var sex = this.sex
                    var xvalue = _.filter(titles, {'sex': sex})

                    return xvalue
                }
            },
            tgl: {
                get() {
                    return this.$store.state.patient.tgl

                },
                set(val) {

                    this
                        .$store
                        .commit("patient/update_tgl", val)
                }
            },
            bln: {
                get() {
                    return this.$store.state.patient.bln

                },
                set(val) {

                    this
                        .$store
                        .commit("patient/update_bln", val)
                }
            },
            thn: {
                get() {
                    return this.$store.state.patient.thn

                },
                set(val) {
                    //var newval = val.toString().padStart(2, "0")
                    this
                        .$store
                        .commit("patient/update_thn", val)
                }
            },
            errors: {
                get() {
                    return this.$store.state.order.errors

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_errors", val)
                }
            },
            tgl: {
                get() {
                    return this.$store.state.patient.tgl

                },
                set(val) {
                    this
                        .$store
                        .commit("patient/update_tgl", val)
                }
            },
            bln: {
                get() {
                    return this.$store.state.patient.bln

                },
                set(val) {
                    this
                        .$store
                        .commit("patient/update_bln", val)
                }
            },
            thn: {
                get() {
                    return this.$store.state.patient.thn

                },
                set(val) {
                    this
                        .$store
                        .commit("patient/update_thn", val)
                }
            },
            ids: {
                get() {
                    return this.$store.state.order.ids

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_ids", val)
                }
            },
            selected_id: {
                get() {
                    return this.$store.state.order.selected_id

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_selected_id", val)
                }
            },
            pramitaid: {
                get() {
                    return this.$store.state.order.pramitaid

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_pramitaid", val)
                }
            },
            address: {
                get() {
                    return this.$store.state.order.address

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_address", val)
                }
            },
            ktp: {
                get() {
                    return this.$store.state.order.ktp

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_ktp", val)
                }
            },
            name: {
                get() {
                    return this.$store.state.order.name

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_name", val)
                }
            },
            job: {
                get() {
                    return this.$store.state.order.job

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_job", val)
                }
            },
            email: {
                get() {
                    return this.$store.state.order.email

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_email", val)
                }
            },
            sex: {
                get() {
                    return this.$store.state.order.sex

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_sex", val)
                    this.selected_title = {}
                }
            },
            selected_title: {
                get() {
                    return this.$store.state.order.selected_title

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_selected_title", val)
                }
            },
            dob: {
                get() {
                    return this.$store.state.order.dob

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_dob", val)
                }
            },
            kelurahans: {
                get() {
                    return this.$store.state.order.kelurahans

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_kelurahans", val)
                }
            },
            selected_kelurahan: {
                get() {
                    return this.$store.state.order.selected_kelurahan

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_selected_kelurahan", val)
                }
            },
            districts: {
                get() {
                    return this.$store.state.order.districts

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_districts", val)
                }
            },
            selected_district: {
                get() {
                    return this.$store.state.order.selected_district

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_selected_district", val)
                    this
                        .$store
                        .dispatch("order/getKelurahans", val)
                }
            },
            cities: {
                get() {
                    return this.$store.state.order.cities

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_cities", val)
                }
            },
            selected_city: {
                get() {
                    return this.$store.state.order.selected_city

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_selected_city", val)
                    this
                        .$store
                        .dispatch("order/getDistricts", val)
                }
            },
            provinces: {
                get() {
                    return this.$store.state.order.provinces

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_provinces", val)
                }
            },
            selected_province: {
                get() {
                    return this.$store.state.order.selected_province

                },
                set(val) {
                    this
                        .$store
                        .commit("order/update_selected_province", val)
                    this
                        .$store
                        .dispatch("order/getCities", val)
                }
            },
    },
    methods:{
        goToBefore(){
            var xstate = {}
            xstate.beranda = this.$store.state.beranda
            xstate.order = this.$store.state.order
            xstate.choosebranch = this.$store.state.choosebranch
            xstate.test = this.$store.state.test
            localStorage.setItem('oneState', JSON.stringify(xstate));

            this.$router.push({name:'xsearchtesths'})
        },
        checkValidEmail(){
            this.error_email = false
            this.error_email_msg = ''
            var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            if(!this.email.match(mailformat) && this.email !== ''){
                this.error_email = true
                this.error_email_msg = 'Email tidak valid'
               
            }
        },
        formatRupiah(angka, prefix) {
            var number_string = angka.toString(),
                sisa = number_string.length % 3,
                rupiah = number_string.substr(0, sisa),
                ribuan = number_string
                    .substr(sisa)
                    .match(/\d{3}/gi);

            // tambahkan titik jika yang di input sudah menjadi angka satuan ribuan
            if (ribuan) {
                let separator = sisa
                    ? '.'
                    : '';
                rupiah += separator + ribuan.join('.');
            }

            return 'Rp' + rupiah
        },
        removeTest(test) {
            var selected_tests = this.selected_tests
            var idx = _.findIndex(selected_tests, test)
            selected_tests.splice(idx, 1)
        },
        checkExist(patient) {
            let selected = this.selected_patient
            let idx = _.findIndex(selected, function (o) {
                return o.M_PatientID == patient.M_PatientID
            })
            if (idx == -1) {
                return false
            } else {
                return true
            }
        },
        thisPatient(patient) {
            let selected = this.selected_patient
            let idx = _.findIndex(selected, function (o) {
                return o.M_PatientID == patient.M_PatientID
            })
            if (idx == -1) {
                selected.push(patient)
                this.selected_patient = selected
            } else {
                selected.splice(idx, 1)
            }
        },
        openForm(act) {
            //this.$store.dispatch("choosebranch/getCategories")
            if (act === 'new') {
                this
                    .$store
                    .commit("order/update_patient_id", 0)
                
            }
        },
        addCart(){
                var xlocalstate = JSON.parse(localStorage.getItem("oneState"))
                console.log('we wei')
                console.log(xlocalstate)
                var checked_test = _.filter(this.selected_tests, function(o) { return o.chx });
                //console.log(checked_test)
                if(checked_test.length > 0){
                    var deliveries = this.deliveries
                    var cart_deliveries = []
                    let idelivery = -1

                    while (++idelivery < deliveries.length) {
                        cart_deliveries[idelivery] = deliveries[idelivery];
                    }

                    var selected_tests = checked_test
                    var selected_patient = JSON.parse(JSON.stringify(this.selected_patient));
                    var cart = JSON.parse(JSON.stringify(this.cart));
                    var rejected_add_test = []
                    console.log(selected_patient)
                    selected_patient.forEach(function (new_patient,knewpat) {

                        var idx_cart = -1
                        cart.forEach(function (valuecart,keycart){
                            if(parseInt(valuecart.patient.M_PatientID) === parseInt(new_patient.M_PatientID)){
                                idx_cart = keycart
                            }
                        })
                        if(idx_cart > -1){
                           // console.log('same patient')
                            checked_test.forEach(function (new_test) {
                                
                                    var new_nat_test = new_test.nat_test
                                    var found_exist_nat_test = false
                                    cart[idx_cart].nat_test.forEach(function (now_nat_test_item) {
                                        var check_exist_nat_test = _.filter(new_nat_test, function (o_nat_test) {
                                            return parseInt(o_nat_test) === parseInt(now_nat_test_item)
                                        })
                                        if(check_exist_nat_test.length > 0){
                                            found_exist_nat_test = true
                                        }
                                    })
                                    
                                    if(!found_exist_nat_test){
                                        console.log('different test')
                                        if(new_test.is_covid == 'Y')
                                            cart[idx_cart].is_covid = true
                                    
                                        cart[idx_cart].order.push(new_test)
                                        new_nat_test.forEach(function (new_nat_test_item) {
                                            cart[idx_cart].nat_test.push(new_nat_test_item)
                                        })
                                    }
                                    else{
                                        var reject_test = {patient :cart[idx_cart].patient, test : new_test }
                                        rejected_add_test.push(reject_test)
                                    }
                                
                            })
                        }
                        else{
                           // console.log('different pasien')
                                var nat_test_to_add = []
                                let is_covid = false
                                checked_test.forEach(function (xtest) {
                                    
                                        if(xtest.is_covid == 'Y')
                                            is_covid = true
                                            xtest.nat_test.forEach(function (xnattest) {
                                            nat_test_to_add.push(xnattest)
                                        })
                                    
                                    
                                })

                                var new_deliveries = []
                                //console.log('ni nii')
                                //console.log(xlocalstate.choosebranch.selected_branch.name)
                                //console.log(xlocalstate)
                                cart_deliveries.forEach(deliv => {
                                    let new_deliv = JSON.parse(JSON.stringify(deliv))
                                    if(parseInt(new_deliv.M_DeliveryID) == 1){
                                        new_deliv.xvalue = xlocalstate.choosebranch.selected_branch.label
                                    }
                                    if(parseInt(new_deliv.M_DeliveryID) == 3){
                                        new_deliv.xvalue = new_patient.M_PatientEmail
                                    }
                                    if(parseInt(new_deliv.M_DeliveryID) == 6){
                                        new_deliv.xvalue = new_patient.M_PatientHp
                                    }
                                    new_deliv.selected = new_deliv.selected  === 'Y' ? true:false

                                    new_deliveries.push(new_deliv)
                                })
                                var new_cart = {
                                    is_klinisi:false,
                                    is_covid:is_covid,
                                    covid_purpose:{},
                                    patient : new_patient,
                                    nat_test : nat_test_to_add,
                                    order : selected_tests,
                                    deliveries:new_deliveries
                                }
                                cart.push(new_cart)
                        }
                    })
                    //console.log(cart)
                    
           
                    
                
                    /*cart.forEach(function (vcart,kcart){
                        console.log(vcart.patient.M_PatientEmail)
                        console.log("-------------")
                        var xnowemail = vcart.patient.M_PatientEmail
                        var xnowhp = vcart.patient.M_PatientHp
                        vcart.deliveries.forEach(function (vdev,kdev){
                            if(parseInt(vdev.M_DeliveryID) == 1){
                                cart[kcart].deliveries[kdev].xvalue = xlocalstate.choosebranch.selected_branch.name
                            }
                            if(parseInt(vdev.M_DeliveryID) == 3){
                                cart[kcart].deliveries[kdev].xvalue .xvalue = xnowemail
                            }
                            if(parseInt(vdev.M_DeliveryID) == 6){
                                cart[kcart].deliveries[kdev].xvalue .xvalue = xnowhp
                            }
                            vdev.selected = vdev.selected  === 'Y' ? true:false
                            console.log(cart[kcart].deliveries[kdev].xvalue )
                            console.log("+++++++++++")
                        })
                    })*/

                    this.cart = cart

                    var xstate = {}
                    this.$store.commit("beranda/update_order_hs",true)
                    xstate.beranda = this.$store.state.beranda
                    xstate.order = this.$store.state.order
                    xstate.choosebranch = this.$store.state.choosebranch
                    xstate.test = this.$store.state.test
                    localStorage.setItem('oneState', JSON.stringify(xstate));
                    var nowseltest = _.filter(this.selected_tests, function(o) { return !o.chx });
                    this.selected_tests = nowseltest
                    this.selected_patient = []
                    this.rejected_add_test = rejected_add_test
                    if(rejected_add_test.length > 0){
                        this.dialog_rejected_add_test = true
                    }
                }else{
                    this
                        .$store
                        .commit("beranda/update_api_error_message","Centang satu atau beberapa pemeriksaan terlebih dahulu")
                }

                

            },
            removeOrderPatient(kcart,orderpatient){
                var cart = JSON.parse(JSON.stringify(this.cart))
                cart.splice(kcart, 1)
                this.cart = cart
            },
            removeOrderTest(kcart,korder,order){
                var cart = JSON.parse(JSON.stringify(this.cart))
                cart[kcart].order.splice(korder, 1)
                if(cart[kcart].order.length > 0){
                    var nat_test_new = []
                    var is_covid = 'N'
                    cart[kcart].order.forEach(function (xtest) {
                        if(xtest.is_covid === 'Y')
                            is_covid = 'Y'
                        xtest.nat_test.forEach(function (xnattest) {
                            nat_test_new.push(xnattest)
                        })
                    })
                    cart[kcart].is_covid = is_covid
                    cart[kcart].nat_test = nat_test_new
                }
                else{
                    cart.splice(kcart, 1)
                }
                
                this.cart = cart
            },
            openForm(act) {
                if (act === 'new') {
                    this
                        .$store
                        .commit("order/update_patient_id", 0)
                    this.ktp = ''
                    this.name = ''
                    this.sex = ''
                    this.tgl = ''
                    this.bln = ''
                    this.thn = ''
                    this.hp = ''
                    this.email = ''
                    this.job = ''
                    this.pramitaid = ''
                    this.address = ''
                    this.selected_province = {}
                    this.selected_city = {}
                    this.selected_district = {}
                    this.selected_kelurahan = {}
                    this.selected_id = {}
                    this.selected_title = {}
                    this.dialog_form = true
                }
            },
            savePatient() {
                var error = false
                this.error_dob = false
                var dob = this.tgl + '-' + this.bln + '-' + this
                    .thn
                    console
                    .log(dob)
                var check_dob = this
                    .$moment(dob, 'DD-MM-YYYY', true)
                    .isValid()
                console.log(check_dob)
                if (!check_dob) 
                    this.error_dob = true
                this.checkIdentity()
          if(this.$refs.form.validate()  && check_dob && !this.error_ktp){
                    this.dialog_form = false
                    var patient = {
                        act: this.act,
                        idtype: this.selected_id,
                        patient_id: this.$store.state.order.patient_id,
                        ktp: this.ktp,
                        title: this.selected_title,
                        name: this.name,
                        sex: this.sex,
                        dob: this.thn + '-' + this.bln + '-' + this.tgl,
                        job: this.job,
                        pramitaid: this.pramitaid,
                        address: this.address,
                        province: this.selected_province,
                        city: this.selected_city,
                        district: this.selected_district,
                        kelurahan: this.selected_kelurahan,
                        hp: this.hp,
                        email:this.email
                    }
                    this
                        .$store
                        .commit("beranda/update_dialog_loading", true)
                    this
                        .$store
                        .dispatch("test/savePatient", patient)
                }

            },
            validate() {
                this
                    .$refs
                    .form
                    .validate()
            },
            reset() {
                this
                    .$refs
                    .form
                    .reset()
            },
            resetValidation() {
                this
                    .$refs
                    .form
                    .resetValidation()
            },
            formatDate(date) {
                if (!date) 
                    return null

                const [year, month, day] = date.split('-')
                return `${day}-${month}-${year}`
            },
            parseDate(date) {
                if (!date) 
                    return null

                const [day, month, year] = date.split('-')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
            changeTgl(event) {
                //var val = event.target.value.toString().padStart(2, "0")
                this.tgl = event.target.value
                var dob = this.tgl + '-' + this.bln + '-' + this.thn
                var check_dob = this
                    .$moment(dob, 'DD-MM-YYYY', true)
                    .isValid()
                this.error_dob = false
                if (!check_dob) 
                    this.error_dob = true

            },
            changeBln(event) {
                this.bln = event.target.value
                var dob = this.tgl + '-' + this.bln + '-' + this.thn
                var check_dob = this
                    .$moment(dob, 'DD-MM-YYYY', true)
                    .isValid()
                this.error_dob = false
                if (!check_dob) 
                    this.error_dob = true
            },
            changeThn(event) {
                this.thn = event.target.value
                var dob = this.tgl + '-' + this.bln + '-' + this.thn
                var check_dob = this
                    .$moment(dob, 'DD-MM-YYYY', true)
                    .isValid()
                this.error_dob = false
                if (!check_dob) 
                    this.error_dob = true
            },
            checkIdentity(){
          var ktp = this.ktp
          this.error_ktp = false
          this.error_ktp_msg = ''
          if(parseInt(this.selected_id.id) === 1 ){
            if(ktp.length < 16){
              this.error_ktp = true
              this.error_ktp_msg = 'KTP tidak valid'
            }
      
            if(isNaN(ktp)){
              //console.log('dasds')
              this.error_ktp = true
              this.error_ktp_msg = 'Hanya numerik'
            }
          }

          if(parseInt(this.selected_id.id) === 3 ){
            if(ktp.length == 0){
              this.error_ktp = true
              this.error_ktp_msg = 'Passport tidak valid'
            }
          }
        },
            goToSettingSchedule(){
                //this.dialog_cart = false
                var xstate = {}
                this.$store.commit("test/update_all_requirements",[])
                this.$store.commit("test/update_datevisit",'')
                this.$store.commit("test/update_timevisit",{})
                this.$store.commit("test/update_visittimes",'')
                this.$store.commit("test/update_select_test",{})
                this.$store.commit("test/update_selected_date_dialog",'')
                this.$store.commit("test/update_selected_time_dialog",'')
                

                var cart = this.cart
                var all_requirements = []
                cart.forEach((patient,keypat) => {
                    var requirements = []
                    var order = patient.order
                    var is_covid_count = 0
                    order.forEach(test => {
                        if(test.is_covid == 'Y')
                            is_covid_count = is_covid_count + 1

                        if(test.requirement.length > 0){
                            test.requirement.forEach(requirement => {
                                let idx = _.findIndex(requirements, function(o) { return o.req_id == requirement.req_id })
                                if(idx === -1)
                                    requirements.push(requirement)
                            })
                        }
                    })


                    if(is_covid_count > 0)
                        cart[keypat].is_covid = true
                    else
                        cart[keypat].is_covid = false


                    if(requirements.length > 0)
                    all_requirements.push({patient:patient.patient.name,requirements:requirements})
                })

                this.$store.commit("test/update_all_requirements",all_requirements)

                xstate.beranda = this.$store.state.beranda
                xstate.beranda.order_hs = true
                //xstate.order = this.$store.state.order
                xstate.choosebranch = this.$store.state.choosebranch
                xstate.test = this.$store.state.test
                localStorage.setItem('oneState', JSON.stringify(xstate));

                var xxlocalstorage = JSON.parse(localStorage.getItem("oneState"))
                var order_hs = xxlocalstorage.beranda.order_hs
                console.log(xxlocalstorage)
                if(this.$store.state.test.all_requirements.length == 0){
                    this.$router.push({ path: `/settingschedulehs` })
                }
                else
                this.$router.push({ path: `/requirements` })
            }
    },
    
    data: vm => ({
        localstoragex:[],
        dialog_rejected_add_test:false,
        rejected_add_test:[],
        dialog_form:false,
        search_province: '',
        search_city: '',
        search_district: '',
        search_kelurahan: '',
        error_dob: false,
        error_ktp:false,
      error_ktp_msg:'',
      error_email:false,
      error_email_msg:'',
        date: '',
        dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
        value: '',
        valid: true,
        rulesdob: [
            v => !!vm
                .$moment(v, 'DD-MM-YYYY', true)
                .isValid() || 'Format salah, harusnya dd-mm-yyyy',
            v => !!v || 'Harus diisi'
        ],
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
    }),
    watch: {
      date (val) {
        this.dateFormatted = this.formatDate(this.date)
      },
      tgl (val) {
        if(val.length == 2){
            this.$refs.input_bln.focus()
        }
      },
      bln (val) {
        if(val.length == 2){
            this.$refs.input_thn.focus()
        }
      },
    },
  }
</script>
