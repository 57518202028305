import axios from 'axios'

//const URL = "https://sasmobile.aplikasi.web.id/one_api_coba/mobile/";
const URL = "https://mobile.pramita.co.id/one_api_coba/mobile/";


export async function getdata(prm,fn_url) {
    try {
        console.log("API")
        var fcm = JSON.parse(localStorage.getItem("fcm"))
        if(fcm)
            prm.fcm = fcm.token
        var resp = await axios.post(URL + fn_url, prm);
        if (resp.status != 200) {
            return {
                status: "ERR",
                message: resp.statusText
            };
        }
        let data = resp.data;
       // console.log(data)
        return data;
    } catch (e) {
        return {
            status: "ERR",
            message: e.message
        };
    }
}
