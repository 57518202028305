<template>
  <div>
    <v-app-bar
      color="white"
      fixed
      elevation="1"
      height="59px"
    >

    <v-list-item class="mx-auto pa-0">
      <v-list-item-content>
        <v-list-item-title class="font-weight-bold title"><v-icon>fa-arrow-left</v-icon></v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    
    </v-app-bar>
 
    

    <v-row class="mx-auto mt-16 mb-0 flex-wrap no-gutters">
      <v-col
        cols="12"
        class="pa-2 mb-0"
  
      >
        <v-card class="pa-2">

            <v-card class="pb-5" flat color="#f1f1f1" min-height="200px">
                <p class="text-center"><v-avatar size="60" color="red" tile><v-icon large dark>fa-syringe</v-icon></v-avatar></p>
                <p class="pa-2 ml-2 mb-1 text-center font-weight-black headline">Rp 1.200.000</p>
                
            </v-card>
            
        </v-card>
      </v-col>
      <v-col
        cols="12"
        class="pa-2 mb-2"
  
      >
        <v-card class="pa-2">

            <v-btn
      
      color="red darken-2"
      dark
      block
    >
      PESAN SEKARANG
    </v-btn>
            
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<style>
html,body{
  background:#fafafa
}
.box-center{
  position: fixed;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}
@media only screen and (min-device-width : 1024px){
/* Styles */
/* width */
::-webkit-scrollbar {
   height: 8px;              /* height of horizontal scrollbar ← You're missing this */
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
}


</style>
<script>
  export default {
    name: 'xrincianpemeriksaan',
    mounted(){
      

    },
    computed:{
      lat:{
         get() {
             return this.$store.state.beranda.lat
                
         },
         set(val) {
               this.$store.commit("beranda/update_lat",val)
         }
      },
      lng:{
         get() {
             return this.$store.state.beranda.lng
                
         },
         set(val) {
               this.$store.commit("beranda/update_lng",val)
         }
      },
      window_width:{
         get() {
             return this.$store.state.beranda.window_width
                
         },
         set(val) {
               this.$store.commit("beranda/update_window_width",val)
         }
      },
    },
     methods:{
        goToDetail(){
          this.$router.push({name:'xdetails',params:{branch:1,test:'008967'}})
        },
        getWIdth(){
          switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 320
            case 'sm': return 325
            case 'md': return 363
            case 'lg': return 363
          }
        }
    },
    data: () => ({
      dialog_info:false,
      reveal:false,
      dialog_branch:false,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      selected_city: {},
      categories:[{id:1,name:'PCR',active:false},{id:1,name:'Swab Antigen',active:false}],
      selected_categories:[],
      show: false,
      cities: [
          { name: 'Surabaya'},
          { name: 'Madiun'},
          { name: 'Salatiga'},
          { name: 'Magelang'},
          { name: 'Yogyakarta'},
          { name: 'Bandung'},
        ],
    }),
  }
</script>
