// 1 => LOADING
// 2 => DONE
// 3 => ERROR


export default {
    namespaced: true,
    state: {
        selected_date:{},
        selected_time:{},
        timeSamples:[],
        datetime_sample:{
            date:'21',
            month:'DES',
            month_x:12,
            year:'2020',
            childs:[
                {id:11,name:'06.00 - 07.00',note:'Pagi',status:'-'},
                {id:12,name:'07.00 - 08.00',note:'Pagi',status:'-'},
                {id:13,name:'08.00 - 09.00',note:'Pagi',status:'-'},
                {id:14,name:'10.00 - 11.00',note:'Pagi',status:'-'},
                {id:15,name:'11.00 - 12.00',note:'Pagi',status:'-'},
                {id:16,name:'13.00 - 14.00',note:'Siang',status:'-'},
                {id:18,name:'19.00 - 20.00',note:'Malam',status:'-'}
            ]
        },
        datetime_samples:[
            {
               date:'21',
               month:'DES',
               month_x:12,
               year:'2020',
               childs:[
                   {id:11,name:'06.00 - 07.00',note:'Pagi',status:'-'},
                   {id:12,name:'07.00 - 08.00',note:'Pagi',status:'-'},
                   {id:13,name:'08.00 - 09.00',note:'Pagi',status:'-'},
                   {id:14,name:'10.00 - 11.00',note:'Pagi',status:'-'},
                   {id:15,name:'11.00 - 12.00',note:'Pagi',status:'-'},
                   {id:16,name:'13.00 - 14.00',note:'Siang',status:'-'},
                   {id:18,name:'19.00 - 20.00',note:'Malam',status:'-'}
               ]
           },
           {
               date:'22',
               month:'DES',
               month_x:12,
               year:'2020',
               childs:[
                   {id:21,name:'06.00 - 07.00',note:'Pagi',status:'-'},
                   {id:22,name:'07.00 - 08.00',note:'Pagi',status:'-'},
                   {id:23,name:'08.00 - 09.00',note:'Pagi',status:'-'},
                   {id:24,name:'10.00 - 11.00',note:'Pagi',status:'-'},
                   {id:25,name:'11.00 - 12.00',note:'Pagi',status:'-'},
                   {id:26,name:'13.00 - 14.00',note:'Siang',status:'-'}
               ]
           },
            {    
                date:'23',
                month:'DES',
                month_x:12,
                year:'2020',
                childs:[
                   {id:31,name:'06.00 - 07.00',note:'Pagi',status:'-'},
                   {id:32,name:'07.00 - 08.00',note:'Pagi',status:'-'},
                   {id:33,name:'08.00 - 09.00',note:'Pagi',status:'-'},
                   {id:34,name:'13.00 - 14.00',note:'Siang',status:'-'},
                   {id:35,name:'15.00 - 16.00',note:'Sore',status:'-'},
                   {id:36,name:'16.00 - 17.00',note:'Sore',status:'-'}
               ]
           },
           {
               date:'24',
               month:'DES',
               month_x:12,
               year:'2020',
               childs:[
                   {id:41,name:'06.00 - 07.00',note:'Pagi',status:'-'},
                   {id:42,name:'07.00 - 08.00',note:'Pagi',status:'-'},
                   {id:43,name:'08.00 - 09.00',note:'Pagi',status:'-'},
                   {id:44,name:'10.00 - 11.00',note:'Pagi',status:'-'},
                   {id:45,name:'11.00 - 12.00',note:'Pagi',status:'-'},
                   {id:46,name:'13.00 - 14.00',note:'Siang',status:'-'}
               ]
           },
           {
               date:'25',
               month:'DES',
               month_x:12,
               year:'2020',
               childs:[
                   {id:51,name:'06.00 - 07.00',note:'Pagi',status:'-'},
                   {id:52,name:'07.00 - 08.00',note:'Pagi',status:'-'},
                   {id:53,name:'08.00 - 09.00',note:'Pagi',status:'-'},
                   {id:54,name:'10.00 - 11.00',note:'Pagi',status:'-'},
                   {id:55,name:'11.00 - 12.00',note:'Pagi',status:'-'},
                   {id:56,name:'13.00 - 14.00',note:'Siang',status:'-'},
                   {id:58,name:'19.00 - 20.00',note:'Malam',status:'-'}
               ]
           },
           {
               date:'26',
               month:'DES',
               month_x:12,
               year:'2020',
               childs:[
                   {id:61,name:'06.00 - 07.00',note:'Pagi',status:'-'},
                   {id:62,name:'07.00 - 08.00',note:'Pagi',status:'-'},
                   {id:63,name:'08.00 - 09.00',note:'Pagi',status:'-'},
                   {id:64,name:'10.00 - 11.00',note:'Pagi',status:'-'},
                   {id:65,name:'11.00 - 12.00',note:'Pagi',status:'-'},
                   {id:66,name:'13.00 - 14.00',note:'Siang',status:'-'}
               ]
           },
            {
               date:'27',
               month:'DES',
               month_x:12,
               year:'2020',
               childs:[
                   {id:71,name:'06.00 - 07.00',note:'Pagi'},
                   {id:72,name:'07.00 - 08.00',note:'Pagi'},
                   {id:73,name:'08.00 - 09.00',note:'Pagi'},
                   {id:74,name:'10.00 - 11.00',note:'Pagi'},
                   {id:75,name:'11.00 - 12.00',note:'Pagi'},
                   {id:76,name:'13.00 - 14.00',note:'Siang'}
               ]
           }
           ]
        
    },
    mutations: {
      update_selected_date(state, val) {
        state.selected_date = val
    },
        update_selected_time(state, val) {
            state.selected_time = val
        },
        update_timeSamples(state, val) {
            state.timeSamples = val
        },
        update_datetime_sample(state, val) {
            state.datetime_sample = val
        },
        update_datetime_samples(state, val) {
            state.datetime_samples = val
        }
    },
    actions: {
        
    }
}