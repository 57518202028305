<template>
  <div>
    <v-dialog
            style="z-index:9999999999999999999"
            v-model="dialog_privacy_policy_x"
            width="500"
            scrollable="scrollable"
            persistent="persistent">

            <v-card>
                <v-toolbar dark="dark" color="#e0282c">

                    <v-toolbar-title class="poppins-bold">KEBIJAKAN PRIVASI</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon="icon" dark="dark" @click="dialog_privacy_policy_x = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text class="pt-3 pb-3">
                    <p class="semi-poppins subheader">
                        Privasi Anda sangat penting bagi kami.
                    </p>
                    <p class="poppins-light">Kami sangat menyadari adanya kebutuhan pelanggan kami akan privasi dan
                        keamanan. Untuk itu, Kebijakan Privasi ini ditujukan untuk memberitahu Anda
                        tentang penggunaan informasi pribadi yang Anda berikan kepada kami saat Anda
                        mengakses Situs Web maupun Aplikasi Web kami.
                    </p>
                    <p class="poppins-light">Dengan mengunjungi dan menggunakan layanan pada situs Web maupun Aplikasi Web
                        kami, Anda dianggap telah setuju untuk terikat dengan ketentuan-ketentuan dalam
                        Kebijakan Privasi ini.</p>
                    <p class="semi-poppins subheader">
                        PENGUMPULAN DATA ANDA
                    </p>
                    <p class="poppins-light">Semua orang dapat mengakses, melihat dan melakukan transaksi di situs web
                        kami. Namun ada beberapa fitur layanan yang membutuhkan pengisian data pribadi
                        agar dapat diakses.</p>

                    <ul class="mb-5">
                        <li class="mb-2 semi-poppins ">
                            Fitur Hubungi :
                            <br/>
                            <span class="poppins-light">Kami mengumpulkan dan menggunakan informasi pribadi Anda meliputi : Nama
                            Lengkap, Alamat Rumah, Alamat Kantor, Email, dan Nomor Telepon</span>
                        </li>
                        <li class="mb-2 semi-poppins ">
                            Fitur Registrasi Online :<br/>
                            <span class="poppins-light">Kami mengumpulkan dan menggunakan informasi pribadi Anda meliputi nomor
                            KTP/SIM/Paspor, Nama, Tanggal Lahir, Jenis Kelamin, Nomor Telepon, Pekerjaan,
                            Alamat, dan Alamat Email. Serta informasi tambahan lainnya seperti informasi
                            mengenai tujuan permintaan pemeriksaan Anda dan mendeteksi lokasi persis (
                            location ) perangkat Anda saat melakukan pendaftaran online.</span>
                        </li>
                    </ul>
                    <p class="semi-poppins subheader">
                        TUJUAN PENGUMPULAN DATA ANDA
                    </p>
                    <p class="poppins-light">Tujuan pengumpulan data pribadi Anda adalah agar kami dapat memberikan
                        informasi dan layanan yang Anda butuhkan dengan lebih baik dan efektif.</p>
                    <p class="poppins-light">Data pribadi yang Anda berikan, dikumpulkan dan digunakan untuk :</p>
                    <ul class="mb-5">
                        <li class="poppins-light mb-2">
                            Memfasilitasi Anda dalam mengakses dan menggunakan layanan yang tersedia pada
                            situs web atau aplikasi web kami.
                        </li>
                        <li class="poppins-light mb-2">
                            Identitas pada dokumen hasil pemeriksaan, pengelolaan pelaporan data hasil
                            pemeriksaan, verifikasi dan validasi hasil pemeriksaan.
                        </li>
                        <li class="poppins-light mb-2">
                            Memberikan informasi, menangani keluhan, pertanyaan, atau permasalahan yang Anda
                            sampaikan.
                        </li>
                        <li class="poppins-light mb-2">
                            Memproses permintaan yang Anda kirimkan.
                        </li>
                        <li class="poppins-light mb-2">
                            Mengumpulkan data transaksi Anda.
                        </li>
                        <li class="poppins-light mb-2">
                            Mengarahkan Anda ke lokasi terdekat Kami, berdasarkan info lokasi persis /
                            location yang terdeteksi dari perangkat Anda, saat Anda melakukan pendaftaran
                            pemeriksaan secara online.
                        </li>
                        <li class="poppins-light mb-2">
                            Mengelola dan memverifikasi akun serta transaksi keuangan yang berkaitan dengan
                            pembayaran yang anda buat secara online.
                        </li>
                    </ul>
                    <p class="poppins-light">Kami menjamin kerahasiaan data pribadi anda. Dalam hal untuk penyelesaian
                        masalah jika terjadi kegagalan dalam transaksi maka kami akan memberikan data
                        pribadi Anda secara terbatas ( sesuai keperluan ) kepada afiliasi kami.</p>
                    <p class="poppins-light">Karena kami menghormati privasi Anda, maka kami akan melindungi dan
                        menggunakan data pribadi Anda hanya untuk kepentingan sebagaimana yang
                        dinyatakan dalam kebijakan privasi ini.</p>
                    <p class="poppins-light">Jika Anda memberikan informasi pribadi pihak ketiga kepada Kami, maka akan
                        dianggap dan diasumsikan bahwa Anda telah memperoleh izin yang diperlukan dari
                        pihak ketiga tersebut untuk memberikan informasi pribadi kepada kami.</p>
                    <p class="semi-poppins subheader">
                        PERUBAHAN KEBIJAKAN PRIVASI
                    </p>
                    <p class="poppins-light">Secara berkala, Kami dapat meninjau kecukupan dari Kebijakan Privasi ini.
                        Kami dapat mengubah isi kebijakan privasi ini dari waktu ke waktu. Perubahan
                        terhadap kebijakan privasi ini akan kami cantumkan pada halaman ini. Bacalah
                        kebijakan privasi ini setiap Anda mengunjungi situs web kami.</p>
                    <p class="semi-poppins subheader">
                        CARA MENGHUBUNGI KAMI
                    </p>
                    <p class="poppins-light">Jika Anda ingin menghubungi kami terkait kebijakan privasi ini, silahkan
                        menghubungi kami melalui :</p>
                    <p class="poppins-light">Kantor Pusat Laboratorium Klinik Pramita.Jl. Adityawarman No. 73 – 75
                        Surabaya Telp. 031-5682416 Email: info@pramita.co.id</p>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    
                    <v-btn rounded block color="#e0282c" dark @click="login('daftar')">
                        Saya setuju
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    <v-dialog
      v-model="dialog_error"
      width="500"
    >
      <v-card>
        <v-card-title class="headline error lighten-2">
          Error
        </v-card-title>

        <v-card-text>
          {{msg_error}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            text
            @click="dialog_error = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

                  <v-app-bar
                    color="white"
                    fixed
                    
                    height="90px"
                  >
                  <v-row align="center">
                     <v-col justify="right" class="text-right font-weight-bold red--text" cols="4">
                        <v-img
                          style="float: right;"
                          class="text-right"
                          :lazy-src="require('../assets/pramita_kotak.png')"
                          :src="require('../assets/pramita_kotak.png')"
                          width="100"
                          height="50"
                        ></v-img>
                     </v-col>
                     <v-col>
                       <div style="font-size:22px;color:#e0282c" class="ml-3 poppins-bold">PRAMITA MOBILE</div>
                      </v-col>
                     
                  </v-row>
                  
                    
              
                    
                    
                  
                  </v-app-bar>

                  



                  <Choosebranch/>

                

                  <v-bottom-navigation height="80"
                    background-color="white"
                    
                   elevation="1" grow fixed>
                  
                    <v-row>
                      <v-col  class="text-center" cols="4" >
                        <v-img
                          class="mx-auto"
                          :lazy-src="require('../assets/icon-home-merah.png')"
                          :src="require('../assets/icon-home-merah.png')"
                          width="40"
                          height="40"
                        ></v-img>

                        <p style="font-size:14px;color:#e0282c" class="poppins-light mx-auto ">Beranda</p>
                      </v-col>
                      <v-col v-if="!isLogin"  class="text-center" cols="4" >
                        <v-img  @click="openPrivacyPolicy"
                          class="mx-auto"
                          :lazy-src="require('../assets/icon-daftar-grey.png')"
                          :src="require('../assets/icon-daftar-grey.png')"
                          width="40"
                          height="40"
                        ></v-img>
                        <p style="font-size:14px" class="poppins-light mx-auto ">Daftar</p>
                      </v-col>
                      <v-col v-if="!isLogin" class="text-center" cols="4" >
                        <v-img  @click="login()"
                          class="mx-auto"
                          :lazy-src="require('../assets/icon-login-grey.png')"
                          :src="require('../assets/icon-login-grey.png')"
                          width="40"
                          height="40"
                        ></v-img>
                        <p style="font-size:14px" class="poppins-light mx-auto ">Login</p>
                      </v-col>
                      <v-col v-if="isLogin"  class="text-center" cols="4" >
                        <v-img  @click="listorder()"
                          class="mx-auto"
                          :lazy-src="require('../assets/icon_pesanan_black.png')"
                          :src="require('../assets/icon_pesanan_black.png')"
                          width="40"
                          height="40"
                        ></v-img>
                        <p style="font-size:14px" class="poppins-light mx-auto ">Pesanan</p>
                      </v-col>
                  
                  <v-col v-if="isLogin"  class="text-center" cols="4" >
                        <v-img  @click="menu()"
                          class="mx-auto"
                          :lazy-src="require('../assets/icon_akunblack.png')"
                          :src="require('../assets/icon_akunblack.png')"
                          width="40"
                          height="40"
                        ></v-img>
                        <p style="font-size:14px" class="poppins-light mx-auto ">Akun</p>
                      </v-col>
                  
                    </v-row>
                        
                 

                  <!--<v-btn @click="listorder()" v-if="isLogin">
                    <span>PESANAN</span>

                    <v-icon class="pb-1">fa-calendar-alt</v-icon>
                  </v-btn>
                  <v-btn @click="login('daftar')" v-if="!isLogin">
                    <span>DAFTAR</span>

                    <v-icon class="pb-1">fa-user-plus</v-icon>
                  </v-btn>
                  <v-btn @click="login()" v-if="!isLogin">
                    <span>LOGIN</span>

                    <v-icon class="pb-1">fa-sign-in-alt</v-icon>
                  </v-btn>
                  <v-btn @click="menu()" v-if="isLogin">
                    <span>LAINNYA</span>

                    <v-icon class="pb-1">fa-address-card</v-icon>
                  </v-btn>-->
                </v-bottom-navigation>
     
         <!---- <LiveChatWidget
    license="14289168"
    visibility="minimized"
    v-on:new-event="handleNewEvent"
  />-->

      </div>
      
                
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
.poppins-bold{
  font-family: 'Poppins', sans-serif;
  font-weight:800;
}
.semi-poppins{
  font-family: 'Poppins', sans-serif;
  font-weight:600;
}
.poppins-light{
  font-family: 'Poppins', sans-serif;
  font-weight:300;
}


.fixed-bottom-bg-no-shadow{
        position: fixed;
        left: 50%;
        bottom: -46px;
        transform: translate(-50%, -50%);
        margin: 0 auto;
      
    }
.box-center{
  position: fixed;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}
@media only screen and (min-device-width : 1024px){
/* Styles */
/* width */
::-webkit-scrollbar {
  height: 8px;              /* height of horizontal scrollbar ← You're missing this */
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
}

</style>
<script>
import Choosebranch from '@/components/Choosebranch';
import { LiveChatWidget } from '@livechat/widget-vue/v2'
  export default {
    name: 'xberanda',
    components: {
      Choosebranch,
      LiveChatWidget
    },
    mounted(){
      this.$store.commit("beranda/update_order_hs",false)
      this.$store.commit("order/update_selected_time",{})
      var user = JSON.parse(localStorage.getItem("user"))
      if (user && user.token != ''){
        this.user = JSON.parse(localStorage.getItem("user"))
        this.isLogin = true
      }
      else
        this.isLogin = false

      if(this.$vuetify.breakpoint.name == 'xs'){
        this.window_width = window.innerWidth
      }
      else
        this.window_width = '560px'

    },
    computed:{
      user:{
        get() {
            return this.$store.state.beranda.user
                
        },
        set(val) {
              this.$store.commit("beranda/update_user",val)
        }
      },
      selected_branch:{
        get() {
            return this.$store.state.choosebranch.selected_branch
                
        },
        set(val) {
              this.$store.commit("choosebranch/update_selected_branch",val)
        }
      },
      lat:{
        get() {
            return this.$store.state.beranda.lat
                
        },
        set(val) {
              this.$store.commit("beranda/update_lat",val)
        }
      },
      lng:{
        get() {
            return this.$store.state.beranda.lng
                
        },
        set(val) {
              this.$store.commit("beranda/update_lng",val)
        }
      },
      window_width:{
        get() {
            return this.$store.state.beranda.window_width
                
        },
        set(val) {
              this.$store.commit("beranda/update_window_width",val)
        }
      },
    },
    methods:{
       handleNewEvent(event) {
        console.log('LiveChatWidget.onNewEvent', event)
      },
        goToDetail(){
          this.$router.push({name:'xdetails',params:{branch:1,test:'008967'}})
        },
        login(act){
          console.log(act)
          if(act === 'daftar'){
            console.log('daftar ini')
            this.$router.push({name:'xlogin',params:{act:act}})
          }
          else
          this.$router.push({name:'xlogin'})
        },
        getWIdth(){
          switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 320
            case 'sm': return 325
            case 'md': return 363
            case 'lg': return 363
          }
        },
        listorder(){
          this.$router.push({ name: 'xlistorder'})
        },
        logout(){
          var user =  JSON.parse(localStorage.getItem("user"))
          user.lat = this.$store.state.beranda.lat
          user.lng = this.$store.state.beranda.lng
          this.$store.dispatch("login/logout", user)
        },
        menu(){
          this.$router.push({name:'xmenu'})
        },
        openPrivacyPolicy() {
                //console.log('dasdasd')
                this.dialog_privacy_policy_x = true
                //console.log(this.dialog_privacy_policy_x)
            },
    },
    data: () => ({
      dialog_privacy_policy_x: false,
      isLogin:false,
      isTrue:false,
      dialog_city:false,
      dialog_error:false,
      msg_error:'',
      reveal:false,
      dialog_branch:false,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      selected_city: {},
      categories:[{id:1,name:'PCR',active:false},{id:1,name:'Swab Antigen',active:false}],
      selected_categories:[],
      show: false,
      cities: [
          { name: 'Surabaya'},
          { name: 'Madiun'},
          { name: 'Salatiga'},
          { name: 'Magelang'},
          { name: 'Yogyakarta'},
          { name: 'Bandung'},
        ],
    }),
  }
</script>
